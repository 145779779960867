"use strict"
export const checker = ['a1','c1','e1','g1',
                 'b2','d2','f2','h2',
                 'a3','c3','e3','g3',
                 'b4','d4','f4','h4',
                 'a5','c5','e5','g5',
                 'b6','d6','f6','h6',
                 'a7','c7','e7','g7',
                 'b8','d8','f8','h8'];

const wfields=["a1","c1","e1","g1","b2","d2","f2","h2",
                "a3","c3","e3","g3","b4","d4","f4","h4",
                "a5","c5","e5","g5","b6","d6","f6","h6",
                "a7","c7","e7","g7","b8","d8","f8","h8"];

const bfields=["h8","f8","d8","b8","g7","e7","c7","a7",
                "h6","f6","d6","b6","g5","e5","c5","a5",
                "h4","f4","d4","b4","g3","e3","c3","a3",
                "h2","f2","d2","b2","g1","e1","c1","a1"];

const  hitdir =
[
 [
  [ //a1
   {len:2, vp:[4, 9]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ],
  [
   {len:7, vp:[4, 9,13,18,22,27,31]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ]
 ],

 [
  [ //c1
   {len:2, vp:[5,10]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[4, 8]}
  ],
  [
   {len:5, vp:[5,10,14,19,23]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[4, 8]}
  ]
 ],

 [
  [ //e1
   {len:2, vp:[6,11]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[5, 9]}
  ],
  [
   {len:3, vp:[6,11,15]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:4, vp:[5, 9,12,16]}
  ]
 ],

 [
  [ //g1
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[6,10]}
  ],
  [
   {len:1, vp:[7]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:6, vp:[6,10,13,17,20,24]}
  ]
 ],

 [
  [ //b2
   {len:2, vp:[9,13]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ],
  [
   {len:6, vp:[9,13,18,22,27,31]},
   {len:1, vp:[1]},
   {len:1, vp:[0]},
   {len:1, vp:[8]}
  ]
 ],

 [
  [//d2
   {len:2, vp:[10,14]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[9,12]}
  ],
  [
   {len:4, vp:[10,14,19,23]},
   {len:1, vp:[ 2]},
   {len:1, vp:[ 1]},
   {len:3, vp:[ 9,12,16]}
  ]
 ],

 [
  [//f2
   {len:2, vp:[11,15]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[10,13]}
  ],
  [
   {len:2, vp:[11,15]},
   {len:1, vp:[ 3]},
   {len:1, vp:[ 2]},
   {len:5, vp:[10,13,17,20,24]}
  ]
 ],

 [
  [//h2
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[11,14]}
  ],
  [
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:1, vp:[ 3]},
   {len:6, vp:[11,14,18,21,25,28]},
  ]
 ],

 [
  [//a3
   {len:2, vp:[12,17]},
   {len:2, vp:[ 4, 1]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ],
  [
   {len:5, vp:[12,17,21,26,30]},
   {len:2, vp:[ 4, 1]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ]
 ],

 [
  [//c3
   {len:2, vp:[13,18]},
   {len:2, vp:[ 5, 2]},
   {len:2, vp:[ 4, 0]},
   {len:2, vp:[12,16]}
  ],
  [
   {len:5, vp:[13,18,22,27,31]},
   {len:2, vp:[ 5, 2]},
   {len:2, vp:[ 4, 0]},
   {len:2, vp:[12,16]}
  ]
 ],

 [
  [//e3
   {len:2, vp:[14,19]},
   {len:2, vp:[ 6, 3]},
   {len:2, vp:[ 5, 1]},
   {len:2, vp:[13,17]}
  ],
  [
   {len:3, vp:[14,19,23]},
   {len:2, vp:[ 6, 3]},
   {len:2, vp:[ 5, 1]},
   {len:4, vp:[13,17,20,24]}
  ]
 ],

 [
  [//g3
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[ 6, 2]},
   {len:2, vp:[14,18]}
  ],
  [
   {len:1, vp:[15]},
   {len:1, vp:[ 7]},
   {len:2, vp:[ 6, 2]},
   {len:5, vp:[14,18,21,25,28]}
  ]
 ],

 [
  [//b4
   {len:2, vp:[17,21]},
   {len:2, vp:[9, 5]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ],
  [
   {len:4, vp:[17,21,26,30]},
   {len:3, vp:[9, 5, 2]},
   {len:1, vp:[ 8]},
   {len:1, vp:[16]}
  ]
 ],

 [
  [//d4
   {len:2, vp:[18,22]},
   {len:2, vp:[10, 6]},
   {len:2, vp:[9, 4]},
   {len:2, vp:[17,20]}
  ],
  [
   {len:4, vp:[18,22,27,31]},
   {len:3, vp:[10, 6, 3]},
   {len:3, vp:[9, 4, 0]},
   {len:3, vp:[17,20,24]}
  ]
 ],

 [
  [//f4
   {len:2, vp:[19,23]},
   {len:2, vp:[11, 7]},
   {len:2, vp:[10, 5]},
   {len:2, vp:[18,21]}
  ],
  [
   {len:2, vp:[19,23]},
   {len:2, vp:[11, 7]},
   {len:3, vp:[10, 5, 1]},
   {len:4, vp:[18,21,25,28]}
  ]
  ],

 [
  [//h4
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[11, 6]},
   {len:2, vp:[19,22]}
  ],
  [
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:3, vp:[11, 6, 2]},
   {len:4, vp:[19,22,26,29]}
  ]
 ],

 [
  [//a5
   {len:2, vp:[20,25]},
   {len:2, vp:[12,9]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ],
  [
   {len:3, vp:[20,25,29]},
   {len:4, vp:[12,9, 5, 2]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ]
 ],

 [
  [//c5
   {len:2, vp:[21,26]},
   {len:2, vp:[13,10]},
   {len:2, vp:[12, 8]},
   {len:2, vp:[20,24]}
  ],
  [
   {len:3, vp:[21,26,30]},
   {len:4, vp:[13,10, 6, 3]},
   {len:2, vp:[12, 8]},
   {len:2, vp:[20,24]}
  ]
 ],

 [
  [//e5
   {len:2, vp:[22,27]},
   {len:2, vp:[14,11]},
   {len:2, vp:[13, 9]},
   {len:2, vp:[21,25]}
  ],
  [
   {len:3, vp:[22,27,31]},
   {len:3, vp:[14,11, 7]},
   {len:4, vp:[13, 9, 4, 0]},
   {len:3, vp:[21,25,28]}
  ]
 ],

 [
  [//g5
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[14,10]},
   {len:2, vp:[22,26]}
  ],
  [
   {len:1, vp:[23]},
   {len:1, vp:[15]},
   {len:4, vp:[14,10, 5, 1]},
   {len:3, vp:[22,26,29]}
  ]
 ],

 [
  [//b6
   {len:2, vp:[25,29]},
   {len:2, vp:[17,13]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ],
  [
   {len:2, vp:[25,29]},
   {len:5, vp:[17,13,10, 6, 3]},
   {len:1, vp:[16]},
   {len:1, vp:[24]}
  ]
 ],

 [
  [//d6
   {len:2, vp:[26,30]},
   {len:2, vp:[18,14]},
   {len:2, vp:[17,12]},
   {len:2, vp:[25,28]}
  ],
  [
   {len:2, vp:[26,30]},
   {len:4, vp:[18,14,11, 7]},
   {len:3, vp:[17,12, 8]},
   {len:2, vp:[25,28]}
  ]
 ],

 [
  [//f6
   {len:2, vp:[27,31]},
   {len:2, vp:[19,15]},
   {len:2, vp:[18,13]},
   {len:2, vp:[26,29]}
  ],
  [
   {len:2, vp:[27,31]},
   {len:2, vp:[19,15]},
   {len:5, vp:[18,13, 9, 4, 0]},
   {len:2, vp:[26,29]}
  ]
 ],

 [
  [//h6
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[19,14]},
   {len:2, vp:[27,30]}
  ],
  [
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:5, vp:[19,14,10, 5, 1]},
   {len:2, vp:[27,30]}
  ]
 ],

 [
  [//a7
   {len:0, vp:[]},
   {len:2, vp:[20,17]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ],
  [
   {len:1, vp:[28]},
   {len:6, vp:[20,17,13,10, 6, 3]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ]
 ],

 [
  [//c7
   {len:0, vp:[]},
   {len:2, vp:[21,18]},
   {len:2, vp:[20,16]},
   {len:0, vp:[]}
  ],
  [
   {len:1, vp:[29]},
   {len:5, vp:[21,18,14,11, 7]},
   {len:2, vp:[20,16]},
   {len:1, vp:[28]}
  ]
 ],

 [
  [//e7
   {len:0, vp:[]},
   {len:2, vp:[22,19]},
   {len:2, vp:[21,17]},
   {len:0, vp:[]}
  ],
  [
   {len:1, vp:[30]},
   {len:3, vp:[22,19,15]},
   {len:4, vp:[21,17,12, 8]},
   {len:1, vp:[29]}
  ]
 ],

 [
  [//g7
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[22,18]}
  ],
  [
   {len:1, vp:[31]},
   {len:1, vp:[23]},
   {len:6, vp:[22,18,13, 9, 4, 0]},
   {len:1, vp:[30]}
  ]
 ],

 [
  [//b8
   {len:0, vp:[]},
   {len:2, vp:[25,21]},
   {len:0, vp:[]},
   {len:0, vp:[]}
  ],
  [
   {len:0, vp:[]},
   {len:6, vp:[25,21,18,14,11, 7]},
   {len:1, vp:[24]},
   {len:0, vp:[]}
  ]
 ],

 [
  [//d8
   {len:0, vp:[]},
   {len:2, vp:[26,22]},
   {len:2, vp:[25,20]},
   {len:0, vp:[]}
  ],
  [
   {len:0, vp:[]},
   {len:4, vp:[26,22,19,15]},
   {len:3, vp:[25,20,16]},
   {len:0, vp:[]}
  ]
 ],

 [
  [//f8
   {len:0, vp:[]},
   {len:2, vp:[27,23]},
   {len:2, vp:[26,21]},
   {len:0, vp:[]}
  ],
  [
   {len:0, vp:[]},
   {len:2, vp:[27,23]},
   {len:5, vp:[26,21,17,12, 8]},
   {len:0, vp:[]}
  ]
 ],

 [
  [//h8
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:2, vp:[27,22]},
   {len:0, vp:[]}
  ],
  [
   {len:0, vp:[]},
   {len:0, vp:[]},
   {len:7, vp:[27,22,18,13,9, 4, 0]},
   {len:0, vp:[]}
  ]
 ]
];

export const convrule=[0,3,4,1,2];

export function convertpos(sour){
  let res=[];
  let l=sour.length;
  for (let k=0;k<l;k++){
    res[k]=sour[l-k-1];
    if (res[k] != 0) {
      res[k] = convrule[ res[k]];
    }
  }
  return res;
}

function savemove(p_orig, p_n1, p_killed, p_itin, pos2) {
  let posconv = convertpos(pos2);
  let p_next = {
    turn    :  p_orig.turn, //nextturn,
    n1      :  p_n1,
    itin    :  p_itin,
    killed  :  p_killed,
    newpos  :  posconv,
    selected:  0
  };
  p_orig.am.push(p_next);
}

function canstrike(currpos,p_n1) {
  let stat = currpos[p_n1];
  if ((stat == 1) || (stat == 2)){
    for (let direction = 0; direction<4; direction++) {
      let i1 = 0;
      direct: for (let i = 0; i< hitdir[p_n1][stat-1][direction].len; i++) {
        let field = hitdir[p_n1][stat-1][direction].vp[i];
        switch (currpos[field]) {
          case 0: {
            if (i1 == 1) {
              return (true);
            }
            break;
          }
          case 1:
          case 2: { break direct; }
          case 3:
          case 4: {
            if (i1 ==1){
              break direct;
            } else {
              i1 = 1;
            }
            break;
          }
          case 5: {break direct; }
        }
      }
    }
  }
  return (false);
}

function buildstrikes (p_orig,p_pos2,firstn1,p_n1, p_itin, p_killed){
  let i;
  let i0;
  let i1;
  let striked;
  let direction;
  let chainind;
  let field;
  let newsta
  let strikepos =[];
  let newcanstr =[];
  let itin1 = [];
  let kill1 =[];
  let itin2 = [];
  let pos2 = [];
  let strikeagain;
  let rez=0;
  if (firstn1 == -1) {
    for (i=0; i< 32; i++){
      i0 = p_orig.p1[i];
      if ((i0 == 1) || (i0 == 2)){
        itin1 = [];
        kill1 = [];
        rez = rez + buildstrikes(p_orig,p_orig.p1,i,i,itin1,kill1);
      }
    }
  } else {
    for (direction = 0; direction < 4; direction++){
      striked = -1;
      strikepos = [];
      strikeagain = false;
      newcanstr = [false,false,false,false,false,false];
chain:for (chainind = 0; chainind<hitdir[p_n1][p_pos2[p_n1]-1][direction].len; chainind++){
        field = hitdir[p_n1][p_pos2[p_n1]-1][direction].vp[chainind];
        switch (p_pos2[field]) {
          case 0:{
            if (striked > -1) {
              kill1 = p_killed.slice(0);                //move(p_killed,kill1,sizeof(kill1));
              kill1.push(striked);
              pos2 = p_pos2.slice(0);                 //move(p_pos2,pos2,sizeof(pos2));
              pos2[striked] = 5;
              pos2[p_n1] = 0;
              itin1=[];
              for (i0 = chainind; i0<hitdir[p_n1][p_pos2[p_n1]-1][direction].len; i0++){
                field = hitdir[p_n1][p_pos2[p_n1]-1][direction].vp[i0];
                if (pos2[field]>0) {
                  break;
                }
                strikepos.push(pos2.slice(0));
                if (field>=28) {
                  newsta = 2;
                } else {
                  newsta = p_pos2[p_n1];
                }
                strikepos[strikepos.length-1][field] = newsta;
                itin1.push(field);
                if (canstrike(strikepos[strikepos.length-1],field)) {
                  newcanstr[strikepos.length-1] = true;
                  strikeagain = true;
                } else {
                  newcanstr[strikepos.length-1] = false;
                }
              }
              break chain;
            }
            break;
          }
          case 1:
          case 2:
          case 5:{
            break chain;
          }
          case 3:
          case 4:{
            if (striked >1) {
              break chain;
            } else {
              striked = field;
            }
          }
        }
      }
      for (i0 = 0; i0 <  strikepos.length; i0++){
        if ((! strikeagain) || newcanstr[i0]) {
          itin2 = p_itin.slice(0);              //move(p_itin,itin2,sizeof(p_itin));
          itin2.push(itin1[i0]);
          if (! newcanstr[i0]) {
            for (i1 = 0; i1< 32; i1++){
              if (strikepos[i0][i1] == 5) {
                strikepos[i0][i1] = 0;
              }
            }
            rez++;
            savemove(p_orig,firstn1, kill1, itin2, strikepos[i0]);
          } else {
            rez = rez+buildstrikes(p_orig,strikepos[i0], firstn1, itin2[itin2.length-1], itin2, kill1);
          }
        }
      }
    }
  }
  return (rez);
}

function maketh(p1, nfrom, nto){
  let it1 = [];
  it1.push(nto);
  let kl1 =[];
  let pos2 = p1.p1.slice(0); // move(p1.pos1,pos2,sizeof(p1.pos1));
  let i = p1.p1[nfrom];
  pos2[nfrom] = 0;
  if (nto>27){
    i = 2;
  }
  pos2[nto] = i;
  savemove(p1,nfrom, kl1,it1, pos2);
}

function buildth(p1){
  let rez = 0;
  for (let i = 0; i<32; i++){
    let j = p1.p1[i];
    if (j == 1){
      if ( (hitdir[i][1][0].len>0) && (p1.p1[hitdir[i][1][0].vp[0]] == 0 )) {
        rez++;
        maketh(p1,i,hitdir[i][1][0].vp[0]);
      }
      if ( (hitdir[i][1][3].len>0) && (p1.p1[hitdir[i][1][3].vp[0]] == 0 )){
        rez++;
        maketh(p1,i,hitdir[i][1][3].vp[0]);
      }
    } else {
      if (j ==2) {
        for (let direction =0; direction<4; direction++){
          for (let k = 0; k<hitdir[i][1][direction].len; k++){
            let l = hitdir[i][1][direction].vp[k];
            if (p1.p1[l] == 0) {
              rez++;
              maketh(p1,i,l);
            } else {
              break;
            }
          }
        }
      }
    }
  }
  return (rez);
}

export function buildmoves(p1){
  let it1 = [];
  let kl1 = [];
  let p11={
    p1:p1,
    am:[]
  };
  
  let rez = buildstrikes(p11,p11.p1,-1,0,it1,kl1)
  if (! rez){
    rez = buildth(p11);
  }
  return (p11.am);
}

export function str2pos(s){
  let pos1=[];
  for (let i=0;i<s.length;i++) {
    pos1.push(Number(s[i]));
  }
  return pos1;
}

export function move2str(oppmove){
  let myfields=[]
  if (oppmove.turn == "w") {
    myfields=wfields
  } else {
    myfields=bfields
  }
  let result=myfields[oppmove.n1]
  let sign
  if (oppmove.killed.length == 0) {
    sign="-"
  } else {
    sign=":"
  }
  for (let i=0; i<oppmove.itin.length;i++){
    result=result+sign+myfields[oppmove.itin[i]]
  }
  return result
}
