<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('COMMON.ALARM')}}
    </template>
    <template v-slot:body>
      <a-form name="alarm" class="alarm-form">

        <span>{{t('TOURNAMENT_NOTIFICATION.START_GAME')}}<br></span>
        <span v-if="Mydatastore.pseudoswitch"></span>
        <span>{{remain()}}</span>
        <a-form-item>
          <a-button type="primary" @click="close" class="login-form-button">
            OK
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent} from 'vue'
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import * as TIME from '@/sys/time.js'
  
  export default defineComponent({
    components: {
      UniversalModal
    },

    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
    
      return {
        t,
        Mydatastore
      };
    },
    methods:{
      close(){
        this.Mydatastore.hideModal()
      },
      remain(){
        if (! TIME.later(this.Mydatastore.alarmstartgame)){
          this.Mydatastore.hideModal()
        }
        return TIME.timeremain(this.Mydatastore.alarmstartgame)
      }
    }
  });

</script>

<style>
.alarm-form {
  max-width: 500px;
}

</style>
