export default {
  "ABOUT": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A little about checkers and about this site"])},
    "WHY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Why checkers?"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hundreds of thousands of people in the world systematically play checkers. Some are attracted by the aesthetic side of the game - beautiful combinations and accurate calculation, others - by sports. Checkers is a great training for logical thinking."])},
      "P2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents often try to give early development to their children by teaching them math, programming or robotics. But it is not easy to organize the course in such a way that it would be interesting for the child, so that knowledge does not become outdated by the time he grows up and that course is sufficient for long-term study. And here - the game, the usual state of a person (not only for a kids). The rules in checkers are simpler than in chess and are accessible to preschoolers. At the same time, it takes a lot of effort to learn how to play well."])}
    },
    "DIFFERENCE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. How is this project different from other checkers sites?"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is not only for playing checkers with people and robots (there are lot of such sites in the web) but also for learning. The amount of data for training is not limited. Any user can share their experience by saving their own games, analytical materials with possible forks and comments in the database (DB). All theese games can then be viewed as an animation by others. Like on YouTube. These playlists may have a link to resources in the Internet. It turns out that you can arrange checkers on the board, find playlists in the database that contain this position and then look at the original source in the Internet. Like Google. But Google does not allow you to search something by checkers position. If you, for example, run your YouTube channel dedicated to checkers, you can duplicate it here and those who wish will find your work."])}
    },
    "RULES": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Briefly about the rules"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section is for beginners. Since you are here, you probably already know how to play and it is not worth much to spread about the rules. But it’s also not good to say nothing at all, especially since there are several varieties of checkers in the world, the rules of which are slightly different. Therefore, we restrict ourselves to a few pictures with explanations. The blue arrows indicate the possible moves."])},
      "P2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple checkers move only diagonally forward one square. Kings go to any distance in all available directions along all diagonals. The checker turns into a queen, having reached the extreme horizontal."])},
      "P3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is a possibility of beating the opponent's checkers, then it is obligatory to beat. Checker combinations are based on that, when  one or more checkers are sacrificed and then a rout follows. Simple checkers, like kings, hit in all directions. When simple checker, during a beating, passes through the extreme horizontal, it turns into king and must continue the beating, if possible. You cannot hit the same checker twice. Beated checkers are removed from the board only after the end of the move. That is called 'turkish hit'"])},
      "EXAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", example is here"])},
      "NOLOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". You must be authorized to see the example of that"])},
      "P4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A game is considered to end in a draw if some position is repeated for 3 times with the same move order."])}
    },
    "ROBOTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Robots"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are several robots on the site that differ in strategies. Some of them use a database. The owner of the playlists that are used for playing by such robots is the robot named alpha. You can find this data by setting the filter by owner in the library. They include books by G.I. Khatskevich '25 lessons of checkers game', V.S. Litvinovich, N.N. Negra 'Course of checkers openings' and old checkers periodicals."])},
      "P2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The robot can play only a limited number of games simultaneously, since it does not work on the client machine, but on the server and consumes resources. If you see in the 'Game Hall' that the robot is busy, it means that the limit of the number of such games has already been reached. Now the site is played by robots beta and gamma. Beta uses the database, gamma does not. Beta calculates the position to a greater depth than gamma. In general, gamma is a partner for beginners. In the foreseeable future, the alpha robot will also be in the game, its algorithm uses a sophisticated position estimation scheme and this robot will be capable of self-learning."])}
    },
    "RATINGS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. User ratings"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Elo ratings of the participants are recalculated every time the rating game ends. All tournament games are rating ones, so take part in tournaments to increase your own rating. Outside the tournament, only VIP-participants can play for the rating. This is done in order to avoid cheating. All games for the rating are saved in the database. They can be found by user's login and loaded into an animation. So all artificial high positions in the ratings will be visible. All rating games are timed ones. Either time is limited for one move or for the entire game."])}
    },
    "VIP": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. VIP-users"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A series of victories over some robots (VIP robots) allows you to get VIP status for a certain time. Such games, firstly, should start from the classical initial position, and secondly, they should not have requests to cancel the move. It is planned that VIP-robots will be self-learning, therefore, by becoming a VIP-user, you not only get additional features, but also contribute to the development of checkers, teaching robots how to play. VIP participants can, firstly, play for a rating out of tournaments, secondly, they can start the game with a VIP robot from a free position, and thirdly, request an assessment of the position of all robots, including the VIP."])}
    },
    "TOURNAMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Tournaments"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on the number of registered participants, tournaments can be held either in a round robin or in the Swiss system. The archive of tournaments is stored in the database and all games in them can be viewed. Tournament's game starts automatically. Starting a tournament game interrupts the player's current activities, including other games. Unsaved data may be lost. 1 minute before the start, tournament participants receive a warning that their game will start soon. Being late for a tournament game by more than 5 minutes is equal to a defeat."])}
    },
    "DB": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Game database"])},
      "SUB1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.1 Chains of moves and playlists"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the games stored in the database are divided into those played for rating and those created by users. Rating games don't have an owner, users games do. If, for example, you arranged checkers, created some chain of moves, gave it a name, placed comments on the moves and saved it to the database, then you become the owner of this chain. You can view such chains in the 'My Recordings' section. You can combine that chains into playlists and publish them, giving other users the permission to view them. One chain in any playlist should be the title one - this is what everyone sees when viewing the catalog of chains in the library. To publish a playlist, it is enough to give access to the title chain. When it is requested, the rest of the chains included in the playlist are pulled up, regardless of whether they were published separately or not. It is desirable that the chains included in the playlist be interconnected by common positions. This means that from the initial position in the title chain, you can get to any other chain by simply making moves. You can check it in 'cartoon' - the main tool for animation of the playlist. The playlist you are viewing is displayed there as a tree. If your playlist has unconnected chains, then there will be more than one root element in this tree. In 'My Recordings' you can give others the permission to comment and rate your published playlists. It's all the same as comments on YouTube videos."])},
      "SUB2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.2 Cartoon"])},
      "P2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartoon is a tool for working with a playlist. It can operate in two modes - view mode and simulator mode. The program launches a cartoon when loading a playlist from various sections of the database or when demonstrating the solution to a checkers problem. In view mode, the computer makes moves for both whites and blacks. Using the buttons, you can control the whole process, returning, if necessary, to the beginning of the chain or to the beginning of the entire playlist. In simulator mode, you play either side or both sides. The computer controls that your moves do not go beyond the playlist. This mode is needed in order to learn, for example, the opening. From the cartoon, you can comments for this playlist (if comments are allowed) or to edit it (if the playlist belongs to you). If you have forgotten how to move in a particular position, you can ask for a hint with the appropriate button."])},
      "SUB3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.3 Solving the problems"])},
      "P3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the owner, when creating or editing a playlist, indicated that it is a combination or etude, then in the library it will be displayed along with the icon "])},
      "P31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". This means that it can be solved in the same way as the task of the day, which is always displayed on the main page of the site before logging in. The problem is considered solved if the end of any chain included in this playlist is reached and there is no other chain in it in which this move is not the last one. You should pay attention to this rule if you create your own problem. Let's suppose that as a result of a combination, a position is created in which any beating leads to a victory. If you make only one chain for some pick option, and the user chooses another when solving the problem, it turns out that he solved the problem but did not see a message about that. Therefore, in this case, it is better to either create a fork and show different winning options or stop the chain before the fork."])},
      "SUB4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.4 Recent games"])},
      "P4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your recently finished games are available in the 'Recent games' section. They are stored in the server's memory for some time until they are overwritten by others games. If you are interested in a recent game, you can download it into a cartoon and save it to the database."])},
      "SUB5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5 Bookmarks"])},
      "P5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can mark any playlists in the library as favorites. This will make it easier for you to find them later. It works like a bookmark in a browser."])}
    },
    "ANALYZE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Position Analysis"])},
      "SUB1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.1 Robot analysis"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For each move possible in a given position, the robot gives its own score. Usually there is a certain number in it. The lower it is, the better this move, according to the robot. If the robot algorithm provides for a preliminary search for a position in the database, then the robot may not say anything about the position, having found it in the database."])},
      "SUB2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.2. Search for moves in the database"])},
      "P2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This position analysis option allows you to find moves in 'My Recordings', in published playlists and in played rating games. In the filter, you can limit the minimum rating of a participant in the game to see how the strongest participants played in this position."])}
    },
    "FEEDBACK": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. Feedback"])},
      "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lets use telegram chat "])},
      "P2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "ACCESS_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access for other users"])},
  "ACHIEVEMENTS_LIST": {
    "POPOVER": {
      "CHAMPION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champion or ex-champion"])},
      "CUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champion of the last tournament"])},
      "PRIVL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP"])},
      "ROBOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot"])},
      "TEACHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great teacher"])},
      "ABSENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is absent"])}
    }
  },
  "ACTIVATION": {
    "ERROR": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user does not exist or the activation link has expired"])}
    },
    "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account activated"])}
  },
  "ANALYZE": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For each possible move, the analysis of the position allows, firstly, to get an estimate of the robot (remember, in order to receive such advice from a VIP robot, you yourself must have a VIP status) and, secondly, to search for a move in the database. The search is carried out in the sections 'my games', 'published records' and 'rated games'"])},
    "ROBOTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot evaluation"])},
    "DATABASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search DB"])},
    "MINRATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", rating of participants not less than"])},
    "WWIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whites wins"])},
    "WADV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significant advantage of whites"])},
    "WBETTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White's position is preferable"])},
    "EQUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approximately equal position"])},
    "BBETTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black's position is preferable"])},
    "BADV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significant advantage of blacks"])},
    "BWIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacks win"])},
    "IDONTKNOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position found in database"])},
    "BEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best move"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My recordings"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating games"])},
    "DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw"])}
  },
  "AUTH_MODAL": {
    "AUTH": {
      "ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "ERROR": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wrong login or password"])}
      },
      "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget the password"])},
      "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in"])},
      "REMEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember"])}
    },
    "COMMON": {
      "CONFIRM_PASSWORD": {
        "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
        "NOT_EQUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
        "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password again"])}
      },
      "EMAIL": {
        "INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid E-mail entered"])},
        "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the E-mail"])}
      },
      "OR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
      "PASSWORD": {
        "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwird"])},
        "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])}
      },
      "USERNAME": {
        "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
        "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter user name"])}
      }
    },
    "FORGOT_PASSWORD": {
      "ERROR": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mil not found"])}
      },
      "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A link to reset your password has been sent to the email address you provided"])}
    },
    "PROFILE": {
      "EXIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name:"])},
      "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating:"])}
    },
    "REGISTRATION": {
      "ERROR": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already exists"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is already used"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like you are robot... :)"])}
      },
      "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in"])},
      "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A link has been sent to the email address you provided to confirm your registration"])}
    },
    "RESET_PASSWORD": {
      "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "ERROR": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset was not requested"])}
      },
      "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed successfully"])}
    },
    "TITLE": {
      "AUTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization"])},
      "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password recovery"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in"])},
      "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])}
    }
  },
  "BOARD": {
    "ABC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show notation"])},
    "LAST_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last move"])},
    "ROTATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Board rotation"])},
    "SETTINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Board settings"])}
  },
  "CARTOON": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlist animation can take place in step-by-step or automatic mode. In addition, a mode is possible when the user makes moves for one or both sides. This is a learning mode, useful for learning, for example, the opening"])},
    "AUTHOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
    "MODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode"])},
    "IAMWHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I play white"])},
    "IAMBLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I play black"])},
    "IAMBOTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I play both sides"])},
    "CARTOON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation mode"])},
    "STOPONFORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop on forks"])},
    "TRAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training mode"])},
    "CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
    "TOSTART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anew"])},
    "HINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint"])},
    "HINTHINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tho moves in this position from playlist"])},
    "TOSTARTHINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to root chain"])},
    "SHOWCOMMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show comments "])},
    "SECONDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" seconds"])},
    "BOOKMARK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark"])},
    "BOOKMARKHINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember this state"])},
    "GOTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goto"])},
    "GOTOHINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to bookmark"])},
    "BMNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name for the new bookmark"])},
    "YOUMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the checkers yourself"])},
    "YOUMOVEHINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new chain in the editor"])},
    "INEDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "INEDITORHINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open chain in editor"])},
    "ADDCHAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add chain"])},
    "ADDCHAINHINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new chain and add it to playlist"])},
    "DISCUSSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion"])},
    "DISCUSSIONHINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User comments"])},
    "OTHERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other features"])},
    "BACKCHAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the beginning of the chain"])},
    "STEPBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move back"])},
    "PAUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
    "STEPFORWARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move forward"])},
    "PLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move by move"])},
    "WRONGMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This move is not in the playlist"])},
    "ENDOFCHAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of chain reached"])},
    "MOVECHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a move from the list of available"])},
    "MISMATCHCOLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhaps this position is present in the original playlist with a color change"])}
  },
  "COMMON": {
    "ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "ALARM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "AUTH_NEEDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to login"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "CHAINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chains"])},
    "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "DETAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More..."])},
    "REFUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "FIELD_REQUIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a required field"])},
    "INVITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "JOIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
    "NETWOWKERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet connection to thi site failed"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "PLAYLIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlist"])},
    "READY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
    "REPRODUCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
    "REFRESH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server wait limit exceeded"])},
    "WATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
    "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes`"])},
    "SETTINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "USER_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found"])}
  },
  "SETTINGS": {
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "ANIMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation of opponent's moves"])},
    "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation speed"])},
    "DONTINVITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nobody can invite you"])},
    "DONTWATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nobody can watch your non-rating playings"])},
    "AUTOSTEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto move for game mode"])}
  },
  "COUNTRY_FLAG": {
    "globe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No flag"])},
    "tn_ae-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U.A.E."])},
    "tn_aj-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "tn_am-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "tn_ar-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "tn_as-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "tn_au-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "tn_bo-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "tn_br-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "tn_bu-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "tn_ca-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "tn_ch-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "tn_ci-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "tn_cu-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "tn_cy-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "tn_da-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "tn_ei-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "tn_en-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "tn_ez-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czechia"])},
    "tn_fi-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "tn_fr-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "tn_gg-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "tn_gm-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "tn_gr-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
    "tn_hr-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "tn_hu-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "tn_id-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "tn_in-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "tn_ir-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "tn_is-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "tn_it-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "tn_iz-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "tn_ja-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "tn_jo-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "tn_kg-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "tn_ks-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Korea"])},
    "tn_kz-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "tn_lg-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "tn_lh-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
    "tn_lo-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "tn_md-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "tn_mj-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "tn_mo-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morocco"])},
    "tn_mx-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "tn_ni-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "tn_nl-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
    "tn_no-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
    "tn_nz-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "tn_pk-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "tn_pl-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
    "tn_po-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "tn_ri-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "tn_ro-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "tn_rs-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russia"])},
    "tn_sa-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi Arabia"])},
    "tn_sf-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
    "tn_si-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "tn_sn-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "tn_sp-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
    "tn_sw-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
    "tn_sy-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
    "tn_sz-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "tn_th-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "tn_ti-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "tn_ts-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "tn_tu-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
    "tn_tx-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "tn_tz-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "tn_uk-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK"])},
    "tn_up-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "tn_us-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA"])},
    "tn_uz-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "tn_vm-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No flag"])}
  },
  "DISCUSSION_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion permission"])},
  "DISCUSSION": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate this playlist or leave a comment"])},
    "BACK2CARTOON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to view"])},
    "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average rating"])},
    "MARKCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total marks"])},
    "MYMARK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My mark"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke"])},
    "YOURCOMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your comment"])},
    "COMMENTHERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave comment"])},
    "REPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])}
  },
  "EDIT_CHAIN": {
    "MOVES_TABLE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moves table"])},
    "FORM_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about chain"])},
    "UNSAVED_PROMPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread not saved, do you want to leave the page?"])},
    "LAST_STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last move"])},
    "ID_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id of current chain: "])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desription"])},
    "DO_MOVES_INFO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a move to create chain"])},
    "GROUPS": {
      "ANALYZE_AND_DEBUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position analysis or opening"])},
      "COMBINATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination"])},
      "ETUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etude"])},
      "PLAYED_GAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Played game"])}
    },
    "INFO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form to save the chain of moves"])},
    "MOVES_TABLE": {
      "DELETE_AFTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all moves after the selected one"])},
      "DELETE_BEFORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all moves up to the selected one"])},
      "TURN_BLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black to move"])},
      "TURN_WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White to move"])}
    },
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "NEW_TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search tags"])},
    "RESULT": {
      "BLACK_WIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black wins"])},
      "DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw"])},
      "UNDEFINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result is not defined"])},
      "WHITE_WIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White wins"])}
    },
    "SOURCE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to source"])}
  },
  "GAME": {
    "ABSENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is absent"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We wish you a productive game!"])},
    "YOUROPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent"])},
    "SURRENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surrender"])},
    "OFFERDRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer draw"])},
    "REQUESTBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request undo"])},
    "MSGOFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" offers draw"])},
    "MSGBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" asks to cancel the move"])},
    "ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])},
    "REFUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "SENDMSG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])},
    "NO_WATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny watching"])},
    "WATCHERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now watching"])},
    "SHOWMUSTGOON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game must be finished"])},
    "COMM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication and viewers"])},
    "EXPELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny viewing"])},
    "RESULT": {
      "DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw"])},
      "YWON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You won"])},
      "YLOST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You lost"])},
      "WHITES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whites` "])},
      "BLACKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacks "])},
      "NOREASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "SURRENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surrended"])},
      "LEFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["left the game"])},
      "EXCEEDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["run out time"])},
      "WWON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White won"])},
      "BWON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black won"])}
    },
    "PLAYAGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play again?"])},
    "WLEFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whites left the game"])},
    "BLEFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacks left the game"])},
    "DRAWISLATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A draw will be offered after your move."])},
    "MOVEBACKREFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move return request denied"])},
    "MOVEBACKACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" returned move"])},
    "CONGRAT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations, you won against this robot "])},
    "CONGRAT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" time(s) in a row against this robot. You get privileged status until  "])},
    "NOWSCHET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You won against this robot "])},
    "YOUNEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" time(s) in a row. "])},
    "INROW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" wins in a row are required to earn VIP status"])},
    "ZEROSCHET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you do not have a single victory over this robot. To obtain privileged status, you must "])}
  },
  "INVITE_MODAL": {
    "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "COLOR_SELECTOR": {
      "ANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random"])},
      "BLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
      "WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])}
    },
    "ENEMY_INFO": {
      "ACHIEVEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achievements"])},
      "NICKNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opponent"])},
      "ANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any player"])}
    },
    "OK_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "ON_RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating game"])},
    "ON_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing game"])},
    "RATING_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prize (w/d/l):"])},
    "START_WITH_CURRENT_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start with current position"])},
    "START_WITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start position"])},
    "TIME_SELECTOR": {
      "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
      "MINUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
      "ON_GAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for game"])},
      "ON_STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for move"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation"])},
    "ON_TIME_CHOICE": {
      "HE_IS_ROBOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't play timing game with robot"])}
    },
    "RATING_CHOICE": {
      "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating game"])},
      "NOT_PRIVL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only VIP users can play for rating out the tournament"])},
      "HE_IS_ROBOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't play with a robot for rating"])}
    },
    "WAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation for "])},
    "ANYBODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["any player"])}
  },
  "LIBRARY": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating games of all participants and playlists posted by them are available in the library"])},
    "INCLUDE_FAVOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include in favorites"])},
    "EXCLUDE_FAVOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from favorites"])},
    "OWNER_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by owner"])},
    "RATING_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only rating games"])},
    "SEARCH_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional filters"])},
    "BOOKMARK_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only in favorites"])},
    "NO_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filters"])},
    "MYGAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In my recordings"])},
    "SET_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up the filter"])},
    "SELECT_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find and select playlist owner"])},
    "SELECT_OWNER_HINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter at least 3 characters from the username"])},
    "FILTER_OFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch off"])},
    "ALL_RATING_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating games"])},
    "MINIMAL_RATING_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum player rating"])},
    "ONE_PLAYER_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected user games"])},
    "OTHERGUY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other author"])},
    "ABSENT_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant's no-show"])},
    "SEARCH_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find for ID"])},
    "SEARCH_POS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find for podition"])},
    "SHOWID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show chain ID"])},
    "SHOWID1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Played games"])},
    "SHOWID2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position analysis and openings"])},
    "SHOWID3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinations for solution"])},
    "SHOWID4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etudes for solution"])},
    "KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
    "WRONGID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID must be a natural number"])},
    "CURR_POS_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter for current position"])},
    "SEARCH_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter for move from current position"])},
    "GAME_NOT_PLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't take place"])},
    "EMPTYGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No moves have been made in this game"])}
  },
  "MESSAGES": {
    "ERRSAVEGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game save error"])}
  },
  "MYGAMES": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section is for managing your playlists. You can form them by combining game chains that belong to you and grant other users access rights to them"])},
    "REFRESH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "DELETE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm deletion: number of games "])},
    "COMBINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge into playlist"])},
    "MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other..."])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commit"])},
    "ANNULATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel playlist"])},
    "ALLOW_ACCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow access"])},
    "DENY_ACCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny access"])},
    "ALLOW_DISCUSSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow discussion"])},
    "DENY_DISCUSSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny discussion"])},
    "CREATE_PLAYLIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlist creation"])},
    "SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a title chain"])}
  },
  "POSITION": {
    "SETUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrangement of checkers"])},
    "EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty board"])},
    "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start position"])},
    "FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "COLOR_SELECTOR": {
      "BLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black move"])},
      "WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White move"])}
    },
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To edit the current position, select the desired figure with the mouse and place it on the board. You can also change the order here."])}
  },
  "PAGES": {
    "LEARN": {
      "CHECKERS_ARRANGEMENT": {
        "COLOR_SELECTOR": {
          "BLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black move"])},
          "WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White move"])}
        },
        "EMPTY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty board"])},
        "START_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start position"])}
      },
      "TABS": {
        "CHECKERS_ARRANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrangement of checkers"])},
        "TASK_OF_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem of day"])},
        "POSITION_ANALYZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis of position"])}
      },
      "TASK_OF_DAY": {
        "MODAL": {
          "TASK_SOLVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem is solved"])},
          "TASK_UNRESOLVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem is not solved"])},
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game over"])}
        },
        "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution and discussion"])},
        "START_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])}
      }
    },
    "MAIN": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solve today's problem. To do this, you need to log in. In the process of solving, you make moves for both white and black."])}
    },
    "PLAY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game room"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are in the game room. You can offer a game to one of the live players or robots, or watch the games of other participants and chat with them if they don't mind."])},
      "GAMES": {
        "COLUMNS": {
          "BLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
          "STATUS": {
            "CAN_BE_WATCHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can be watched"])},
            "CLOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On time"])},
            "NO_WATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't be watched"])},
            "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
            "TOURNAMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournament"])},
            "GAME_START_SOON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts soon"])}
          },
          "WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])}
        },
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
        "WATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])}
      },
      "NEED_AUTH_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization required to play"])},
      "TASK_OF_DAY": {
        "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by:"])},
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem of day"])}
      },
      "USERS": {
        "PLAY_WITH_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play with yourself"])},
        "DONTINVITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't invite"])},
        "BUSY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busy"])},
        "STATUS": {
          "POPOVER": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busy"])},
            "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing"])},
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])}
          }
        },
        "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are here"])}
      }
    },
    "TOURNAMENTS": {
      "ARCHIVE": {
        "COLUMNS": {
          "CYCLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournament"])},
          "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
          "PLAYERS_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of players"])},
          "SYSTEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])}
        }
      },
      "TABS": {
        "ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Tournament archive"])},
        "TIMETABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule of upcoming tournaments"])}
      }
    },
    "TOURNAMENT_INFO": {
      "ABOUT_CHAPTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on the number of registered participants, tournaments can be held in a round robin or Swiss system. 1 point is awarded for a win in the game, ½ for a draw.\nIn a round robin tournament, each participant plays with all the others. Places are distributed according to the points scored.\nIn the Swiss system, participants in each round play with those who scored approximately the same number of points in previous rounds. Places are distributed depending on the points scored and, in case of a tie, depending on the rating in this tournament. A participant's rating in a Swiss system tournament is the sum of points scored by all opponents of this participant. Do not confuse the rating in the Swiss system tournament with the personal rating of the participant, which changes throughout the history of games on our site.\nThe number of rounds in the Swiss system tournament is less than the round robin, and this allows tournaments with a very significant number of participants to be held.\nCumulative points are indicated for each line of the Swiss system standings.\n For more information about the Swiss system, see. https://ru.wikipedia.org/wiki/Швейцарская_система"])},
      "ABOUT_TOURNAMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About tournament"])},
      "ABOUT_TOUR_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All tournaments played on the site are available in this section"])},
      "BUTTON": {
        "PARTICIPATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
        "UNSUBSCRIBE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sing out"])},
        "TOURTABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])}
      },
      "CHECKIN_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion of registration"])},
      "HIDE_PARTICIPANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide the list"])},
      "PARTICIPANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
      "ROWS": {
        "MAX_PLAYERS_FOR_ROUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum number of participants at which the round robin "])},
        "MIN_PLAYERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum number of participants"])},
        "REGISTERED_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered players"])},
        "REGISTRATION_END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion of registration"])},
        "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournament start"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "TIME": {
          "TIMING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing"])},
          "FOR_GAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["game"])},
          "FOR_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["move"])},
          "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
          "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
          "NOTIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no timing"])}
        }
      },
      "SHOW_PARTICIPANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the list"])},
      "TABLE": {
        "COLUMNS": {
          "PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
          "PLAYER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
          "POINTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
          "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])}
        },
        "GAME_NOT_PLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't take place"])},
        "ABSENT_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No-show"])},
        "GAME_START_SOON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting soon"])},
        "TOURNAMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournament game"])},
        "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For rating"])},
        "TIMING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On time"])}
      },
      "TABS": {
        "ABOUT_CHAPTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About section"])},
        "TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])}
      }
    },
    "TOUR_TABLE": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the standings you can watch any game. If it is finished, then in the form of a playlist, if not, then you can see the process of the game itself"])},
      "FORMHEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])}
    }
  },
  "PERSONAL_INVITATIONS": {
    "CANCEL_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse all"])},
    "COLUMNS": {
      "ENEMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent"])},
      "ON_RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For rating`"])},
      "TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "YOUR_COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your color"])}
    },
    "DENY_INVITATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny invitations"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations"])}
  },
  "PROTOCOL": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit the sequence of moves (chain) or create a new one. You can comment on any move. To save in the database, specify the data that will facilitate the search for the chain by you or other users. If you provide a link to an Internet resource related to this chain, then users will be able to find and open it"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chain editior"])},
    "CUTBEFORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut before"])},
    "CUTAFTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut after"])},
    "ADDCOMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add comment"])},
    "GAMENAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "GAMEDESCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "UNDEF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
    "REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "KEYWORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
    "GROUPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups:"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "PLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
    "CONFIRMNULLID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set the value of the id of the chain to zero, and then saving it will create a new copy of it. Zeroing out?"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "CHAINALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current chain has not been saved. Continue anyway?"])}
  },
  "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rated by other users"])},
  "REJECT_INVITATION": {
    "TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refused your invitation"])}
  },
  "START_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start position"])},
  "TAB_BAR": {
    "ABOUT_PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About`"])},
    "LEARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning"])},
    "MAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "PLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
    "TOURNAMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournaments"])},
    "TIMETABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timetable"])},
    "ARCHIEVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
    "SETUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrange checkers"])},
    "PUZZLEOFDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem of day"])},
    "ANALYSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis position"])},
    "LIBRARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "MYGAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My recordings"])},
    "RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent games"])},
    "ANALYZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis position"])},
    "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])}
  },
  "PUZZLE": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solve the problem. In the process of solving, you make moves for both white and black."])},
    "SHOWSOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution and discussion"])},
    "BADRESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more moves"])},
    "GOODRESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations!"])},
    "NOSOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem is not solved"])},
    "SOLUTIONOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem is solved"])}
  },
  "RECENT": {
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
    "WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
    "BLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "WHITE_WON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White won"])},
    "BLACK_WON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black won"])},
    "DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw"])},
    "LOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can download and view your recent games from the archive."])}
  },
  "TEMPLATES": {
    "PLAY": {
      "BOARD": {
        "TOP_PANEL": {
          "COMMON_INVITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find opponent"])},
          "DENY_INVITATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny invitations"])},
          "DENY_WATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny watching"])},
          "ENABLE_AUTO_STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto move"])},
          "ROTATE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotate the board"])},
          "SHOW_ABC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show notation"])}
        }
      },
      "LEADERS": {
        "COLUMNS": {
          "NICKNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          "RATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])}
        }
      },
      "LEFT_TABS": {
        "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About section"])},
        "LEADERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaders"])}
      }
    }
  },
  "TOURNAMENT_NOTIFICATION": {
    "CHECKIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration is over in a minute!"])},
    "START_GAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your game is starting soon"])}
  },
  "TOURNAMENT_STATUS": {
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In archive"])},
    "o": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in opened"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now is playing"])},
    "REG_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in over"])},
    "WAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginning soon"])}
  },
  "TOUR_CYCLE": {
    "Daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dayly tournament"])},
    "Morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning tournament"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])},
    "test1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test1"])},
    "test2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test2"])},
    "test3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test3"])},
    "test4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test4"])}
  },
  "TOUR_SYSTEM": {
    "r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round robin"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss system"])}
  },
  "TURN_ALERT": {
    "BLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black move"])},
    "WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White move"])}
  },
  "WATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watching the game"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For rating games the participation of viewers in the chat is prohibited in order to avoid hints"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "TOURNAMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "WHITES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
    "BLACKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
    "TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing"])},
    "STAKE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating (w/d/l)"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game over"])}
  }
}