<template>
  <svg :width="board.size" :height="board.size" :id="board.pid" >
    <defs>
      <radialGradient id="Gradient"
        cx="0.6" cy="0.6" r="0.4" fx="0.8" fy="0.8" stroke="black">
        <stop offset="0%" stop-color="#050505"/>
        <stop offset="100%" stop-color="#94774C"/>
      </radialGradient>
    </defs>
<!--    <rect x=0 y=0 :width="board.size" :height="board.size" style="fill:#E0C08F;stroke:white" />-->
    <rect x=0 y=0 :width="board.size" :height="board.size" style="fill:#94774C;stroke:#94774C" />
    <rect :x="getstartx()" :y="getstarty()" :width="subboardsize()" :height="subboardsize()" style="fill:#E0C08F;stroke:black" />

    <path v-for="l in wood" :key=l :d="l.line" stroke="#B0A060" fill="#B0A060" fill-opacity="0.3" stroke-opacity="0.3"/>

    <text v-for="n in [0,1,2,3,4,5,6,7]" :key="n" :x="getxalpha()" :y="getytextalpha(n)" 
            :font-size="getfontsize()" >{{num(n)}}</text>
    <text v-for="n in [0,1,2,3,4,5,6,7]" :key="n" :x="getxnum(n)" :y="getytextalpha(board.chcount)" 
            :font-size="getfontsize()" >{{alpha(n)}}</text>
    <rect v-for="c in coord.xy" :key=c :x="c.x" :y="c.y" :height="coord.sz.dx" :width="coord.sz.dx" :style="c.bgstyle"/>
    <rect v-for="c in coord.xy.filter(a => (a.cx>0))" :key=c :x="c.x+2" :y="c.y+2" :height="coord.sz.dx-4" :width="coord.sz.dx-4" fill='url(#Gradient)'/>


    <circle v-for="c in coord.xy.filter(a => (a.cx>0))" :key=c :cx="c.cx" :cy="c.cy" :r="coord.sz.r1" :style="c.style"/>
    <circle v-for="c in coord.xy.filter(a => (a.cx>0))" :key=c :cx="c.cx" :cy="c.cy" :r="coord.sz.r2" :style="c.style"/>
    <polyline v-for="ch,index in board.p1" :key=index :points="getpoints(index)" :style="coord.xy[index].style" />
    <circle v-for="c in coord.killed" :key=c :cx="c.x" :cy="c.y" :r="coord.sz.r1/4" style="fill:red"/>
    <polyline v-for="itin in coord.arrows" :key=itin :points="itin.points" :style="itin.style"/>
  </svg>
</template>

<script type="text/javascript">

  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import {computed} from 'vue'
  import { useI18n } from 'vue-i18n'
  import * as POSITION from '@/hooks/position.js'

  export default {
    props:["board"],
    data(){
      return {
        abc:['A','B','C','D','E','F','G','H']
      }
    },
    setup(props) {
      
      const Mydatastore = useMyDataStore()
      const Boardstore=useBoardStore()
      let  dx09=Math.round(props.board.size*9/496)
      if (dx09 == 0){
        dx09=1
      }
      let  dx15=Math.round(props.board.size*15/496)
      if (dx15 == 0){
        dx15=1
      }
      let stroke3=Math.round(props.board.size*3/496)
      if (stroke3 == 0){
        stroke3=1
      }
      let stroke4=Math.round(props.board.size*4/496)
      if (stroke4 == 0){
        stroke4=1
      }
      let stroke5=Math.round(props.board.size*5/496)
      if (stroke5 == 0){
        stroke5=1
      }

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })

      const getstartx = (() => {
        return props.board.abc?((props.board.size/((props.board.chcount|0)+1))|0):0
      })

      const subboardsize = (() => {
        return props.board.size-getstartx()
      })

      const sizes = (() => {
        let sb=subboardsize()
        let dx=(props.board.chcount == 1)?sb:(sb >>> 3)
        return({
          r1:(dx/2.5)|0,
          dx:dx,
          r2:(dx>=16)?((dx/3.75)|0):null
        })
      })
      
      const trivialchecker = ((sz,n) => {
        let x0=getstartx()
        let y0=0
        if (props.board.chcount != 1){
          x0=x0+sz.dx*(((n >>> 2) & 1) + ((n & 3) << 1 ));  
          y0=y0+sz.dx*(7-(n >>> 2));
        }
        return({
          x:x0,
          y:y0,
          cx:(x0+sz.dx/2)|0,
          cy:(y0+sz.dx/2)|0,
          sz:sz
        })
      })
      
      const selected = ((n) => {
        if (! props.board.selected){
          return false
        }
        for (let i=0;i<props.board.selected.length;i++){
          if (n == props.board.selected[i]){
            return true
          }
        }
        return false
      })

      const checker2point = ((c,n) =>{
        return {
          x:c[n].cxempty,
          y:c[n].cyempty
        }
      })

      const onepoint = ((c,n,reverse) => {
        let n2=reverse?(31-n):n
        let n2point=checker2point(c,n2)
        return n2point.x+','+n2point.y
      })


      const addArrow = ((p1,p2) => {
        let arrowX=[];
        let arrowY=[];
        if (p2.x>p1.x){
          arrowX=[-dx09,-dx15];
        } else {
          arrowX=[dx09,dx15];
        }
        if (p2.y>p1.y){
          arrowY=[-dx15,-dx09];
        } else {
          arrowY=[dx15,dx09];
        }
        return (p2.x+arrowX[0])+','+(p2.y+arrowY[0])+' '+p2.x+','+p2.y+' '+(p2.x+arrowX[1])+','+(p2.y+arrowY[1])
      })

      const oneitin = ((c,n1,itin,style,reverse) => {
        let rez=[]
        let s=onepoint(c,n1,reverse)
        let p1=checker2point(c,reverse?(31-n1):n1)
        for (let i=0;i<itin.length;i++){
          s=s+' '+onepoint(c,itin[i],reverse)
          let p2=checker2point(c,reverse?(31-itin[i]):itin[i])
          rez.push({
            points:addArrow(p1,p2),
            style:style
          })
          p1=JSON.parse(JSON.stringify(p2))
        }
        rez.push({
          points:s,
          style:style
        })
        return rez
      })

      const buildarrows = ((c) => {
        let arrows=[]
        if (props.board.showlast && props.board.lastmove) {
          arrows=arrows.concat(oneitin(c,props.board.lastmove.n1,props.board.lastmove.itin,"fill: none; stroke:grey; stroke-width:"+stroke4,
                                       props.board.turn == props.board.orient))
        }
        if (props.board.showawail && props.board.awail) {
          for (let i=0; i<props.board.awail.length;i++){
            if ((props.board.showawail==2)||(props.board.awail[i].eligible)){
              if (props.board.awail[i].selected == 0){
                arrows=arrows.concat(oneitin(c,props.board.awail[i].n1,props.board.awail[i].itin,
                     "fill: none; stroke:blue; stroke-width:"+stroke5, props.board.turn != props.board.orient))
              }
            }
          }
          for (let i=0; i<props.board.awail.length;i++){
            if ((props.board.showawail==2)||(props.board.awail[i].eligible)){
              if (props.board.awail[i].selected == 1){
                arrows=arrows.concat(oneitin(c,props.board.awail[i].n1,props.board.awail[i].itin,
                     "fill: none; stroke:yellow; stroke-width:"+stroke3, props.board.turn != props.board.orient))
              }
            }
          }
        }
        if (props.board.showitin && props.board.itin) {
          arrows=arrows.concat(oneitin(c,props.board.n1,props.board.itin.slice(0,props.board.showitin),
                                       "fill: none; stroke:yellow; stroke-width:"+stroke3, props.board.turn != props.board.orient))
        }
        return arrows
      })

      const buildkilled = ((c) => {
        let rez=[]
        if (props.board.showkilled){
          //props.board.killed.forEach(a =>{
          for (let i=0;((i<props.board.killed.length)&&(i<props.board.showitin));i++ ){
            let a= props.board.killed[i]
            rez.push(
              (props.board.orient == props.board.turn)?checker2point(c,a):checker2point(c,31-a)
            )
          }//)
        }
        return rez
      })

      const coord = computed (() => {
        let c=[]
        let sz=sizes()
        for (let i=0; i<props.board.p1.length;i++){
          let j=(props.board.orient == props.board.turn)?i:(props.board.p1.length-1-i)
          let tch=trivialchecker(sz,i)
          let realcolor=(props.board.turn == 'b')?POSITION.convrule[props.board.p1[j]]:props.board.p1[j]
          switch (realcolor){  
            case 1:
            case 2:{
              tch.style="fill:white;stroke:black"
              break
            }
            case 3:
            case 4:{
              tch.style="fill:black;stroke:white"
              break
            }
          }
          tch.bgstyle=selected(j)?"fill:yellow;stroke:black":"fill:#94774C;stroke:black"
          c.push({
            x:tch.x,
            y:tch.y,
            cx:props.board.p1[j]?tch.cx:null,
            cy:props.board.p1[j]?tch.cy:null,
            cxempty:tch.cx,
            cyempty:tch.cy,
            style:tch.style,
            queen:(props.board.p1[j]==2)||(props.board.p1[j]==4),
            bgstyle:tch.bgstyle
          })  
        }
        let a= {
          xy:c,
          sz:sz,
          arrows:buildarrows(c),
          killed:buildkilled(c)
        }
        return a
      })

      const wood = computed (() =>{
        let w=[]
        let lcount=50
        let leftbrim=props.board.abc?((props.board.size/9)|0):0
        let bottom=props.board.size-leftbrim
        for (let i=0; i<lcount;i++){
          let spx=leftbrim+((i*props.board.size/lcount+Math.random()*6)-3)|0
          let spy=0
          let dx=leftbrim+((i*props.board.size/lcount+Math.random()*16)-8)|0
          let dy=(bottom/2)|0
          let fx=leftbrim+((i*props.board.size/lcount+Math.random()*24)-12)|0
          let fy=bottom
          let dx1=(dx+Math.random()*6-3)|0
          let dy1=dy
          let spx1=(spx+Math.random()*6-3)|0
          let spy1=0
          let l="M"+spx+' '+spy+' C '+dx+' '+dy+' '+fx+' '+fy+' '+fx+' '+fy+' S '+dx1+' '+dy1+' '+spx1+' '+spy1
          w.push({
            line:l
          })
        }
        return w
//        [
//          {
//            line:"M10 0 C 20 150, 30 190, 25 240 S 30 240, 15 0"
//          },
//        ]
      })

      return {
        getstartx,
        subboardsize,
        trivialchecker,
        coord,
        Mydatastore,
        Boardstore,
        wood,
        t
      }
    },
    methods : {
      alpha(n){
        let ind=(this.board.orient != 'w')?((this.board.chcount|0)-n-1):n
        return (n<this.board.chcount)?this.abc[ind]:null
      },
      getfontsize(){
        return (this.getstartx()*0.6)|0
      },
      getstarty(){
        return 0
      },
      getxalpha(){
        return (this.getstartx()/3)|0
      },
      getxnum(n){
        return this.getxalpha()+(n+1)*((this.board.size/((this.board.chcount|0)+1))|0)
      },
      getytextalpha(n){
        return (this.getstartx()*(((n|0)+0.7)))|0
      },
      imgsize(){
        let a= Math.round(this.Boardstore.getcurrentposition().size/16)|0
        return a
      },
      num(n){
        let n1=(this.board.orient != 'w')?(n+1):((this.board.chcount|0)-n)
        return (n<this.board.chcount)?n1:null
      },
      pointsstrelka(){
        let px=[-10,0,10,0,-10]
        let py=[-5,0,-5,10,-5]
        let position=this.Boardstore.getcurrentposition()
        if (position.orient == position.turn){
          for (let i=0;i<py.length;i++){
            py[i]=-py[i]
          }
        }
        for (let i=0;i<px.length;i++){
          px[i]=Math.round(px[i]*this.imgsize()/36)|0
          py[i]=Math.round(py[i]*this.imgsize()/36)|0
        }
        let center=(this.imgsize()/2)|0
        let rez=((center+px[0])+','+(center+py[0]))
        for (let i=1;i<px.length;i++){
          rez=rez+' '+(center+px[i])+','+(center+py[i])
        }
        return rez
      }, 
      rotateboard(){
        this.Boardstore.getcurrentposition().orient=(this.Boardstore.getcurrentposition().orient == 'w')?'b':'w'
      },
      showboardsettings(){
        this.Mydatastore.showboardsettings()
      },
      stylestrelka(){
        return (this.Boardstore.getcurrentposition().turn == 'w')?'fill:white;stroke:black':'fill:black;stroke:white'
      },
      getpoints(ind){
        let x=this.coord.xy[ind].cx
        let y=this.coord.xy[ind].cy
        if (! this.coord.xy[ind].queen){
          return null
        } 
        let px=[-8,-3, 0, 3,8,5,-5,-8]
        let py=[ -8,1,-8,1,-8,7,7,-8]
        for (let i=0;i<px.length;i++){
          px[i]=Math.round(px[i]*this.coord.sz.dx/55)
          py[i]=Math.round(py[i]*this.coord.sz.dx/55)
        }
        let rez=((x+px[0])+','+(y+py[0]))
        for (let i=1;i<px.length;i++){
          rez=rez+' '+(x+px[i])+','+(y+py[i])
        }
        return rez
        //return ("20,20 40,25 60,40 80,120 120,140 200,180")
      },
      radius(){
        return (this.imgsize()/2.5)|0
      },
      showlast(){
        this.Boardstore.getcurrentposition().showlast=true
      },
      hidelast(){
        this.Boardstore.getcurrentposition().showlast=false
      }
    }
  }

</script>

<style>
  .whitef{
    fill: white;
  }
</style>
