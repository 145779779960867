<template class="mypictures">
  <a-popover v-if="profile && profile.profpublic && profile.profpublic.country && ! profile.robot">
    <template #content>
      {{t("COUNTRY_FLAG."+profile.profpublic.country.replace(".gif",""))}}
    </template>
    <img :src="'flags/'+profile.profpublic.country" :height="imgsize" :width="imgsize*1.5" style="border-style: solid; border-color:grey; border-width: thin;">
  </a-popover>

  <a-popover v-if="profile &&  profile.profpublic &&(! profile.profpublic.country) && (! profile.robot)">
    <template #content>
      {{t("COUNTRY_FLAG.undefined")}}
    </template>
    <img :src="'flags/globe.png'" :height="imgsize" :width="imgsize" >
  </a-popover>

  <a-popover v-if="profile && profile.profsystem && profile.profsystem.champion">
    <template #content>
      {{t("ACHIEVEMENTS_LIST.POPOVER.CHAMPION")}}
    </template>
    <img :src="'crown.png'" :height="imgsize" :width="imgsize" >
  </a-popover>

  <a-popover v-if="profile && profile.profsystem && profile.profsystem.cup">
    <template #content>
      {{t("ACHIEVEMENTS_LIST.POPOVER.CUP")}}
    </template>
    <img :src="'champion.png'" :height="imgsize" :width="imgsize" >
  </a-popover>

  <a-popover v-if="profile && profile.profsystem && profile.profsystem.teacher">
    <template #content>
      {{t("ACHIEVEMENTS_LIST.POPOVER.TEACHER")}}
    </template>
    <img :src="'book.png'" :height="imgsize" :width="imgsize" >
  </a-popover>

  <a-popover v-if="(!profile) || profile.absent">
    <template #content>
      {{t("ACHIEVEMENTS_LIST.POPOVER.ABSENT")}}
    </template>
    <img :src="'absent.png'" :height="imgsize" :width="imgsize" >
  </a-popover>

  <a-popover v-if="profile && profile.robot">
    <template #content>
      {{t("ACHIEVEMENTS_LIST.POPOVER.ROBOT")}}
    </template>
    <img :src="'robot.png'" :height="imgsize" :width="imgsize" >
  </a-popover>

  <a-popover v-if="(profile && (!profile.robot) && iamprivl(profile)) || profile.game2privl">
    <template #content>
      {{t("ACHIEVEMENTS_LIST.POPOVER.PRIVL")}}
    </template>
    <img :src="'gerb.png'" :height="imgsize" :width="imgsize" >
  </a-popover>


</template>

<script type="text/javascript">
  import { useI18n } from 'vue-i18n'
  import * as TIME from '@/sys/time.js'

  export default {
    props:["profile","imgsize"],
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      return { t }
    },
    methods : {
      iamprivl: function(user){
        if (user.privl) {
          return TIME.later(user.privl)
        } else {
          return false
        }
      }
    }
  }
</script>
