import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import {createPinia} from 'pinia'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { VueReCaptcha } from 'vue-recaptcha-v3'


const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(i18n)
app.use(Antd)
app.use(VueReCaptcha, { siteKey: '6Lc6FbMdAAAAALl2NH9kUXshuaUwc-o5LAUh7pD9',loaderOptions:{autoHideBadge: true}})
app.mount('#app')
