<template> 
  <div class="gameroom">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%;">
      <span class="formheader">{{t("PAGES.TOURNAMENTS.TABS.ARCHIVE")}}</span>
      <br>
      <div  @scroll="scroll" id="divext" style="overflow:auto; max-height: 88%;">
        <table id="divid" width="100%">
          <tr align="left">
            <td width="30%">
              {{t('PAGES.TOURNAMENTS.ARCHIVE.COLUMNS.CYCLE')}}
            </td>
            <td width="30%">
              {{t('PAGES.TOURNAMENTS.ARCHIVE.COLUMNS.DATE')}}
            </td>
            <td width="25%">
              {{t('PAGES.TOURNAMENTS.ARCHIVE.COLUMNS.SYSTEM')}}
            </td>
            <td>
              {{t('PAGES.TOURNAMENTS.ARCHIVE.COLUMNS.PLAYERS_NUMBER')}}
            </td>
          </tr>
          <tr v-for="(tour) in Mydatastore.archtour_data" :key="tour.tourid" class="asreference" @click="tourtable(tour.tourid)" align="left">
            <td>
              {{tourname(tour.tourcycle)}}
            </td>
            <td>
              {{tour.openday}}
            </td>
            <td>
              {{t('TOUR_SYSTEM.'+tour.toursystem)}}
            </td>
            <td>
              {{tour.playercount}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    data(){
      return{
        scroll_locked:false
      }
    },
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()

      return {
        t,
        Mydatastore
      }
    },
    methods:{
      tourname(tourcycle){
        return this.t('TOUR_CYCLE.'+tourcycle)
      },
      async scroll(){
        if (document.getElementById("divid").getBoundingClientRect().bottom-document.getElementById("divext").getBoundingClientRect().bottom<50){
          if (!this.scroll_locked){
            this.scroll_locked=true
            this.Mydatastore.archtour_getnextpage()
            await new Promise(r => setTimeout(r, 100));
            this.scroll_locked=false
          }
        }
      },
      tourtable(tourid){
        this.Mydatastore.showTourtable(tourid)
      },
    }
  })
</script>