<template>
<div class="fixed-overlay fixed-overlay__modal" >
    <div class="modal bordered width80" @click="pause">
        <div align="right">
          <img src="close.png" class="asreference" width="16" height="16" @click="closewin">
        </div>            
        <div class="modal_container textcenter">
          {{t('CARTOON.MOVECHOICE')}}
          <div class="bordered" style="max-height: 380px; overflow: auto;">
            <table>
              <tr v-for="mch in BoardStore.movechoice" :key=mch :bgcolor="selectedchoice(mch.id)" @click="select(mch.id)"> 
                <td>
                  <CheckersBoard :board="mch.position"/>
                </td>
                <td align="left">
                  <b>{{move2str(mch)}}</b>
                  <span v-for="str in mch.slaves" :key=str><br>{{str.gamename}}</span>
                </td>
              </tr>
            </table>
          </div>
          <div style="padding: 4px;">
            <button @click="closewin">{{t('COMMON.CANCEL')}}</button>
            <button @click="mkchoice" :disabled="nochoice()">{{t('COMMON.READY')}}</button>
          </div>
        </div>
    </div>
</div>
</template>

<script>
  import { defineComponent} from 'vue'
  import { useI18n } from 'vue-i18n'
  import {useBoardStore} from '@/store/BoardStore.js'
  import CheckersBoard from '@/components/CheckersBoard'
  import * as POSITION from '@/hooks/position.js'


  export default defineComponent({
    components:{
      CheckersBoard,
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const BoardStore = useBoardStore()

      const closewin=( () => {
        BoardStore.modalvisible='none'
        BoardStore.cancelchoice()
      })

      return {
        t,
        BoardStore,
        closewin
      }
    },
    methods:{
      keyhandler(e){
        if (e.key == 'Escape') {
          this.closewin()
        } else {
          if (e.key == 'Enter') {
            this.mkchoice()
          }
        }
      },
      mkchoice(){
        this.BoardStore.mkchoice()
      },
      move2str(x){
        return POSITION.move2str(x.position)
      },
      nochoice(){
        for (let i=0;i<this.BoardStore.movechoice.length;i++){
          if (this.BoardStore.movechoice[i].id == this.BoardStore.selectedchoice){
            return false
          }
        }
        return true
      },
      selectedchoice(n){
        return (n==this.BoardStore.selectedchoice)?'lightblue':null
      },
      select(i){
        this.BoardStore.selectchoice(i)
      }
    }
  });

</script>

<style type="text/css">
  .width80{
    left: 10%;
    width: 80%;
  }
</style>