<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('AUTH_MODAL.TITLE.FORGOT_PASSWORD')}}
    </template>
    <template v-slot:body>
      <a-form
        :model="formState"
        name="normal_login"
        class="login-form"
      >
        <a-form-item
          v-bind:label="t('AUTH_MODAL.COMMON.EMAIL.LABEL')"
          name="email"
          :rules="[{ required: true, message: t('AUTH_MODAL.COMMON.EMAIL.REQUIRED') }]"
        >
          <a-input v-model:value="formState.email" :maxlength=90 v-on:keydown.enter="forgot">
            <template #prefix>
              <MailOutlined class="site-form-item-icon" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-button :disabled="disabled" type="primary" @click="forgot" class="login-form-button">
            {{t('AUTH_MODAL.RESET_PASSWORD.BUTTON')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent,reactive,computed } from 'vue';
  import { MailOutlined } from '@ant-design/icons-vue';
  import {useMyDataStore, getlangnum, notify} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import axios from 'axios'
  import * as SYSCONST from '@/sys/sysconst.js'

  export default defineComponent({
    components: {
      MailOutlined,
      UniversalModal
    },

    setup() {
      const formState = reactive({
        email: ''
      });
      
      const disabled = computed(() => {
        return !(formState.email);
      });

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
    
      const forgot = async () =>{
        axios.post(SYSCONST.URL_forgotpass, {
          emailaddr:formState.email,
          langdivforget:getlangnum()
        }).then((res) => {
          const errCode = res.data?.out_error_code
          const msg={
            title: t('AUTH_MODAL.TITLE.FORGOT_PASSWORD'),
            txt: t('AUTH_MODAL.FORGOT_PASSWORD.SUCCESS')
          }
          if (errCode){
            msg.title=t('COMMON.ERROR')
            msg.txt=t('AUTH_MODAL.FORGOT_PASSWORD.ERROR.'+errCode)
          } else {
            Mydatastore.hideModal()
          }
          notify(msg.title,msg.txt);
        }) 
      }
      return {
        formState,
        disabled,
        t,
        Mydatastore,
        forgot
      }
    },

  });

</script>
<style>
.login-form {
  max-width: 300px;
}
.login-form-button {
  width: 100%;
  margin-top: 16;
}
</style>
