import { defineStore } from 'pinia'
import axios from 'axios';
import * as SYSCONST from '@/sys/sysconst.js'
import * as TIME from '@/sys/time.js'
import  useI18n  from '../i18n.js'
import { notification } from 'ant-design-vue'
import {reactive} from 'vue'
import * as SOCKETUTILS from '@/hooks/socketutils.js'
import {useBoardStore} from '@/store/BoardStore.js'
import * as POSITION from '@/hooks/position.js'
import {calcprize,grestxt,watchgameresult} from '@/hooks/utils.js'

export const bignum=1000000000000;

let socket;
const { t } = useI18n.global

const empty_filter={
  name:"getlist",
  rnd:"library",
  p1:null,
  p2:null,
  turn:null,
  amysaved:0, 
  apublished:null,
  aplayer:null, 
  arating:0, 
  aminimalrating:null, 
  akeywords :'',
  ashowtypes:'',
  aminimalid:bignum, 
  acnt:20,
  aowner:0,
  afavor:0
}

export function sendsmth(inp){
  if (socket && socket.readyState){
    socket.send(JSON.stringify(inp));
  } else {
    notify(t('COMMON.NETWORKERROR'))
  }
}

function closesocket(){
  try {
    if (socket) {
      socket.close();
    }
  } catch(err) {
    notify(t('COMMON.NETWORKERROR'))
  }
}

function compareleaders(newl,oldl) {
  let result=[];
  if (oldl.length){
    for (let i=0;i<newl.length;i++){
      let found=0;
      for (let j=0;j<oldl.length;j++){
        if (newl[i].id == oldl[j].id){
          found=1;
          if (i<j) {
            result.push({
              act:'bg',
              ind:i,
              where2:'up'
            })
          } else {
            if (i>j) {
              result.push({
                act:'bg',
                ind:i,
                where2:'down'
              })
            }
          }
          if (newl[i].rating>oldl[j].rating) {
            result.push({
              act:'ar',
              ind:i,
              where2:'up'
            })
          } else {
            if (newl[i].rating<oldl[j].rating) {
              result.push({
                act:'ar',
                ind:i,
                where2:'down'
              })
            }
          }
          break; 
        }
      }
      if (! found){
        result.push({
          act:'bg',
          ind:i,
          where2:'up'
        })
      }        
    }
  } 
  return result;
}

export function setURL(param){
  //console.log('seturl '+param)
  let newhref="";
  if (location.protocol){
    newhref=location.protocol+"//";
  }
  newhref=newhref+location.hostname;
  if (location.port){
    newhref=newhref+":"+location.port;
  }
  if (param){
    newhref=newhref+'/?'+param
  }
  history.pushState(null, null,newhref);
}

export function cleanURL(){
  //console.log('cleanURL')
  setURL(null)
}

export async function sockinit(wsaddr,uname,pass){
  //console.log('sockinit')
  try {
    socket = new WebSocket(wsaddr);
    socket.addEventListener('error', (event) => {
      console.log('WebSocket error: ', event);
    });
    //socket = new WebSocket('wss://www.mycheckers.online:8082/');
    for (let i=0; i<10; i++) {
      //sleep
      await new Promise(r => setTimeout(r, 500));
      if (socket.readyState) {
        sendsmth({
          name:"authdata",
          username: uname,
          userpass: pass
        })
        useMyDataStore().spinning=true
        break;
      }
      if (i==9) {
        useMyDataStore().spinning=false
        alert("No connection");
        return;
      }
    }
    socket.onclose = function(){
      //postlogout();
      useMyDataStore().spinning=false
      if (useMyDataStore && useMyDataStore.mydata && useMyDataStore.mydata.login){
        setTimeout(sockinit,100,wsaddr,uname,pass);
      }
    };
    socket.onmessage = function(event) {
      var incomingMessage = event.data;
      let receivedobj=JSON.parse(incomingMessage);
      if ((receivedobj.name != "yourdata" )){
        cleanURL()
        //console.log('clean 164 '+receivedobj.name)
      }
      messdisp(receivedobj)
    };
  }
  catch(error){
    console.error("socket init error:"+error);
  }
}

function lang2locale(lang){
  switch (lang){
    case 'RUS': return 'ru'
    case 'ENG': return 'en'
    default :return 'en'
  }
}

function locale2lang(locale){
  switch (locale){
    case 'ru': return 'RUS'
    case 'en': return 'ENG'
    default :return 'ENG'
  }
}

export function getlangnum(){
  switch (useI18n.global.locale.value){
    case 'ru': return 0
    case 'en': return 1
    default :return 0
  }
}

export const useMyDataStore = defineStore('MyData', {
  state: () => {
    let restoringcode1;
    let modalvisible1='none';
    let startgameid;
    let starttor1;
    let activationcode

    let href=location.href;
    if (href.match("dbgame=[0-9]+")){
      let st1=href.match("dbgame=[0-9]+")[0];
      startgameid=st1.match("[0-9]+")[0];
    }
    if (href.match("tournament=[0-9]+")){
      let st1=href.match("tournament=[0-9]+")[0];
      starttor1=st1.match("[0-9]+")[0];
    }
    if (href.match("restoring=[A-Z]+")){
      let st1=href.match("restoring=[A-Z]+")[0];
      restoringcode1=st1.match("[A-Z]+$")[0];
      modalvisible1='Twopassword'
    }
    if (href.match("activation=[A-Z]+")){
      let st1=href.match("activation=[A-Z]+")[0];
      activationcode=st1.match("[A-Z]+$")[0];
      axios.post(SYSCONST.URL_activation, {
        activation:activationcode
      }).then((res) => {
        const errCode = res.data?.out_error_code
        cleanURL()
        let txt
        if (errCode){
          txt='ACTIVATION.ERROR.1'
        } else {
          txt='ACTIVATION.SUCCESS'
          this.modalvisible='Login'
        }
        notify(t(txt));
      })
    }

    return {
      activeKey:'1', 
      alarmstartgame:null,
      allgames:[],
      analyzedata:{
        pos1:null,
        roboton:false,
        robotid:null,
        dbon:false,
        minrating:1000,
        description:null
      },
      archtour_firstentry:1,
      archtour_data:[],
      archtour_lastid:null,
      Boardstore:useBoardStore(),
      chat:[],
      clocktoken:null,
      commoninvitations:[],
      currenttournament:null,
      currentinvitations:[],
      favors:[],
      game:null,
      idfilter:0,
      mydata:null,
      mygames:[],
      mygames_lastid:bignum,
      myparams:null,
      leaders:[],
      library:[],
      library_filter:JSON.parse(JSON.stringify(empty_filter)),
      library_firstrequest:true,
      library_lastid:bignum,
      locale:'ru',
      ownerfilter:0,
      ownerfilteron:0,
      ownerfilterlist:[],
      owner_usertxt:'',
      ratingfilteron:false,
      ratingfilter:0,
      ratingfilterlist:[],
      modalvisible:modalvisible1,
      recent:[],
      startgame:startgameid,
      restoringcode:restoringcode1,
      showtournament:null,
      pagesection:"PAGES.MAIN.DESCRIPTION",
      //personalinvitations:[],
      playlisttitleid:null,
      pseudoswitch:false,
      rightpanel:"solution",
      rightsubregime:"TOD",
      currentplaylist:null,
      spinning:false,
      settingsformState: reactive ({
        country:'',
        dontinvite: false,
        dontwatch: false,
        autostep: false,
      }),
      starttournament:starttor1,  
      tournaments:[],
      tourtable:null,
      users:[],
      lastactivity:new Date()
    }
  },
  actions: {
    setlastactivity(){
      this.lastactivity=new Date()
    },
    TOD2playlist(){
      this.currentplaylist=JSON.parse(JSON.stringify(this.myparams.PUZZLEREXP))
      this.rightsubregime='TOD'
      this.pagesection="PAGES.MAIN.DESCRIPTION"
      //this.rightpanel=  "solution"
      this.Boardstore.setplaylist(this.currentplaylist)
      this.Boardstore.buildtree()
    },
    async setmyparams() {
      try {
        const mp = await axios.get(SYSCONST.URL_myparams)
        this.myparams = mp.data
        if (mp.data.PUZZLEREXP){
          if (this.changeright('solution')){
            //console.log('setmyparams worked')
            this.TOD2playlist()
          }
        }
        return mp.data
      }
      catch (error){
        console.log(error)
      }
    },
    cleararrowmydata(){
      this.mydata.arrow=undefined
    },
//    testmydata(a){
//      let msg=JSON.parse(JSON.stringify(this.mydata))
//      msg.rating+=a
//      this.msg_yourdata(msg)
//    },
    msg_yourdata(msg){
      if (this.modalvisible == 'Login'){
        this.modalvisible='none'
      }
      if (this.mydata && this.mydata.rating && (this.mydata.rating != msg.rating) ){
        msg.arrow=(this.mydata.rating>msg.rating)?'redarrow.png':'greenarrow.png'
        setTimeout(this.cleararrowmydata,3000)
      }
      this.mydata=msg
      TIME.setservertime(msg.sysdate)
      this.locale=lang2locale(msg.profprivate.lang)
//      document.getElementById("LanguageSelector").value=this.locale
      useI18n.global.locale.value = this.locale
      if (this.starttournament){
        this.showTourtable(this.starttournament)
        this.starttournament=null
      } else {
        if (this.startgame){
          this.loadgame(this.startgame,'library')
          this.startgame=null
        }
      }
      if (this.Boardstore){
        this.Boardstore.acceptyourdata(msg)
      }
      //console.log('msg_yourdata finished')
    },
    async setarrow(i,pic,targetarray){
      let elem=targetarray[i];
      elem.arrow=pic;
      let leaderid=elem.id?elem.id:elem.userid;
      targetarray.splice(i,1,elem);
      await new Promise(r => setTimeout(r, 3000));
      for (let j=0; j<targetarray.length; j++){
        if ((targetarray[j].id &&(targetarray[j].id==leaderid))||(targetarray[j].userid &&(targetarray[j].userid==leaderid))){
          elem=targetarray[j];
          elem.arrow=undefined;
          targetarray.splice(j,1,elem)
          break;
        }
      }
    },
    async fadebackground(i,r0,g0,b0){
      let elem=this.leaders[i]
      let next=1;
      let r1=r0;
      let g1=g0;
      let b1=b0;
      while (next) {
        next=0;
        if (r1<255){
          r1++;
          next=1;
        }
        if (g1<255){
          g1++;
          next=1;
        }
        if (b1<255){
          b1++;
          next=1;
        }
        if (next){
          elem.bgcolor=r1.toString(16)+g1.toString(16)+b1.toString(16);
          this.leaders.splice(i,1,elem)
          await new Promise(r => setTimeout(r, 10));
        } else {
          elem.bgcolor=undefined;
        }
      }
    },
    testfading(doing){
      let a={
        data:JSON.parse(JSON.stringify(this.leaders))
      }
      switch (doing){
        case 0: {
          a.data[3].rating--;
          break;
        }
        case 1: {
          a.data[4].rating++;
          break;
        }
        case 2: {
          let t3=JSON.parse(JSON.stringify(a.data[3]))
          let t4=JSON.parse(JSON.stringify(a.data[4]))
          a.data[3]=t4;
          a.data[4]=t3;
          break;
        }
      }
      this.msg_leaders(a)
    },
    async msg_leaders(msg){
      let compres=[]
      if (this.leaders.length){
        compres=compareleaders(msg.data, this.leaders)
      }
      this.leaders=[]
      msg.data.forEach(a => this.leaders.push(a))
      compres.forEach(cr =>{
        if (cr.act == 'bg') {
          let r=255;
          let g=255;
          if (cr.where2 == 'up') {
            r=0;
          } else {
            g=0;
          }
          this.fadebackground(cr.ind, r,g,0);
        } else {
          let arrowname='redarrow.png'
          if (cr.where2 == 'up'){
            arrowname='greenarrow.png'
          }
          this.setarrow(cr.ind,arrowname,this.leaders)
        }
      }) 
    },
    exitback(){
      if (this.where2switch){
        this.changeright(this.where2switch)
        this.where2switch=null
      }
    },
    changeright(newwin){
      //console.log('newwin='+newwin)
      this.setlastactivity()
      if (newwin=='game'){
        setURL('')
        //console.log('clean 470')
        this.rightpanel='game'
        this.pagesection='GAME.DESCRIPTION'
        return true
      }
      if ((this.rightpanel=='game')&&(this.game)) {
        notify(t('GAME.SHOWMUSTGOON'))
        return false
      }
      if (this.rightpanel=='game'){
        SOCKETUTILS.getinvitations()
      }
      if ((this.rightpanel != 'watching')&&(newwin=='watching')){
        this.where2switch=this.rightpanel
      }
      if ((this.rightpanel=='watching')&&(newwin != 'watching')){
        this.watching=null
        SOCKETUTILS.stopwatching()
      }
      if ((this.rightpanel !='analyze')&&(newwin == 'analyze')){
        this.where2switch=this.rightpanel
      }
      let ok=true
      if ((this.rightpanel=='protocol')&&(this.Boardstore.editchain.changed)){
        ok=confirm(t('PROTOCOL.CHAINALERT'))
      }
      if (ok){
        this.rightpanel=newwin
        if (newwin=='gameroom'){
          this.pagesection="PAGES.PLAY.DESCRIPTION"
        } else {
          if (newwin == 'tourtable'){
            this.pagesection='PAGES.TOUR_TABLE.DESCRIPTION'
          } else {
            if (newwin=='cartoon'){
              this.pagesection="CARTOON.DESCRIPTION"
            } else {
              if (newwin == 'discussion'){
                this.pagesection="DISCUSSION.DESCRIPTION"
              } else {
                if (newwin == 'library'){
                  this.pagesection="LIBRARY.DESCRIPTION"
                } else {
                  if (newwin=='analyze'){
                    this.pagesection="ANALYZE.DESCRIPTION"
                  } else {
                    if (newwin=='about'){
                      this.pagesection=='ABOUT.DESCRIPTION'
                    } else {
                      if (newwin=='setposition'){
                        this.pagesection="POSITION.DESCRIPTION"
                      } else {
                        if (newwin=='mygames'){
                          this.pagesection="MYGAMES.DESCRIPTION"
                        } else {
                          if (newwin=='recent'){
                            this.pagesection="RECENT.DESCRIPTION"
                          } else {
                            if (newwin=='protocol'){
                              this.pagesection="PROTOCOL.DESCRIPTION"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } 
      }
      if (ok && ((newwin=='recent')||(newwin=='gameroom')||
                 (newwin=='tourtimetable')||(newwin=='mygames')||(newwin=='setposition')||(newwin=='archtour'))){
        setURL('')
        //console.log('clean 543: '+newwin)
      }
      
      return ok
    },
    setAnyfilter(){
      this.library_filter.aminimalid=bignum
      this.library=[]
      sendsmth(this.library_filter)
      this.modalvisible='none'
    },
    setOwnerfilter(){
      if (this.ownerfilteron){
        this.library_filter.aowner=this.ownerfilter
        this.library_filter.afavor=0
        this.library_filter.arating=0
        this.library_filter.amysaved=0
        this.idfilter=0
      } else {
        this.library_filter.aowner=0
      }
      this.setAnyfilter()
    },
    setRatingfilter(filtertype,minrat){
      this.library_filter.aowner=0
      this.library_filter.afavor=0
      this.idfilter=0
      this.library_filter.arating=(filtertype == 1)?0:1
      this.library_filter.aminimalrating=(filtertype == 3)?minrat:null
      this.library_filter.aplayer=(filtertype == 4)?this.ratingfilter:null
      this.ratingfilteron=(filtertype != 1)
      this.library_filter.amysaved=0
      this.setAnyfilter()
    },
    setbookmarkfilter(){
      this.library_filter=JSON.parse(JSON.stringify(empty_filter))
      this.ownerfilteron=0
      this.ratingfilteron=false
      this.library_filter.afavor=1
      this.setAnyfilter()
    },
    setIdfilter(idf){
      if (!isNaN(parseFloat(idf)) && isFinite(idf) && (idf>=0) && (idf == (idf | 0))){
        this.library_filter=JSON.parse(JSON.stringify(empty_filter))
        this.ownerfilteron=0
        this.ratingfilteron=false
        this.library=[]
        this.library_filter.aminimalid=bignum
        this.idfilter=idf
        SOCKETUTILS.loadgame(idf,'libfilter')
      } else {
        notify(t('COMMON.ERROR'),t('LIBRARY.WRONGID'))
        this.idfilter=0
      }
      this.modalvisible='none'
    },
    setPosfilter(p1,p2,turn){
      this.library_filter.p1=JSON.parse(JSON.stringify(p1))
      this.library_filter.p2=JSON.parse(JSON.stringify(p2))
      this.library_filter.turn=turn
      this.setAnyfilter()
    },
    dropIdfilter(){
      this.idfilter=0
      this.library=[]
      sendsmth(this.library_filter)
    },
    nulllibraryfilter(){
      this.library_filter=JSON.parse(JSON.stringify(empty_filter))
      this.ownerfilteron=0
      this.ratingfilteron=false
      this.library=[]
      this.idfilter=0
    },
    dropLibraryfilter(){
      this.nulllibraryfilter()
      sendsmth(this.library_filter)
    },
    showCartoon(){
      //console.log('showCartoon')
      this.changeright("cartoon")
    },
    showDiscussion(){
      this.changeright('discussion')
    },
    showboardsettings(){
      this.modalvisible='boardsettings'
    },
    showHelp(){
      this.modalvisible='help'
    },
    showIdfilter(){
      this.modalvisible='Idfilter'
    },
    showPosfilter(){
      this.modalvisible='Posfilter'
    },
    showOwnerfilter(){
      this.modalvisible='Ownerfilter'
    },
    showRatingfilter(){
      this.modalvisible='Ratingfilter'
    },
    showSearchfilter(){
      this.modalvisible='Searchfilter'
    },
    showLogin(){
      this.modalvisible='Login'
    },
    showRegister(){
      this.modalvisible='Register'
    },
    showSettings(){
      this.settingsformState.country=this.mydata.profpublic.country
      this.settingsformState.dontinvite=(this.mydata.dontinvite == 1)
      this.settingsformState.dontwatch=(this.mydata.nowatch == 1)
      this.settingsformState.autostep=(this.mydata.profprivate.enabledAutoSingleStep == 1)
      this.modalvisible='Settings'
    },
    savesettings(){
      this.mydata.profpublic.country=this.settingsformState.country
      this.mydata.dontinvite=this.settingsformState.dontinvite?1:0
      this.mydata.nowatch=this.settingsformState.dontwatch?1:0
      this.mydata.profprivate.enabledAutoSingleStep=this.settingsformState.autostep?1:0
      this.modalvisible='None'
      SOCKETUTILS.setSettings(this.mydata.profpublic.country, this.mydata.dontinvite, this.mydata.nowatch, this.mydata.profprivate.enabledAutoSingleStep)
    },
    hideModal(){
      this.modalvisible='None'
    },
    archtour_getnextpage(){
      if (this.archtour_lastid>0){
        SOCKETUTILS.nextarchpage(this.archtour_lastid)
      }
    },
    mygames_getnextpage(){
      if (this.mygames_lastid>0){
        SOCKETUTILS.requestmygames(this.mygames_lastid)
      }
    },
    library_getnextpage(){
      if (this.library_lastid>0){
        this.library_filter.aminimalid=this.library_lastid
        sendsmth(this.library_filter)
      }
    },
    initalarm(astarttime){
      if (this.alarmstartgame != astarttime){
        this.alarmstartgame=astarttime;
        let ms=TIME.timeremainms(astarttime);
        if (ms <=60000 ){
          this.showAlarm();
        } else {
          setTimeout(this.showAlarm,ms-60000);
        }
      }
    },
    setplaylisttitleid(){
      let firstid
      for (let i=0;i<this.mygames.length;i++){
        if (this.mygames[i].chb){
          firstid=this.mygames[i].gid
          break
        }
      }
      this.playlisttitleid=firstid
    },
    setslaves(slaves){
      for (let i=0;i<this.mygames.length;i++){
        if (this.mygames[i].gid == this.playlisttitleid){
          this.mygames[i].slaves=slaves
          break
        }
      }
    },
    showAlarm(){
      this.modalvisible='Alarm'
    },
    showInvList(){
      this.modalvisible="Invitations"
    },
    refreshMygames(){
      this.mygames_lastid=bignum
      this.mygames.splice(0)
      SOCKETUTILS.requestmygames(this.mygames_lastid)
    },
    requestfavors(){
      sendsmth({
        name:"getlist",
        rnd:"favor",
        p1:null,
        p2:null,
        amysaved:null, 
        apublished:null,
        aplayer:null, 
        arating:null, 
        aminimalrating:null, 
        akeywords :null,
        ashowtypes:null,
        aminimalid:bignum, 
        acnt:bignum,
        aowner:0,
        fafavor:1
      })
    },
    showLibrary(){
      if (this.changeright('library')){
        if (this.library_firstrequest) {
          this.requestfavors()
          this.library_firstrequest=false
        }
        if (this.library.length==0){  
          sendsmth(this.library_filter)
        }
      }
    },
    showAnalyze(){
      this.changeright('analyze')
    },
    showAbout(){
      //this.where2switch=this.rightpanel
      //this.changeright('about')
      this.modalvisible='aboutmodal'
    },
    showSetuppos(){
      if (this.changeright('setposition')){
        this.Boardstore.dropitin()
      }
    },
    showMygames(){
      if (this.changeright('mygames')){
        if (this.mygames.length == 0) {
          SOCKETUTILS.requestmygames(this.mygames_lastid)
        }
      }
    },
    showRecent(){
      if (this.changeright('recent')){
        SOCKETUTILS.requestrecent()
      }
    },
    showProtocol(){
      this.changeright('protocol')
    },
    showPuzzle(){
      //console.log('showPuzzle')
      if (this.changeright('solution')){
        this.pagesection="PUZZLE.DESCRIPTION"
        this.rightsubregime='puzzle'
      }
    },
    showGameroom(){
      this.changeright('gameroom')
    },
    showCurrentTour(tourid){
      this.currenttournament=tourid
      this.changeright("tourtable")
      for (let i=0; i< this.tournaments.length; i++){
        if (this.tournaments[i].tourid==tourid) {
          this.tourtable=this.tournaments[i]
          break
        }
      }
    },
    showTourtable(tourid){
      if (this.changeright('tourtable')){
        this.currenttournament=0
        this.tourtable=null
        SOCKETUTILS.reqtourn(tourid)
        this.spinning=true
      }
    },
    showTourtimetable(){
      if (this.changeright("tourtimetable")){
        this.pagesection="PAGES.TOURNAMENT_INFO.ABOUT_CHAPTER"
      }
    },   
    showTourarchive(){
      if (this.changeright("archtour")){
        this.pagesection="PAGES.TOURNAMENT_INFO.ABOUT_TOUR_ARCHIVE"
        if (this.archtour_firstentry){
          this.archtour_firstentry=0;
          SOCKETUTILS.nextarchpage(bignum)
        }
      }
    },
    update4show(a,prefix){
      a.pid=prefix+a.gameid
      a.size=64
      a.showitin=0
      a.showkilled=0
      a.p1=a.pos1
      a.orient=a.board
    },
    load_library(list){
      this.library_lastid=0
      list.forEach(a => {
        this.update4show(a,'lib')
        this.library.push(a)
        this.library_lastid=a.gid
      })
    },
    load_favors(list){
      this.favors=[]
      list.forEach(a => {
        this.favors.push(a)
      })
    },
    load_mygames(list){
      this.mygames_lastid=0
      list.forEach(a => {
        this.update4show(a,'mygames')
        a.chb=false
        this.mygames.push(a)
        this.mygames_lastid=a.gid
      })
    },
    spinneroff(err){
      if (this.spinning && err){
        notify(t('COMMON.ERROR'),t('COMMON.TIMEOUT'))
      }
      this.spinning=false
    },
    spinneron(){
      this.spinning=true
      setTimeout(this.spinneroff,30000,true)
    },
    loadgame(gameid,text){
      SOCKETUTILS.loadgame(gameid,text)
      this.spinneron()
    },
    msg_dbgame(msg){
      if (msg.data.gamedata && (msg.data.gamedata.rnd == 'libfilter')){
        let libelem={
          gid:msg.data.gamedata.gameid,
          ownr:msg.data.gamedata.ownerid,
          ownrlogin:msg.data.gamedata.ownerlogin,
          w:msg.data.gamedata.wplayer,
          wlogin:msg.data.gamedata.wlogin,
          wrating:msg.data.gamedata.wrating,
          b:msg.data.gamedata.bplayer,
          blogin:msg.data.gamedata.blogin,
          brating:msg.data.gamedata.brating,
          pos1:msg.data.gamedata.moves.length?msg.data.gamedata.moves[0].pos1:null,
          dfinish:msg.data.gamedata.finish,
          gres:msg.data.gamedata.gres,
          comment:msg.data.gamedata.gamename,
          published:msg.data.gamedata.public,
          commenabled:msg.data.gamedata.commenabled,
          tournament:msg.data.gamedata.tournament,
          virtual:msg.data.gamedata.virtual,
          gamerating:msg.data.gamedata.grating,
          showtypes:msg.data.gamedata.showtypes,
          slaves:[]
        }
        msg.data.gamedata.slaves.forEach(a => libelem.slaves.push(a.gameid))
        this.update4show(libelem,'lib')
        this.library.push(libelem)
        return
      }
      if (msg.data.gamedata && (msg.data.gamedata.rnd == 'library')){
        if (msg.data.gamedata.moves.length==0){
          notify(t('LIBRARY.EMPTYGAME'))
          this.spinneroff()
          return
        }
        setURL('dbgame='+msg.data.gamedata.gameid)
        this.currentplaylist=JSON.parse(JSON.stringify(msg.data.gamedata))
        if ((msg.data.gamedata.ownerid==0)&&(msg.data.gamedata.gamename=='')){
          this.createtitle(msg.data.gamedata)
        }
        this.Boardstore.setplaylist(msg.data.gamedata)
        this.Boardstore.buildtree()
        if (this.library_filter.p1){
          this.Boardstore.startfrom(this.library_filter.p1,this.library_filter.p2,this.library_filter.turn)
        }
        this.showCartoon()
        //console.log('cartoon')
        return
      }
      if (msg.data.gamedata && (msg.data.gamedata.source == 'recent')){
        this.createtitle(msg.data.gamedata)
        this.Boardstore.setplaylist(msg.data.gamedata)
        this.Boardstore.buildtree()
        this.Boardstore.orientrecent()
        this.showCartoon()
        return
      }
      if (msg.data.gamedata && (msg.data.gamedata.rnd == 'puzzle')){
        //console.log('937 puzzle')
        setURL('dbgame='+msg.data.gamedata.gameid)
        this.currentplaylist=JSON.parse(JSON.stringify(this.myparams.PUZZLEREXP))
        this.Boardstore.setplaylist(msg.data.gamedata)
        this.Boardstore.buildtree()
        this.showPuzzle()
        return
      }

    },
    createtitle(playlist){
      let gres="";
      if (playlist.gres>=0){
        switch (playlist.gres){
          case 0:{
            gres=" (1/2 - 1/2)"
            break
          }
          case 1: {
            gres=" (1 - 0)"
            break
          }
          case 2: {
            gres=" (0 - 1)"
            break
          }
        }
      }
      playlist.gamename=playlist.wlogin+' : '+playlist.blogin+' '+gres+' '+playlist.finish
    },
    msg_userbymask(msg){
      if (msg.rnd == 'ownerfilter'){
        this.ownerfilterlist=[]
        msg.userlist.forEach(a => this.ownerfilterlist.push(a))
        if (msg.userlist.length){
          this.ownerfilter=msg.userlist[0].id
        } else {
          this.ownerfilter=0
        }
      } else {
        if (msg.rnd == 'ratingfilter'){
          this.ratingfilterlist=[]
          msg.userlist.forEach(a => this.ratingfilterlist.push(a))
          if (msg.userlist.length){
            this.ratingfilter=msg.userlist[0].id
          } else {
            this.ratingfilter=0
            notify(t('COMMON.ERROR'),t('COMMON.USER_NOT_FOUND'))
          }
        }
      }
    },
    updategamerating(gameid,grating){
      for (let i=0;i<this.library.length;i++){
        if (this.library[i].gid == gameid){
          this.library[i].gamerating=grating
          break
        }
      }
    },
    msg_mycomment(msg){
      this.Boardstore.msg_mycomment(msg)
    },
    msg_mygames(msg){
      if (msg.games.length){
        if (!this.game){
          this.changeright('game')
          this.game=msg.games[0]
          this.currenttournament=this.game.tournament
        } else {
          this.game.gid=msg.games[0].gid
          this.game.pos=JSON.parse(JSON.stringify(msg.games[0].pos))
          this.game.turn=msg.games[0].turn
          this.game.am=JSON.parse(JSON.stringify(msg.games[0].am))
        }
      }
      this.Boardstore.msg_mygames(msg)
    },
    msg_refuseagain(msg){
      if (this.rightpanel=='game'){
        notify(t('GAME.'+msg.txt.toUpperCase()))
        if (this.where2switch){
          this.changeright(this.where2switch)
        }
      }
    },
    gameover(msg){
      let againpossible=0
      let iamleaving=1
      let opponentuser=this.getuser(this.game.opponent)
      if ((msg.reason != 2) && (this.game ) && (! this.game.tournament) && opponentuser && (opponentuser.robot == 0) && (this.game.mycolor != "wb")){
        againpossible=1
      }
      let grtxt
      if ((this.game)&&(this.game.gid)){
        grtxt=grestxt(msg,this.game.mycolor)
      } else {
        grtxt=watchgameresult(msg)
        againpossible=0
      }
      if (againpossible){
        let iagree
        if (confirm(grtxt+" "+t("GAME.PLAYAGAIN"))){
          iagree=1
          iamleaving=0
        } else {
          iagree=0
        }
        sendsmth({
          name:"playagain",
          iagree:iagree
        })
      } else {
        alert(grtxt)
      }
      this.where2switch=this.game.tournament?'tourtable':'gameroom'
      let tourn=this.game.tournament
      this.gamerecent=JSON.stringify(this.game)
      this.game=null
      if (tourn){
        this.showCurrentTour(this.currenttournament)
      } else {
        if (iamleaving){
          this.changeright(this.where2switch)
        }
      }
    },
    watchover(msg){
      //this.watching=null
      let grtxt=watchgameresult(msg)
      this.watching.grestxt=grtxt
      notify(grtxt)
    },
    dectime(){
      if (this.game && (this.game.mytime>0) && (this.game.opptime>0)){
        if (this.game.mycolor==this.game.turn){
          this.game.mytime--
        } else {
          this.game.opptime--
        }
      } else {
        if (this.watching && (!this.watching.grestxt)){
          if (this.watching.time2count=='w'){
            this.watching.wtime--
          } else {
            this.watching.btime--
          }
        }
      }
      this.clocktoken=setTimeout(this.dectime,1000)
    },
    msg_timeremain(msg){
      if (this.clocktoken){
        clearTimeout(this.clocktoken)
        this.clocktoken=null
      }
      if (this.game){
        if (this.game.mycolor=='w'){
          this.game.mytime=msg.wtime
          this.game.opptime=msg.btime
        } else {
          this.game.mytime=msg.btime
          this.game.opptime=msg.wtime
        }
        this.dectime()
      } else {
        if (this.watching){
          this.watching.wtime=msg.wtime
          this.watching.btime=msg.btime
          this.watching.time2count=msg.turn
          this.dectime()
        }
      }
    },
    msg_gameresult(msg){
      if ((!this.game)&&(!this.watching)){
        console.log('game is null but gameresult='+JSON.stringify(msg))
      } else {
        if (this.game){
          this.gameover(msg)
        } else {
          this.watchover(msg)
        }
      }
    },
    msg_offerdraw(msg){
      if ((!this.game)||(this.game.gid != msg.gameid) ){
        console.log('game & offrer mismatch offerdraw='+JSON.stringify(msg))
      } else {
        this.game.offerdraw=1
      }
    },
    msg_reqmoveback(msg){
      if ((!this.game)||(this.game.gid != msg.gameid) ){
        console.log('game & reqback mismatch reqmoveback='+JSON.stringify(msg))
      } else {
        this.game.requestmoveback=1
      }
    },
    msg_chat(msg){
      if (!this.chat){
        this.chat=[]
      }
      let login=this.getuser(msg.user).login
      this.chat.unshift({
//      this.chat.push({
        login:login,
        txt:msg.txt
      })
    },
    msg_watchers(msg){
      if (this.game){
        this.game.watchers=[]
        msg.data.forEach(a=>{
          this.game.watchers.push(this.getuser(a))
        })
      } else {
        if (this.watching){
          this.watching.watchers=[]
          msg.data.forEach(a=>{
            this.watching.watchers.push(this.getuser(a))
          })
        } else {
          this.strwatchers=JSON.stringify(msg)
        }
      }
    },
    msg_discussion(msg){
      if (msg.out_error_code){
        console.log(JSON.stringify(msg))
      } else {
        this.Boardstore.msg_discussion(msg)
      }
    },
    msg_discussresult(msg){
      this.Boardstore.discussresult(msg.out_id)
    },
    msg_arctourlist(msg){
      msg.data.forEach(a => this.archtour_data.push(a))
      if (msg.data.length){
        this.archtour_lastid=msg.data[msg.data.length-1].tourid
      } else {
        this.archtour_lastid=0
      }
    },
    msg_recent(msg){
      this.recent=[]
      msg.data.forEach(a => {
        //this.update4show(a,'rec')
        this.recent.push(a)
      })
    },
    msg_tournaments(msg){
      for (let i=0; i<this.tournaments.length; i++){
        for (let j=0;j<msg.data.length;j++){
          if (msg.data[j].tourid == this.tournaments[i].tourid){
            msg.data[j].show_participants=this.tournaments[i].show_participants
            break
          }
        }
      }
      this.tournaments=[];
      msg.data.forEach(a => {
        this.tournaments.push(a)
        if (a.tourid == this.currenttournament){
          this.tourtable=a
        }
        if (a.status == 'p'){
          for (let i=0;i<a.tours.length;i++){
            if (a.tours[i].login == this.mydata.login){
              for (let j=0; j<a.tours[i].games.length; j++){
                if ( a.tours[i].games[j] && 
                   (!a.tours[i].games[j].gameid)&&
                   (a.tours[i].games[j].startday) &&
                   (! a.tours[i].games[j].inprogress) 
                ){
                  this.initalarm(a.tours[i].games[j].startday);
                  break;
                }
              }
              break;
            }
          }
        }
      })
    },
    msg_descrtournament(msg){
      this.spinning=false
      this.tourtable=msg.data
      setURL('tournament='+msg.data.tourid)
    },
    msg_userlist(msg){
      let old_users=JSON.parse(JSON.stringify(this.users));
      this.users=[];
      for (let i=0;i<msg.data.length;i++){
        let a = msg.data[i]
        this.users.push(a)
        for (let j=0;j<old_users.length; j++){
          if (old_users[j].userid == a.userid){
            if (old_users[j].rating < a.rating){
              this.setarrow(i,'greenarrow.png',this.users)
            } else {
              if (old_users[j].rating > a.rating) { 
                this.setarrow(i,'redarrow.png',this.users)
              }
            } 
            break;
          }
        }
        if (a.userid == this.mydata.id) {
          if (this.mydata.rating!=a.rating){
            if (this.mydata.rating < a.rating){
              this.mydata.arrow='greenarrow.png'
            } else {
              this.mydata.arrow='redarrow.png'
            } 
            setTimeout(this.cleararrowmydata,3000)
            this.mydata.rating=a.rating
          }
          this.mydata.profsystem=a.profsystem
          this.mydata.privl=a.privl
        }
      }
      //update all_games
      this.allgames.forEach(a => {
        if (a.virtual){
          a.wuser=this.getuser(a.wplayer)
          a.buser=this.getuser(a.bplayer)
          let va=(a.wuser&&a.wuser.userid)?0:1
          let vb=(a.buser&&a.buser.userid)?0:1
          a.virtual=va+vb
          if (a.rating){
            this.setwatchingdetailes()
          }
        }
      })
    },
    getuser(uid){
      for (let i=0; i<this.users.length; i++){
        if (this.users[i].userid == uid){
          return this.users[i]
        }
      }
      //search in tournaments
      for (let i=0;i<this.tournaments.length;i++){
        if (this.tournaments[i].status=='p'){
          for (let j=0;j<this.tournaments[i].participants.length;j++){
            if (this.tournaments[i].participants[j].id==uid){
              return {
                id:uid,
                login:this.tournaments[i].participants[j].login,
                rating:this.tournaments[i].participants[j].rating,
                absent:true
              }
            }
          }
        }
      }
      return{
        login:'-',
        rating:"-"
      }
    },
    msg_allgames(msg){
      this.allgames=[];
      msg.data.forEach(a => {
        a.wuser=this.getuser(a.wplayer)
        a.buser=this.getuser(a.bplayer)
        this.allgames.push(a)
        if (a.wuser.userid && a.buser.userid){
          a.virtual=0
        }
      })
    },
    msg_commoninvitations(msg){
      this.commoninvitations=[];
      msg.data.forEach(a => {
        this.setprize(a)
        this.commoninvitations.push(a)
      })
    },
    msg_personalinvitations(msg){
      this.currentinvitations=[]
      msg.data.forEach(a => {
        this.setprize(a)
        a.size=64
        a.orient=this.Boardstore.antiturn(a.mycolor)
        a.showkilled=false
        a.showitin=0
        a.p1=a.pos1
        this.currentinvitations.push(a)
      })
      if (msg.data.length &&(!this.game)){
        this.showInvList()
      }
      if ((msg.data.length==0)&&(this.modalvisible=='Invitations')){
        this.hideModal()
      }
    },
    msg_description(msg){
      if (JSON.stringify(msg.data.p1) != JSON.stringify(this.analyzedata.pos1.p1)){
        this.analyzedata.pos1.p1=msg.data.p1
      }
      let bestnpp=null
      let bestval=10000
      msg.data.am.forEach((a,index)=>{
        a.p1=msg.data.p1
        a.turn=this.analyzedata.pos1.turn
        a.board=this.analyzedata.pos1.board
        a.showkilled=1
        a.showitin=a.itin.length
        a.showawail=0
        a.npp=index
        a.size=96
        a.orient=this.analyzedata.pos1.orient
        a.str=POSITION.move2str(a)
        if (bestval>a.roboteval){
          bestnpp=index
          bestval=a.roboteval
        }
      })
      if (bestnpp != null ){
        msg.data.am[bestnpp].best=1
      }
      this.analyzedata.description=msg.data
      this.spinneroff()
    },
    msg_refuseinvitation(msg){
      notify(t('INVITE_MODAL.TITLE'),this.getuser(msg.opponent).login+' '+t('REJECT_INVITATION.TEXT'))
    },
    msg_unilistres(msg){
      if (msg.data.rnd == 'mygames'){
        this.load_mygames(msg.data.aresult)
      } else {
        if (msg.data.rnd == 'library'){
          this.load_library(msg.data.aresult)
        } else {
          if (msg.data.rnd == 'favor'){
            this.load_favors(msg.data.aresult)
          }
        }
      }
    },
    msg_moveback(){
      if (this.game && this.game.mymovescnt){
        this.game.mymovescnt--
        this.game.backrequested=0
      }
    },
    msg_refusemoveback(){
      notify(t('GAME.MOVEBACKREFUSED'))
      this.game.backrequested=0
    },
    setprize(a){
      for (let i=0;i<this.users.length;i++){
        if (this.users[i].userid == a.sender){
          if (a.rating){
            let prize=calcprize(this.mydata.rating,this.users[i].rating)
            a.prize=prize.wwin+'/'+prize.wdraw+'/'+prize.wloose
          } else {
            a.prize='-'
          }
          a.login=this.users[i].login
          a.userrating=this.users[i].rating
          break
        }
      }
    },
    timing(time){
      if (time){
        let tm=TIME.timing2str(time)
        let s=''
        if (tm.hours) {
          s=tm.hours+' '+t('PAGES.TOURNAMENT_INFO.ROWS.TIME.HOUR')+' '
        }
        if (tm.min) {
          s=s+tm.min+' '+t('PAGES.TOURNAMENT_INFO.ROWS.TIME.MIN')+' '
        }
        return s+'/'+t(tm.whatfor)
      } else {
        return(' - ')
      }
    },
    likereference(str){
      let startwith=str.replace(/\s/g,'').substring(0,4)
      return (startwith =='http') || (startwith == 'www.') || (startwith == 'yout')
    },
    openreference(str){
        window.open(str,'_blank')
    },
    setwatchingdetailes(){
      if (this.watching && this.watching.game && this.watching.game.rating){
        let prize=calcprize(this.watching.game.wuser.rating,this.watching.game.buser.rating)
        if (prize.wwin){
          this.watching.deltaratw=prize.wwin+'/'+prize.wdraw+'/'+prize.wloose
          this.watching.deltaratb=prize.bwin+'/'+prize.bdraw+'/'+prize.bloose
          if (this.watching.game.timelimit){
            this.watching.game.timecondition=this.timing(this.watching.game.timelimit)
          }
        }
      }
    },
    msg_initpos(msg){
      for (let i=0;i<this.allgames.length;i++){
        if (this.allgames[i].gameid==msg.gameid){
          this.watching.game=this.allgames[i]
          break
        }
      }
      this.setwatchingdetailes()
      if (this.changeright('watching')){
        this.pagesection='WATCHING.DESCRIPTION'
      }
    },
    msg_roboschet(msg){
      //console.log(JSON.stringify())
      let ihave
      let ineed
      let informtxt
      if (msg.roboschet.length){
        for (let i=0;i<msg.roboschet.length;i++){
          if (msg.roboschet[i].robot == msg.robotid){
            ihave=msg.roboschet[i].schet
            ineed=msg.roboschet[i].game2privl
            break
          }
        }
      } else {
        ihave=0
      }
      if (!ineed){
        if (msg.robotid){
          ineed=this.getuser(msg.robotid).game2privl
        }
      }
      if (msg.humanwon) {
        if (ihave == 0){
          informtxt=t('GAME.CONGRAT1')+ineed+t('GAME.CONGRAT2')+msg.privl
        } else {
          informtxt=t('GAME.NOWSCHET')+ihave+t('GAME.YOUNEED')+ineed+t('GAME.INROW')
        }
      } else {
        informtxt=t('GAME.ZEROSCHET')+ineed+t('GAME.INROW')
      }
      notify(informtxt)
    },
    msg_puzzleoday(msg){
      //console.log('msg_puzzleoday')
      if (msg.data && msg.data.gamedata){
        this.myparams.PUZZLEREXP=msg.data.gamedata
        this.TOD2playlist()
        this.showPuzzle()
      } else {
        notify(t('COMMON.EERROR'),t('COMMON.ERROR'))
      }
    }
  },
})

export const notify=(msg,descr)=>{
  notification.open({
      message: msg,
      description:descr
    })
}

function messdisp(msg){
  //let now=new Date();
  //console.log("iM("+now.getHours()+"."+now.getMinutes()+":"+now.getSeconds()+":"+now.getMilliseconds()+")="+JSON.stringify(msg));
  if (msg.name === 'personalinvitations'){
    useMyDataStore().msg_personalinvitations(msg)
    return
  }
  if (msg.name === 'commoninvitations'){
    useMyDataStore().msg_commoninvitations(msg)
    return
  }
  if  (msg.name === "bye" ){
    document.location.reload()
    return
  }
  if (msg.name === 'yourdata'){
    let M=useMyDataStore()
    M.msg_yourdata(msg)
    M.spinning=false
    if (!M.firstmydata){
      M.firstmydata=1
      M.activeKey='2'
    }
    return
  }
  if (msg.name === "loginfailed"){
    notify(t('COMMON.ERROR'),t('AUTH_MODAL.AUTH.ERROR.1'))
    useMyDataStore().spinning=false
    return
  }
  if (msg.name === 'leaders'){
    //221202
    msg.data=msg.data.slice(0,10)
    useMyDataStore().msg_leaders(msg)
    return
  }
  if (msg.name == 'tournaments'){
    useMyDataStore().msg_tournaments(msg)
    return
  }
  if (msg.name == 'userlist'){
    let M=useMyDataStore()
    let a=JSON.stringify(M.mydata.profsystem)
    M.msg_userlist(msg)
    if (a!=JSON.stringify(M.mydata.profsystem)){
      M.mydata.profsystem.updated=1
    }
    return
  }
  if (msg.name == 'allgames'){
    useMyDataStore().msg_allgames(msg)
    return
  }
  if (msg.name == "arctourlist" ){
    useMyDataStore().msg_arctourlist(msg)
    return
  }
  if (msg.name == "descrtournament" ){
    useMyDataStore().msg_descrtournament(msg)
    return
  }
  if (msg.name == "refuseinvitation" ){
    useMyDataStore().myinv=null
    useMyDataStore().msg_refuseinvitation(msg)
    return
  }
  if (msg.name == "recentlist" ){
    useMyDataStore().msg_recent(msg)
    return
  }
  if (msg.name == "unilistres" ){
    useMyDataStore().msg_unilistres(msg)
    return
  }
  if (msg.name == "userbymask" ){
    useMyDataStore().msg_userbymask(msg)
    return
  }
  if (msg.name == "dbgame" ){
    useMyDataStore().msg_dbgame(msg)
    return
  }
  if (msg.name == "mycomment"){
    useMyDataStore().msg_mycomment(msg)
    return
  }
  if (msg.name == "discussion"){
    useMyDataStore().msg_discussion(msg)
    return
  }
  if (msg.name == "discussresult"){
    useMyDataStore().msg_discussresult(msg)
    return
  }
  if (msg.name == "savingres"){
    useBoardStore().msg_savingres(msg)
    return
  }
  if (msg.name == "description"){
    useMyDataStore().msg_description(msg)
    return
  }
  if (msg.name == "mygames"){
    useMyDataStore().myinv=null
    useMyDataStore().msg_mygames(msg)
    return
  }
  if (msg.name == "gameresult"){
    useMyDataStore().msg_gameresult(msg)
    useBoardStore().dropitin()
    return
  }
  if (msg.name == "offerdraw"){
    useMyDataStore().msg_offerdraw(msg)
    return
  }
  if (msg.name == "reqmoveback"){
    useMyDataStore().msg_reqmoveback(msg)
    return
  }
  if (msg.name == "chat"){
    let M=useMyDataStore()
      if (!M.chat){
        M.chat=[]
      }
      let login=M.getuser(msg.user).login
//      M.chat.unshift({
      M.chat.push({
        login:login,
        txt:msg.txt
      })
    return
  }
  if (msg.name == "watchers"){
    useMyDataStore().msg_watchers(msg)
    return
  }
  if (msg.name == "refuseagain"){
    useMyDataStore().msg_refuseagain(msg)
    return
  }
  if (msg.name == "timeremain"){
    useMyDataStore().msg_timeremain(msg)
    return
  }
  if (msg.name == "oppmove"){
    useBoardStore().msg_oppmove(msg)
    return
  }
  if (msg.name == "moveback"){
    useMyDataStore().msg_moveback()
    useBoardStore().msg_moveback(msg)
    return
  }
  if (msg.name == "refusemoveback"){
    useMyDataStore().msg_refusemoveback()
    return
  }
  if (msg.name == "roboschet"){
    useMyDataStore().msg_roboschet(msg)
    return
  }
  if (msg.name == "initpos"){
    let M=useMyDataStore()
    M.watching=msg
    M.msg_initpos(msg)
    if (M.strwatchers){
      M.msg_watchers(JSON.parse(M.strwatchers))
      M.strwatchers=null
    } 
    useBoardStore().msg_initpos(msg)
    return
  }
  if (msg.name == "puzzleofday"){
    useMyDataStore().msg_puzzleoday(msg)
    return
  }
}


export async function auth(uname, pass){
  closesocket()
  sockinit("wss://www.mycheckers.online:8082",uname,pass);
}

export async function logout(){
//  sendsmth({
//    name:"logout"
//  });
  document.location.reload()
}

export function setlang(locale){
  sendsmth({
    name:"saveprivateprofile",
    sname:"lang",
    svalue:locale2lang(locale)
  });
}

const refresh = () => {
  useMyDataStore().pseudoswitch =! useMyDataStore().pseudoswitch
}

setInterval(refresh,1000)

const sendping = () =>{
  let n=new Date()
  let M=useMyDataStore()
  if (M.mydata && M.mydata.id && ((n-M.lastactivity)<1800000)){
    SOCKETUTILS.ping()
  }
}

setInterval(sendping,45000)
