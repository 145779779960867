<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('INVITE_MODAL.TITLE')}}
    </template>
    <template v-slot:body>
      <a-form class="invitation-form" >
        <span> {{t('INVITE_MODAL.ENEMY_INFO.TITLE')}} 
          <span v-if="Mydatastore.user2invite" >
            {{Mydatastore.user2invite.login}} ({{Mydatastore.user2invite.rating}})
            <OneUser :profile="Mydatastore.user2invite" :imgsize="24"/>
          </span>
          <span v-if="Mydatastore.user2invite === null"> {{t('INVITE_MODAL.ENEMY_INFO.ANY')}}</span>
        </span>

        <br>
        <span>{{t('INVITE_MODAL.COLOR')}}:</span>
        <a-radio-group v-model:value="mycolor" name="radioGroup">
          <a-radio value="1">{{t('INVITE_MODAL.COLOR_SELECTOR.WHITE')}}</a-radio>
          <a-radio value="2">{{t('INVITE_MODAL.COLOR_SELECTOR.BLACK')}}</a-radio>
          <a-radio value="3">{{t('INVITE_MODAL.COLOR_SELECTOR.ANY')}}</a-radio>
        </a-radio-group>

        <br>
        <a-checkbox :disabled="ratingdisabled" v-model:checked="onrating" @change="calcontime">{{getratingchoice()}}</a-checkbox>
        <br>
        <span v-if="onrating && Mydatastore.user2invite">
          {{t('INVITE_MODAL.RATING_DESCRIPTION')}} {{getprize(Mydatastore.mydata.rating,Mydatastore.user2invite.rating)}}
        </span>
        <br>
        <a-checkbox v-model:checked="ontime" :disabled="ontimedisabled()">{{getontimechoice()}}</a-checkbox>
        <span :class="getvisibility()">
          <input  type="number" size=3 min="1" max="119" v-model="timevalue">
          <span v-if="timeselector == 'min'" class="asreference" @click="chtimeselector">
            {{t('INVITE_MODAL.TIME_SELECTOR.MINUTE')}}
          </span>
          <span v-if="timeselector == 'hour'" class="asreference" @click="chtimeselector">
            {{t('INVITE_MODAL.TIME_SELECTOR.HOUR')}}
          </span>
           / 
          <span v-if="timeon == 'move'" class="asreference" @click="chtimeon">
            {{t('INVITE_MODAL.TIME_SELECTOR.ON_STEP')}}
          </span>
          <span v-if="timeon == 'game'" class="asreference" @click="chtimeon">
            {{t('INVITE_MODAL.TIME_SELECTOR.ON_GAME')}}
          </span>
        </span>
        <br>
        <a-checkbox v-model:checked="startwith" :disabled="startwithdisabled()">{{t('INVITE_MODAL.START_WITH_CURRENT_POSITION')}}</a-checkbox>
        <br>
        <a-form-item>
          <a-button type="primary" @click="invite">
            {{t('INVITE_MODAL.OK_TEXT')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent,ref} from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import { useI18n } from 'vue-i18n'
  import OneUser from '@/components/OneUser.vue'
  import * as TIME from '@/sys/time.js'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import {calcprize} from '@/hooks/utils.js'
  
  export default defineComponent({
    components: {
      UniversalModal,
      OneUser
    },
    mounted(){
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      return {
        timeselector:'min',
        timeon:'game',
        timevalue:5
      }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
      const Boardstore = useBoardStore()

      const mycolor=ref('3')

      const onrating=ref(false)
      const ontime=ref(false)
      const ratingdisabled=ref(true)  
      const startwith=ref(false)

      return {
        t,
        Mydatastore,
        Boardstore,
        mycolor,
        onrating,
        ontime,
        ratingdisabled,
        startwith
      };
    },
    methods:{
      calcontime(){
        this.ontime = this.ontime || this.onrating
        this.startwith = this.startwith && (! this.onrating)
      },
      chtimeon(){
        this.timeon=(this.timeon == 'move')?'game':'move'
      },
      chtimeselector(){
        this.timeselector=(this.timeselector == 'min')?'hour':'min'
      },
      getprize(rat1,rat2){
        let prize=calcprize(rat1,rat2)
        return(prize.wwin+'/'+prize.wdraw+'/'+prize.wloose)
      },
      getontimechoice(){
        let messcode;
        if (this.Mydatastore.user2invite && this.Mydatastore.user2invite.robot ){
          messcode='INVITE_MODAL.ON_TIME_CHOICE.HE_IS_ROBOT'
          this.ontime=false
        } else {
          messcode='INVITE_MODAL.ON_TIME'
        }
        return this.t(messcode)

      },
      getratingchoice(){
        let messcode;
        if (this.Mydatastore.user2invite && (this.Mydatastore.user2invite.robot > 0)) {
          messcode='INVITE_MODAL.RATING_CHOICE.HE_IS_ROBOT'
          this.onrating=false
          this.ratingdisabled=true
        } else {
          if ( TIME.later(this.Mydatastore.mydata.privl) && 
               ((this.Mydatastore.user2invite == null) || (TIME.later(this.Mydatastore.user2invite.privl))) ){
            messcode='INVITE_MODAL.RATING_CHOICE.OK'
            this.ratingdisabled=false
          } else {
            messcode='INVITE_MODAL.RATING_CHOICE.NOT_PRIVL'
            this.onrating=false
            this.ratingdisabled=true
          }
        }
        return this.t(messcode)
      },
      getvisibility(){
        if (this.ontime){
          return 'visible'
        } else {
          return 'hidden'
        }
      },
      invite(){
        this.Mydatastore.hideModal()
        let cp=this.startwith?this.Boardstore.getcurrentposition():null
        let opponent=this.Mydatastore.user2invite?this.Mydatastore.user2invite.userid:null
        let tlimit=0
        if (this.ontime){
          if (this.timeselector == "min") {
            tlimit=this.timevalue*60;
          } else {
            tlimit=this.timevalue*3600;
          }
          if (this.timeon == "move"){
            tlimit=tlimit+1000000;
          } else {
            tlimit=tlimit+2000000;
          }
        }
        SOCKETUTILS.Invite(opponent,
               this.onrating?1:0,
               tlimit,
               (this.mycolor == '3')?null:(this.mycolor == '1')?'w':'b',
               cp?cp.p1:null,
               cp?cp.turn:null)
        this.Mydatastore.myinv=opponent?this.Mydatastore.getuser(opponent):{
          userid:0
        }
        this.Mydatastore.myinv.r=(Math.random()*256)|0,
        this.Mydatastore.myinv.g=(Math.random()*256)|0,
        this.Mydatastore.myinv.b=(Math.random()*256)|0
        this.chbgcolor()
      },
      chbgcolor(){
        if (this.Mydatastore.myinv){
          this.Mydatastore.myinv.r=(256+this.Mydatastore.myinv.r+((Math.random()*20-10)|0))%256
          this.Mydatastore.myinv.g=(256+this.Mydatastore.myinv.g+((Math.random()*20-10)|0))%256
          this.Mydatastore.myinv.b=(256+this.Mydatastore.myinv.b+((Math.random()*20-10)|0))%256
          if (this.Mydatastore.myinv){
            setTimeout(this.chbgcolor,100)
          }
        }
      },
      keyhandler(e){
        if (e.key == 'Enter') {
          this.invite()
        }
      },
      ontimedisabled(){
        return (this.Mydatastore.user2invite != null) && ((this.Mydatastore.user2invite.robot>0) || this.onrating)
      },
      startwithdisabled(){
        let a=(
               (this.Mydatastore.user2invite != null) && 
               (!TIME.later(this.Mydatastore.mydata.privl) && 
               (this.Mydatastore.user2invite.game2privl > 0))  
              ) || this.onrating 
        if (!a){
          let cp=this.Boardstore.getcurrentposition().p1
          let robot=this.Mydatastore.user2invite?this.Mydatastore.user2invite.robot:null
          if (robot){
            let r12=[0,0,0,0,0]
            for (let i=0;i<cp.length;i++){
              r12[cp[i]]++
            }
            a=(r12[1]>12)||(r12[2]>12)||(r12[3]>12)||(r12[4]>12)
          }
        }
        return a
      },
    }
  });

</script>
<style>
.invitation-form {
  max-width: 500px;
}
.visible{
  visibility: visible;
}

.hidden{
  visibility: hidden;
}
</style>

