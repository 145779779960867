<template>
  <img id="ProfileButt" src='@/assets/profile.png'> 
</template>

<script type="text/javascript">
  export default {
    name:"ProfileButt"
  }
</script>

<style type="text/css">
  #ProfileButt {
   height: 100%;
  }
  @media all and (max-width: 800px) {
    #ProfileButt {
      height: 30px;
    }
  }

</style>