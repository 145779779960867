<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('PERSONAL_INVITATIONS.TITLE')}}
    </template>
    <template v-slot:body>
      <a-form class="invitationslist">

        <table>
          <tr bgcolor="#CCCCCC">
            <td>
              {{t('INVITE_MODAL.ENEMY_INFO.TITLE')}}
            </td>
            <td>
              {{t('INVITE_MODAL.COLOR')}}
            </td>
            <td>
              {{t('INVITE_MODAL.RATING_DESCRIPTION')}}
            </td>
            <td>
              {{t('INVITE_MODAL.ON_TIME')}}
            </td>
            <td>
              {{t('INVITE_MODAL.START_WITH')}}
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr v-for="(invitation,index) in Mydatastore.currentinvitations" :key="invitation.sender" :bgcolor="bg[index % 2]">
            <td>
              {{invitation.login}} ({{invitation.userrating}})
              <OneUser :profile=Mydatastore.getuser(invitation.sender) :imgsize="16"/>
            </td>
            <td>
              <span v-if="invitation.mycolor == 'w'">{{t('INVITE_MODAL.COLOR_SELECTOR.BLACK')}}</span>
              <span v-if="invitation.mycolor == 'b'">{{t('INVITE_MODAL.COLOR_SELECTOR.WHITE')}}</span>
              <span v-if="invitation.mycolor == 0">{{t('INVITE_MODAL.COLOR_SELECTOR.ANY')}}</span>
            </td>
            <td>
              {{invitation.prize}}
            </td>
            <td>
              {{Mydatastore.timing(invitation.tlimit)}}
            </td>
            <td>
              <span v-if="invitation.pos1">
                <!--<img src="smallLogo.png" width="16" height="16">-->
                <CheckersBoard :board="invitation"/>
                <br>
                <span v-if="invitation.turn=='w'">{{t('TURN_ALERT.WHITE')}}</span>
                <span v-else>{{t('TURN_ALERT.BLACK')}}</span>
              </span>
            </td>
            <td>
              <span class="asreference" @click="accept(invitation.sender)">{{t('COMMON.ACCEPT')}} </span>
            </td>
            <td>
              <span class="asreference" @click="refuse(invitation.sender)">{{t('COMMON.REFUSE')}}</span>
            </td>
          </tr>
        </table>
        <br>
        <a-form-item>
          <a-button @click="dontinvite" type="primary">
            {{t('PAGES.PLAY.USERS.DONTINVITE')}}
          </a-button>
          <a-button @click="close">
            {{t('COMMON.CLOSE')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import OneUser from '@/components/OneUser'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import CheckersBoard from '@/components/CheckersBoard'
  import {pisk} from '@/store/BoardStore'

  export default defineComponent({
    components: {
      UniversalModal,
      OneUser,
      CheckersBoard
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },

    data(){
      return {
        bg:['#EEEEEE','#DDDDDD']
      }
    },

    setup() {
      pisk()

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
    
      return {
        t,
        Mydatastore
      };
    },
    methods:{
      accept(sender){
        SOCKETUTILS.accept(sender)
        this.Mydatastore.currentinvitations=[]
        this.Mydatastore.hideModal()
      },
      close(){
        this.Mydatastore.hideModal()
      },
      dontinvite(){
        this.Mydatastore.mydata.dontinvite=1
        SOCKETUTILS.dontinvite(1)
        this.Mydatastore.hideModal()
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.close()
        }
      },
      refuse(sender){
        SOCKETUTILS.refuse(sender)
        this.Mydatastore.hideModal()
      },
    }
  });

</script>
<style>
.invitationslist {
  max-width: 800px;
}
</style>
