<template>
  <div class="gameroom">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%; height: 100%; ">
      <span class="formheader">{{t('GAME.YOUROPP')}}: {{opponent.login}}({{opponent.rating}})
        <OneUser :profile="opponent" :imgsize="36"/>
      </span>
      <div class="bordered height90" style="width:98%; margin-bottom: 10px;">
        <table width="100%">
          <tr>
            <td width="30%" v-if="MydataStore.game && MydataStore.game.timelimit && MydataStore.game.mytime &&MydataStore.game.opptime" rowspan="3" align="left">
              <GameClock :time="MydataStore.game.opptime"/> {{opponent.login}}
              <br>
              <GameClock :time="MydataStore.game.mytime"/> {{MydataStore.mydata.login}}
            </td>
            <td>
              <div style="display:flex; justify-content: space-around;" >
                <button @click="surrender()" :disabled="!MydataStore.game">{{t('GAME.SURRENDER')}}</button>
                <button :disabled="!MydataStore.game" @click="offerdraw">{{t('GAME.OFFERDRAW')}}</button>
                <button :disabled="(!MydataStore.game)||(!MydataStore.game.mymovescnt)||(MydataStore.game.backrequested)" @click="moveback">{{t('GAME.REQUESTBACK')}}</button>
              </div>
            </td>
          </tr>
          <tr v-if="MydataStore.game && MydataStore.game.offerdraw" align="left" style="margin: 10 10 10 10">
            <td>
              <div style="margin: 10px;" class="bordered">
                <span :style="blink()">{{opponent.login}} {{t('GAME.MSGOFFER')}}</span>
                <button @click="acceptdraw()"><img src="thumbsup.png" width="24">{{t('GAME.ACCEPT')}}</button>
                <button @click="refusedraw()"><img src="thumbsdown.png" width="24">{{t('GAME.REFUSE')}}</button>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td>
              <div style="margin: 16px;" class="bordered">
                <br>
              </div>
            </td>
          </tr>
          <tr v-if="MydataStore.game && MydataStore.game.requestmoveback" align="left" style="margin: 10 10 10 10">
            <td>
              <div style="margin: 10px;" class="bordered">
                <span :style="blink()">{{opponent.login}} {{t('GAME.MSGBACK')}}</span>
                <button @click="allowmoveback()"><img src="thumbsup.png" width="24">{{t('GAME.ACCEPT')}}</button>
                <button @click="refusemoveback()"><img src="thumbsdown.png" width="24">{{t('GAME.REFUSE')}}</button>
              </div>
            </td>
          </tr>
        </table>
<!--        <table width="100%">
          <tr align="center">
            <td colspan="2">
              <h3><b>{{t('GAME.COMM')}}</b></h3>
            </td>
          </tr>
          <tr bgcolor="#FFFFE0" >
            <td align="left" style="border:1px solid gray;">
              <div style="overflow:auto; min-height: 200px; max-height:200px;"> 
                <span v-for="txt,index in MydataStore.chat" :key=index>
                  <b><small>{{txt.login}}:</small></b>
                  {{txt.txt}}
                  <br>
                </span>
              </div>
            </td>
            <td style="vertical-align: top; border:1px solid gray;">
              <div style="overflow:auto; max-height:300px; " 
                   v-if="(MydataStore.game && MydataStore.game.watchers && MydataStore.game.watchers.length)">
                <img src="eye.png" width="24">
                <br>
                <span v-for="watcher in MydataStore.game.watchers" :key="watcher" align=left>
                  {{watcher.login}}
                  <OneUser :profile="watcher" :imgsize="24"/>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <input v-model="msg2send" type="text" name="msg2opp" size="25" @keydown.enter="sendmsg" >
              <button @click="sendmsg()" :disabled="(msg2send.length==0)|| (!this.MydataStore.game)">{{t('COMMON.SEND')}}</button>
            </td>
            <td>
              <button v-if="(MydataStore.game && MydataStore.game.watchers && MydataStore.game.watchers.length)" @click="expell()" :disabled="MydataStore.game.rating">{{t('GAME.EXPELL')}}</button>
            </td>
          </tr>
        </table>-->
        <CommTable/>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore,notify} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import { defineComponent,computed } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import OneUser from '@/components/OneUser.vue'
  import GameClock from '@/components/GameClock.vue'
  import CommTable from '@/components/CommTable.vue'

  export default defineComponent({
    components: {
      OneUser,
      GameClock,
      CommTable
    },
    data(){
      return{
        fcolor:0,
        fcolors:['red','black'],
        timeouttoken:null,
        msg2send:''
      }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const BoardStore = useBoardStore()
      const MydataStore = useMyDataStore()
      const opponent = computed (() => {
        return MydataStore.game?JSON.parse(JSON.stringify(MydataStore.getuser(MydataStore.game.opponent))):
          (MydataStore.gamerecent?JSON.parse(JSON.stringify(MydataStore.getuser(MydataStore.gamerecent.opponent))):null)
      })

      return {
        t,
        BoardStore,
        MydataStore,
        opponent
      }
    },
    methods:{
      chcolor(){
        this.fcolor=(this.fcolor+1)%2
        this.timeouttoken=null
      },
      blink(){
        if (!this.timeouttoken){
          this.timeouttoken= setTimeout(this.chcolor,500)
        }
        return "color:"+this.fcolors[this.fcolor]
      },
      dialogstyle(){
        return "top:10%; position: absolute; height: 71%; width: "+this.dialogwidth()+"%;  overflow:auto; border: solid #808080 2px; border-radius: 0;"
      },

      dialogwidth(){
        return ((this.MydataStore.game && this.MydataStore.game.watchers && this.MydataStore.game.watchers.length)?'57':'96')
      },
      inputstyle(){
        return "top:81%; position: absolute; height: 15%; width: "+this.dialogwidth()+"%; display:flex; justify-content: flex-start;   padding: 5px;"+
                "margin-left:10px;margin-right: 10px;margin-top: 10px;max-width: 100%;"
      },
      expell(){
        SOCKETUTILS.expell()
        this.MydataStore.mydata.nowatch=1
      },
      surrender(){
        this.MydataStore.game.offerdraw=null
        SOCKETUTILS.surrender(this.MydataStore.game.gid)
      },
      acceptdraw(){
        this.MydataStore.game.offerdraw=null
        SOCKETUTILS.offerdraw(this.MydataStore.game.gid)
      },
      refusedraw(){
        this.MydataStore.game.offerdraw=null
        SOCKETUTILS.refusedraw(this.MydataStore.game.gid)
      },
      allowmoveback(){
        this.MydataStore.game.requestmoveback=null
        SOCKETUTILS.allowmoveback(this.MydataStore.game.gid)
      },
      refusemoveback(){
        this.MydataStore.game.requestmoveback=null
        SOCKETUTILS.refusemoveback(this.MydataStore.game.gid)
      },
      sendmsg(){
        if (this.msg2send.length && this.MydataStore.game){
          SOCKETUTILS.chat(this.msg2send)
          this.msg2send=''
        }
      },
      offerdraw(){
        if (this.MydataStore.game && (this.MydataStore.game.turn!=this.MydataStore.game.mycolor)){
          SOCKETUTILS.offerdraw(this.MydataStore.game.gid)
        } else {
          notify(this.t('GAME.DRAWISLATER'))
          this.MydataStore.game.drawlater=1
        }
      },
      moveback(){
        if (!this.MydataStore.game.backrequested){
          SOCKETUTILS.reqmoveback(this.MydataStore.game.gid)
          this.MydataStore.game.backrequested=1
        }
      },
    }
  })
</script>