"use strict"

import  useI18n  from '../i18n.js'

const { t } = useI18n.global

export function getrating(gamerating){
  let ratindex=((gamerating+0.249)*2)|0
  if (ratindex>10) {
    ratindex=10
  }
  return "star"+ratindex+".png"   
}

const colortxt=[  "WHITES", "BLACKS" ]
const reasontxt=[ "NOREASON", "SURRENDED", "LEFT", "EXCEEDED" ]
const watchgrrestxt= [ "WWON", "BWON" ]

export function grestxt(msg,mycolor){
  let resulttxt=t('GAME.RESULT.DRAW');
  let myresult
  if (msg.gres){
    if ((msg.gres == 1)&&(mycolor == "w")||(msg.gres == 2)&&(mycolor == "b")) {
      resulttxt=t('GAME.RESULT.YWON');
      myresult=1
    } else {
      resulttxt=t('GAME.RESULT.YLOST')
      myresult=0
    }
  }
  let reason="."
  let oppcolor
  if (myresult &&(msg.reason)){
    if (mycolor == "w") {
      oppcolor=1
    } else {
      oppcolor=0
    }
    reason=reason+" "+t('GAME.RESULT.'+colortxt[oppcolor])+" "+t('GAME.RESULT.'+reasontxt[msg.reason])+"."
  }
  return resulttxt+reason
}

export function watchgameresult(msg){
  if (msg.gres==0){
    return t('GAME.RESULT.DRAW')+"."
  }
  let reason="."
  if (msg.reason) {
    reason=reason+" "+t('GAME.RESULT.'+colortxt[2-msg.gres])+" "+t('GAME.RESULT.'+reasontxt[msg.reason])+"."
  }
  return t('GAME.RESULT.'+watchgrrestxt[msg.gres-1])+reason
}

export function calcprize(rat1,rat2){
  let result= ({
      wwin : 1,
      wloose : -1,
      wdraw :  0,
      bwin :   1,
      bloose : -1,
      bdraw :  0
    });
  let EA=1/(1+Math.pow(10,(rat2-rat1)/400));
  let KA;
  if (rat1>2400) {
    KA=10;
  } else {
    if (rat1>1000) {
      KA=20;
    } else {
      KA=40;
    }
  }
  result.wwin = Math.round(KA*(1-EA));
  result.wloose = Math.round(KA*(-EA));
  result.wdraw= Math.round(KA*(0.5-EA));
  EA=1/(1+Math.pow(10,(rat1-rat2)/400));
  if (rat2>2400) {
    KA=10;
  } else {
    if (rat2>1000) {
      KA=20;
    } else {
      KA=40;
    }
  }
  result.bwin = Math.round(KA*(1-EA));
  result.bloose = Math.round(KA*(-EA));
  result.bdraw= Math.round(KA*(0.5-EA));
  return result;
}
