<template>
  <div class="gameroom">
    <div class="bordered lightblue">
<!--      <span v-if="(Mydatastore && (Mydatastore.rightsubregime == 'TOD') )" 
                   class="formheader">{{t("PAGES.PLAY.TASK_OF_DAY.TITLE")}}</span>
      <span v-else class="formheader">{{BoardStore.playlist.gamename}}</span>-->
      <span class="formheader">{{BoardStore.playlist.gamename}}</span>
      <br>
      <span class="formdescription">{{t("PAGES.PLAY.TASK_OF_DAY.OWNER")}} {{BoardStore.playlist.ownerlogin}}</span>
      <!--<span v-if="(Mydatastore && (Mydatastore.rightsubregime == 'TOD') )" class="formdescription"><br>{{BoardStore.playlist.gamename}}</span>-->
      <br>
      <span class="formdescription">{{BoardStore.playlist.descr}}</span>
      <br>
      <span v-if="asreference()" class="formdescription asreference" @click="newwin">{{BoardStore.playlist.www}}</span>
      <span v-else class="formdescription">{{BoardStore.playlist.www}}</span>
      <br>
      <span v-if="Mydatastore && Mydatastore.mydata" class="asreference" @click="showcartoon()">{{t("PUZZLE.SHOWSOLUTION")}}</span>
      <br>
      <button v-if="Mydatastore && Mydatastore.mydata" @click="BoardStore.selectslave(-1)">{{t("PAGES.LEARN.TASK_OF_DAY.START_AGAIN")}}</button>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
      const BoardStore = useBoardStore()

      return {
        t,
        Mydatastore,
        BoardStore
      }
    },
    methods:{
      asreference(){
        if (this.Mydatastore && this.Mydatastore.currentplaylist){
          return this.Mydatastore.likereference(this.Mydatastore.currentplaylist.www)
        } else {
          return false
        }
      },
      newwin(){
        this.Mydatastore.openreference(this.Mydatastore.currentplaylist.www)
      },
      showcartoon(){
        this.BoardStore.selectslave(-1)
        this.Mydatastore.showCartoon()
      }
    }
  })
</script>