<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('AUTH_MODAL.TITLE.REGISTRATION')}}
    </template>
    <template v-slot:body>
      <a-form
        v-if="Mydatastore.modalvisible === 'Register' "
        :model="formState"
        name="normal_register"
        class="register-form"
      >
        <a-form-item
          v-bind:label="t('AUTH_MODAL.COMMON.USERNAME.LABEL')"
          name="username"
          :rules="[{ required: true, message: t('AUTH_MODAL.COMMON.USERNAME.REQUIRED') }]"
        >
          <a-input v-model:value="formState.username" :maxlength=20 v-on:keydown.enter="recaptcha">
            <template #prefix>
              <UserOutlined class="site-form-item-icon" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item
          v-bind:label="t('AUTH_MODAL.COMMON.EMAIL.LABEL')"
          name="email"
          :rules="[{ required: true, message: t('AUTH_MODAL.COMMON.EMAIL.REQUIRED') }]"
        >
          <a-input v-model:value="formState.email" :maxlength=90 v-on:keydown.enter="recaptcha">
            <template #prefix>
              <MailOutlined class="site-form-item-icon" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item
          v-bind:label="t('AUTH_MODAL.COMMON.PASSWORD.LABEL')"
          name="password"
          :rules="[{ required: true, message: t('AUTH_MODAL.COMMON.PASSWORD.REQUIRED') }]"
        >
          <a-input-password v-model:value="formState.password" :maxlength=100 v-on:keydown.enter="recaptcha">
            <template #prefix>
              <LockOutlined class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>

        <a-form-item
          v-bind:label="t('AUTH_MODAL.COMMON.CONFIRM_PASSWORD.LABEL')"
          name="password"
          :rules="[{ required: true, message: t('AUTH_MODAL.COMMON.CONFIRM_PASSWORD.REQUIRED') }]"
        >
          <a-input-password v-model:value="formState.confirmpassword" :maxlength=100 v-on:keydown.enter="recaptcha">
            <template #prefix>
              <LockOutlined class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>

        <a-form-item>
          <a-button :disabled="disabled" type="primary" @click="recaptcha" class="register-form-button">
            {{t('AUTH_MODAL.REGISTRATION.REGISTER')}}
          </a-button>
        </a-form-item>


      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>

</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent,reactive,computed } from 'vue';
  import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons-vue';
  import {useMyDataStore, getlangnum, notify} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import {useReCaptcha}  from "vue-recaptcha-v3";
  import axios from 'axios'
  import * as SYSCONST from '@/sys/sysconst.js'

  export default defineComponent({
    components: {
      UserOutlined,
      LockOutlined,
      MailOutlined,
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    setup() {
      const formState = reactive({
        username: '',
        password: '',
        confirmpassword:'',
        email:'',
      });
      
      const disabled = computed(() => {
        return !(formState.username && formState.password && (formState.password == formState.confirmpassword));
      });

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
    
      const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
      const recaptcha = async () => {
        await recaptchaLoaded();
        const token = await executeRecaptcha("register");
        axios.post(SYSCONST.URL_newuser, {
          newaccount:formState.username,
          newpass1:formState.password,
          newemailaddr:formState.email,
          langdivreg:getlangnum(),
          token:token 
        }).then((res) => {
          const errCode = res.data?.out_error_code
          const registermsg={
            title: t('AUTH_MODAL.TITLE.REGISTRATION'),
            txt: t('AUTH_MODAL.REGISTRATION.SUCCESS')
          }
          if (errCode){
            registermsg.title=t('COMMON.ERROR')
            registermsg.txt=t('AUTH_MODAL.REGISTRATION.ERROR.'+errCode)
          } else {
            Mydatastore.hideModal()
          }
          notify(registermsg.title,registermsg.txt);
        })
      };

      return {
        formState,
        disabled,
        t,
        Mydatastore,
        recaptcha
      };
    },
    methods:{
      async register(token){
        console.log("reg "+token)
      },     
      keyhandler(e){
        if (e.key == 'Escape') {
          this.Mydatastore.modalvisible='none'
        }
      },
    }
  });

</script>
<style>
.register-form {
  max-width: 300px;
}
.register-form-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.register-form-button {
  width: 100%;
  margin-top: 16;
}
</style>
