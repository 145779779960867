<template>
  <div style="max-height:400px; max-width: 1200px; overflow: auto;">
  <h2>
    <span class="asreference" @click="changechapter(0)">
      {{t('ABOUT.WHY.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[0]">
    <p>
      {{t('ABOUT.WHY.P1')}}
    </p>
    <p>
      {{t('ABOUT.WHY.P2')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(1)"> 
      {{t('ABOUT.DIFFERENCE.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[1]">
    <p>
      {{t('ABOUT.DIFFERENCE.P1')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(2)"> 
      {{t('ABOUT.RULES.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[2]">
    <p>
      {{t('ABOUT.RULES.P1')}}
    </p>
    <table width="100%">
      <tr>
        <td>
          <CheckersBoard :board="About.diagr1"/>
        </td>
        <td>
          <p>
            {{t('ABOUT.RULES.P2')}}
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <CheckersBoard :board="About.diagr2"/>
        </td>
        <td>
          <p>
            {{t('ABOUT.RULES.P3')}}
            <span class="asreference" @click="showturkey" v-if="MydataStore && MydataStore.mydata">
              {{t('ABOUT.RULES.EXAMPLE')}}
            </span>
            <span v-else>{{t('ABOUT.RULES.NOLOGIN')}}</span>
          </p>
        </td>
      </tr>
    </table>
    <p>
      {{t('ABOUT.RULES.P4')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(3)"> 
      {{t('ABOUT.ROBOTS.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[3]">
    <p>
      {{t('ABOUT.ROBOTS.P1')}}
    </p>
    <p>
      {{t('ABOUT.ROBOTS.P2')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(4)"> 
      {{t('ABOUT.RATINGS.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[4]">
    <p>
      {{t('ABOUT.RATINGS.P1')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(5)"> 
      {{t('ABOUT.VIP.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[5]">
    <p>
      {{t('ABOUT.VIP.P1')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(6)"> 
      {{t('ABOUT.TOURNAMENT.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[6]">
    <p>
      {{t('ABOUT.TOURNAMENT.P1')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(7)"> 
      {{t('ABOUT.DB.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[7]">
    <p><b>{{t('ABOUT.DB.SUB1')}}</b></p>
    <p>
      {{t('ABOUT.DB.P1')}}
    </p>
    <p><b>{{t('ABOUT.DB.SUB2')}}</b></p>
    <p>
      {{t('ABOUT.DB.P2')}}
    </p>
    <p><b>{{t('ABOUT.DB.SUB3')}}</b></p>
    <p>
      {{t('ABOUT.DB.P3')}}<img src="brain.jpg" width="24" height="24">{{t('ABOUT.DB.P31')}}
    </p>
    <p><b>{{t('ABOUT.DB.SUB4')}}</b></p>
    <p>
      {{t('ABOUT.DB.P4')}}
    </p>
    <p><b>{{t('ABOUT.DB.SUB5')}}</b></p>
    <p>
      {{t('ABOUT.DB.P5')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(8)"> 
      {{t('ABOUT.ANALYZE.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[8]">
    <p><b>{{t('ABOUT.ANALYZE.SUB1')}}</b></p>
    <p>
      {{t('ABOUT.ANALYZE.P1')}}
    </p>
    <p><b>{{t('ABOUT.ANALYZE.SUB2')}}</b></p>
    <p>
      {{t('ABOUT.ANALYZE.P2')}}
    </p>
  </span>

  <h2>
    <span class="asreference" @click="changechapter(9)"> 
      {{t('ABOUT.FEEDBACK.TITLE')}}     
    </span>
  </h2>
  <span v-if="About.chaptervisible[9]">
    <p>
      {{t('ABOUT.FEEDBACK.P1')}}
      <span class="asreference" @click="MydataStore.openreference('https://t.me/MyCheckers')">https://t.me/MyCheckers</span>
    </p>
    <p>
      {{t('ABOUT.FEEDBACK.P2')}}
    </p>
  </span>
</div>
</template>

<script type="text/javascript">
  import { defineComponent } from 'vue'
  import { useI18n } from 'vue-i18n'
  import {useMyDataStore} from '@/store/MyData.js'
  import {useAboutStore} from '@/store/AboutStore.js'
  import CheckersBoard from '@/components/CheckersBoard'

  export default defineComponent({
    name:'AboutData',
    components:{
      CheckersBoard
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      const MydataStore = useMyDataStore()
      const About = useAboutStore()
  
      return {
        t,
        About,
        MydataStore,
      }
    },
    methods:{
      changechapter(i){
        this.About.chaptervisible[i]=!this.About.chaptervisible[i]
      },
      showturkey(){
        this.MydataStore.modalvisible='none'
        this.MydataStore.loadgame(1443130,'library')
      }
    }
  })
</script>
