"use strict"
import {sendsmth} from '@/store/MyData.js'

const pagesize=15


export function ping(){
  sendsmth({
    name:"ping"
  })
}

export function getpuzzle(){
  sendsmth({
    name:"getpuzzle"
  })
}

export function stopwatching(){
  sendsmth({
    name:"stopwatching"
  })
}

export function watchgame(gameid){
  sendsmth({
    name:"watchgame",
    gameid:gameid
  })
}

export function selfplay(ipos,turn){
  sendsmth({
    name:"selfplay",
    ipos:ipos,
    how2show:turn
  })
}

export function getinvitations(){
  sendsmth({
    name:"refreshpersonalinv"
  })
}

export function mymove(mygameid,move){
  sendsmth({
    name:"mymove",
    gameid:mygameid,
    move:move
  })
}

export function expell(){
  sendsmth({
    "name":"dontwatch",
    "prohibition":1
  })
}

export function chat(chattxt){
  sendsmth({
    name:"chat",
    txt:chattxt
  })
}
export function reqmoveback(mygameid){
  sendsmth({
    name:"reqmoveback",
    gameid:mygameid
  })
}

export function refusemoveback(mygameid){
  sendsmth({
    name:"refusemoveback",
    gameid:mygameid
  })
}

export function allowmoveback(mygameid){
  sendsmth({
    name:"allowmoveback",
    gameid:mygameid
  })
}

export function offerdraw(mygameid){
  sendsmth({
    name:"offerdraw",
    gameid:mygameid
  })
}

export function refusedraw(mygameid){
  sendsmth({
    name:"refusedraw",
    gameid:mygameid
  })
}

export function surrender(mygameid){
  sendsmth({
    name:"surrender",
    gameid:mygameid
  })
}

export function evaluate(p1,robotid,dbflag,minrating) {
  sendsmth({
    name:"describe",
    p1:p1,
    parameters:{
      robot:robotid,
      dbflag:dbflag?1:0,
      libflag:null,
      minrating:minrating,
      searchkeywords:null,
      searchshowtypes:null
    }
  })
}

export function discussgame(gameid,parent,txt){
  sendsmth({
    name:"discussgame",
    gameid:gameid,
    replayof:parent,
    txt:txt
  })
}

export function commentgame(gameid,val){
  sendsmth({
    name:"commentgame",
    gameid:gameid,
    irating:val,
    txt:null
  })
}

export function getcommentgame(gid){
  sendsmth({
    name:"getcommentgame",
    gameid:gid
  })
}

export function requestdiscussion(gameid,lastdiscussid){
  sendsmth({
    name:"getgamediscussion",
    gameid:gameid,
    lastid:lastdiscussid,
    cnt:15
  })
}

export function finduser(rnd,sample){
  sendsmth({
    name:"finduser",
    rnd:rnd,
    sample:sample
  })
}

export function requestrecent(){
  sendsmth({
    name:"getrecentlist"
  })
}

export function requestmygames(startwith){
  sendsmth({
    name:"getlist",
    rnd:"mygames",
    p1:null,
    p2:null,
    amysaved:1, 
    apublished:null,
    aplayer:null, 
    arating:null, 
    aminimalrating:null, 
    akeywords :null,
    ashowtypes:null,
    aminimalid:startwith, 
    acnt:pagesize,
    aowner:null,
    afavor:0
  })
}

export function tofavor(gid,flag){
  let name=flag?'addfavor':'removefavor'
  sendsmth({
    name:name,
    gameid:gid
  })
}

export function deletegame(gameid){
  sendsmth({
    name:"deletegame",
    gameid:gameid
  })
}

export function sendpublicorder(id,newstatus){
  let msg={
    name:"publicgame",
    gameid:id,
    publ:newstatus
  }
  sendsmth(msg)
}

export function clearplaylist(id){
  sendsmth({
    name:"clearplaylist",
    master:id
  })
}

export function playlist(tittle,slaves){
  sendsmth({
    name:"playlist",
    master:tittle,
    slaves:slaves
  })
}

export function addslave(title,slave){
  sendsmth({
    name:"addslave",
    master:title,
    slave:slave
  })
}

export function sendchatorder(id,newstatus){
  let msg={
    name:"enablecomments",
    gameid:id,
    ecomm:newstatus
  };
  sendsmth(msg)
}

export function getrecentgame(gameid){
  sendsmth({
    name:"loadrecent",
    gameid:gameid
  })
}

export function loadgame(gameid,rnd){
  sendsmth({
    name:"loaddbgame",
    gameid:gameid,
    rnd:rnd
  })
}

export function accept(sender){
  sendsmth({
    name:"acceptinvitation",
    opponent:sender
  })
}

export function dontinvite(dontinvite){
 sendsmth({
    "name":"dontinvite",
    "prohibition":dontinvite
  });
}

export function refuse(sender){
  sendsmth({
    name:"refuseinvitation",
    opponent:sender
  })
}

export function cancelinvitation(){
  sendsmth({"name":"cancelinvitation"})
}

export function Invite(opponent,rating,tlimit,mycolor,pos1,turn){
  let mess={
    name:"invitation",
    opponent:opponent,
    rating:rating,
    tlimit:tlimit,
    mycolor:mycolor,
    pos1:pos1,
    turn:turn
  }
  sendsmth(mess)
}


export function saveprivateprofile(sname,svalue){
  sendsmth({
    "name":"saveprivateprofile",
    "sname":sname,
    "svalue":svalue
  });
}

export function setSettings(country, dontinvite, dontwatch, autostep){
  sendsmth({
    "name":"savepublicprofile",
    "sname":"country",
    "svalue":country
  });
  sendsmth({
    "name":"dontinvite",
    "prohibition":dontinvite
  });
  sendsmth({
    "name":"dontwatch",
    "prohibition":dontwatch
  })
  saveprivateprofile("enabledAutoSingleStep",autostep)
}

export function enroll(tourid,flag){
  sendsmth({
    name:"enroll",
    tourid:tourid,
    inout:flag
  });
}

export function reqtourn(tourid){
  sendsmth({
    name:"showtournament",
    tourid:tourid
  })
}

export function nextarchpage(lastid){
  sendsmth({
    name:"getarchtours",
    maxid:lastid,
    cnt:pagesize
  })
}

