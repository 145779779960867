<template>
  <div id="rightpanel">
    <PosComment  v-if="BoardStore && (BoardStore.modalvisible=='message')"/>
    <MoveChoice v-if="BoardStore && (BoardStore.modalvisible=='movechoice')"/>
    <SolutionForm v-if="(Mydatastore && (Mydatastore.rightpanel == 'solution') && Mydatastore.currentplaylist)" />
    <TourTimetable v-if="(Mydatastore && (Mydatastore.rightpanel == 'tourtimetable') )" />
    <GameRoom v-if="(Mydatastore && (Mydatastore.rightpanel == 'gameroom') )" />
    <ArchTour v-if="(Mydatastore && (Mydatastore.rightpanel == 'archtour') )" />
    <TourTable v-if="(Mydatastore && (Mydatastore.rightpanel == 'tourtable') )" />
    <RecentList v-if="(Mydatastore && (Mydatastore.rightpanel == 'recent') )" />
    <MyGames v-if="(Mydatastore && (Mydatastore.rightpanel == 'mygames') )" />
    <LibraryTable v-if="(Mydatastore && (Mydatastore.rightpanel == 'library') )" />
    <SetPosition v-if="(Mydatastore && (Mydatastore.rightpanel == 'setposition') )" />
    <CartoonViewer v-if="(Mydatastore && (Mydatastore.rightpanel == 'cartoon') )" />
    <GameDiscussion v-if="(Mydatastore && (Mydatastore.rightpanel == 'discussion') )" />
    <ProtocoL v-if="(Mydatastore && (Mydatastore.rightpanel == 'protocol') )" />
    <AnalyzTable v-if="(Mydatastore && (Mydatastore.rightpanel == 'analyze') )" />
    <PlayGround v-if="(Mydatastore && (Mydatastore.rightpanel == 'game') )" /> 
    <WatchingGame v-if="(Mydatastore && (Mydatastore.rightpanel == 'watching') )" />
    <AboutPage v-if="(Mydatastore && (Mydatastore.rightpanel == 'about') )" />
  </div>	
</template>

<script type="text/javascript">
  import SolutionForm from './SolutionForm.vue'
  import TourTimetable from './TourTimetable' 
  import GameRoom from './GameRoom'
  import ArchTour from './ArchTour'
  import TourTable from './TourTable'
  import RecentList from './RecentList'
  import MyGames from './MyGames'
  import LibraryTable from './LibraryTable'
  import SetPosition from './SetPosition'
  import CartoonViewer from './CartoonViewer'
  import GameDiscussion from './GameDiscussion'
  import PosComment from '@/modal/PosComment'
  import MoveChoice from '@/modal/MoveChoice'
  import ProtocoL from './ProtocoL'
  import AnalyzTable from './AnalyzTable'
  import PlayGround from './PlayGround'
  import WatchingGame from './WatchingGame'
  import AboutPage from './AboutPage'
  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'

  export default {

    setup() {
      const Mydatastore = useMyDataStore()
      const BoardStore = useBoardStore()
      return {
        Mydatastore,
        BoardStore
      }
    },
    mounted(){
      this.setsizes()
      window.addEventListener('resize', this.setsizes);
    },
    components:{
      SolutionForm,
      TourTimetable,
      GameRoom,
      ArchTour,
      TourTable,
      RecentList,
      MyGames,
      LibraryTable,
      SetPosition,
      CartoonViewer,
      PosComment,
      MoveChoice,
      GameDiscussion,
      ProtocoL,
      AnalyzTable,
      PlayGround,
      WatchingGame,
      AboutPage
    },
    methods:{
      setsizes(){
        if (document.getElementById('rightpanel').offsetTop>500){
          document.getElementById('rightpanel').style.left='0px'
        } else {
          let a=document.getElementById('DivBoard').getBoundingClientRect().right|0
          document.getElementById('rightpanel').style.left=(a+12)+'px'
        }
      },
    },
  }

</script>

<style type="text/css">
  #rightpanel{
  position: absolute; 
  left:800px;
  right:0;
  top:0px;
  bottom:0;
  height: 100%;
  margin-top: 10px;
  max-height: 548px;
  padding-right: 12px;
}

@media all and (max-width: 1060px) {
  #rightpanel {
    left: 600px;
    height: 600px;
  }
}

@media all and (max-width: 860px) {
  #rightpanel {
    left: 0px;
    height: 100vw;
    width: 100%;
  }
}

</style>
