<template> 
  <div v-if="(Mydatastore && Mydatastore.tourtable)" class="gameroom">
    <div class="bordered lightblue" style="max-height: 100%; overflow: auto;">
      <span class="formheader">{{t("PAGES.TOUR_TABLE.FORMHEADER")}}</span>
      <br>
      <div class='bordered'>
        <h2>{{tourname(Mydatastore.tourtable.tourcycle)}}</h2>
        {{t('TOUR_SYSTEM.'+Mydatastore.tourtable.toursystem)}}
        <br>
        {{t('PAGES.TOURNAMENT_INFO.ROWS.START')}} : {{Mydatastore.tourtable.start}}
        <br>
        {{t('PAGES.TOURNAMENT_INFO.ROWS.TIME.TIMING')}} : {{timing(Mydatastore.tourtable.timing)}} 
        <table v-if="Mydatastore.tourtable.toursystem == 'r'" border="1">
          <tr>
            <td>{{t('PAGES.TOURNAMENT_INFO.TABLE.COLUMNS.PLAYER')}}</td>
            <td v-if="Mydatastore.tourtable.toursystem == 'r'" :colspan="Mydatastore.tourtable.tours.length">-</td>
            <td v-if="Mydatastore.tourtable.toursystem == 's'" :colspan="Mydatastore.tourtable.tours[0].games.length">+</td>
            <td>{{t('PAGES.TOURNAMENT_INFO.TABLE.COLUMNS.POINTS')}}</td>
            <td v-if="Mydatastore.tourtable.toursystem == 's'">{{t('PAGES.TOURNAMENT_INFO.TABLE.COLUMNS.RATING')}}</td>
            <td>{{t('PAGES.TOURNAMENT_INFO.TABLE.COLUMNS.PLACE')}}</td>
          </tr>
          <tr v-for="participant in Mydatastore.tourtable.tours" :key="participant.id">
            <td>
              {{participant.login}}
            </td>
            <td v-for="game in participant.games" :key=game :class="getclasscell(game)">
              <span v-if="game">
                <span v-if="game.gameid" @click="loadgame(game)">
                  {{getace(game.ace)}}
                  <a-popover v-if="game.virtual">
                    <template #content>
                      {{t("PAGES.TOURNAMENT_INFO.TABLE.GAME_NOT_PLAYED")}}
                    </template>
                    <img src="absent.png" :height="16" :width="16" >
                  </a-popover>
                </span>
                <span v-if="!game.gameid">
                  <a-popover v-if="game.inprogress">
                    <template #content>
                      {{t("PAGES.PLAY.GAMES.COLUMNS.STATUS.CAN_BE_WATCHED")}}
                    </template>
                    <img src="gameStatus/eye.jpg" :height="16" :width="16" @click="watchgame(game.inprogress)">
                  </a-popover>
                  <a-popover v-if="game.startday && (!game.inprogress)">
                    <template #content>
                      {{t("PAGES.PLAY.GAMES.COLUMNS.STATUS.GAME_START_SOON")}}
                    </template>
                    <span v-if="Mydatastore.pseudoswitch"></span>
                    <img src="clock.png" :height="16" :width="16" >
                    <span :class="soon(game.startday)">{{remain(game.startday)}}</span>
                  </a-popover>
                </span>
              </span>
            </td>
            <td>
              {{getace(participant.totalace)}}
            </td>
            <td>
              {{getplace(participant.placemin,participant.placemax)}}
            </td>
          </tr>
        </table>
        <table v-if="Mydatastore.tourtable.toursystem == 's'" border="1">
          <tr>
            <td>{{t('PAGES.TOURNAMENT_INFO.TABLE.COLUMNS.PLAYER')}}</td>
            <td :colspan="Mydatastore.tourtable.tours[0].games.length"></td>
            <td>{{t('PAGES.TOURNAMENT_INFO.TABLE.COLUMNS.POINTS')}}</td>
            <td>{{t('PAGES.TOURNAMENT_INFO.TABLE.COLUMNS.RATING')}}</td>
            <td>{{t('PAGES.TOURNAMENT_INFO.TABLE.COLUMNS.PLACE')}}</td>
          </tr>
          <tr v-for="tour in Mydatastore.tourtable.tours" :key="tour.login">
            <td>
              {{tour.login}}
            </td>
            <td v-for="game in tour.games" :key=game :class="getclasscell(game)">
              <span v-if="game">
                <span v-if="game.gameid" @click="loadgame(game)" class="asreference">
                  {{getace(game.ace)}}
                  <a-popover v-if="game.virtual">
                    <template #content>
                      {{t("PAGES.TOURNAMENT_INFO.TABLE.GAME_NOT_PLAYED")}}
                    </template>
                    <img src="absent.png" :height="16" :width="16" >
                  </a-popover>
                  <br>
                  {{game.opplogin}}
                </span>
                <span v-if="!game.gameid"  @click="watchgame(game.inprogress)">
                  <a-popover v-if="game.inprogress">
                    <template #content>
                      {{t("PAGES.PLAY.GAMES.COLUMNS.STATUS.CAN_BE_WATCHED")}}
                    </template>
                    <img src="gameStatus/eye.jpg" :height="16" :width="16">
                  </a-popover>
                  <a-popover v-if="game.startday && (!game.inprogress)">
                    <template #content>
                      {{t("PAGES.PLAY.GAMES.COLUMNS.STATUS.GAME_START_SOON")}}
                    </template>
                    <span v-if="Mydatastore.pseudoswitch"></span>
                    <img src="clock.png" :height="16" :width="16" >
                    <span :class="soon(game.startday)">{{remain(game.startday)}}</span>
                  </a-popover>
                  {{game.opplogin}}
                </span>
              </span>
            </td>
            <td>
              {{getace(tour.totalace)}}
            </td>
            <td>
              {{getace(tour.rating)}}
            </td>
            <td>
              {{getplace(tour.placemin,tour.placemax)}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore,notify} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as TIME from '@/sys/time.js'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()

      return {
        t,
        Mydatastore
      }
    },
    methods:{
      soon(checkin){
        let beforecheckin=TIME.timeremainms(checkin)
        return (beforecheckin>=60000)?"blackfont":"redfont"
      },
      remain(checkin){
        return TIME.timeremain(checkin).replace("00.","")
      },
      getace(ace){
        if (ace === null) {
          return null
        } else {
          let M=(ace/2)|0
          let m=ace-M*2
          if (m) {
            return M?M+'½':""+'½'
          } else {
            return M
          }
        }
      },
      getplace(minp,maxp){
        if (! (minp>0)) {
          return '-'
        }
        if (minp == maxp) {
          return minp
        } else {
          return minp+' - '+maxp
        }
      },
      getclasscell(game){
        if (!game){
          return 'yellowcell'
        }
        if  (game.virtual || (!game.inprogress)){
          return 'normalcell'
        } else { 
          return 'asreference'
        }
      },
      loadgame(game){
        if (! game.virtual){
          this.Mydatastore.loadgame(game.gameid,'library')
        } else {
          notify(this.t('LIBRARY.EMPTYGAME'))
        }
      },
      tourname(tourcycle){
        return this.t('TOUR_CYCLE.'+tourcycle)
      },
      timing(time){
        return this.Mydatastore.timing(time)
      },
      watchgame(gameid){
        SOCKETUTILS.watchgame(gameid)
      }
    }
  })
</script>