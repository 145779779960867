<template>
<div class="fixed-overlay fixed-overlay__modal" >
    <div class="modal bordered" @click="pause">
        <div align="right">
          <img src="close.png" class="asreference" width="16" height="16" @click="closewin">
        </div>            
        <div class="modal_container">
          {{BoardStore.messagetxt}}
        </div>
        <ProgressBar :bar="bar"/>
    </div>
</div>
</template>

<script>
  import { defineComponent} from 'vue'
  import { useI18n } from 'vue-i18n'
  import {useBoardStore} from '@/store/BoardStore.js'
  import {useMyDataStore} from '@/store/MyData.js'
  import ProgressBar from '@/components/ProgressBar'
  
  export default defineComponent({
    components:{
      ProgressBar
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
      this.count()
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      return{
        bar:{
          width:200,
          height:8,
          progress:0
        }
      }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
      const BoardStore = useBoardStore()

      const progress= {
        limit:(Mydatastore && Mydatastore.mydata && Mydatastore.mydata.profprivate && 
                   (Mydatastore.mydata.profprivate.showcommpause >=0))?Mydatastore.mydata.profprivate.showcommpause:3,
        progresscounter:0,
        timer:null
      }

      const closewin=( () => {
        BoardStore.modalvisible='none'
      })

      return {
        t,
        BoardStore,
        closewin,
        progress
      }
    },
    methods:{
      count(){
        this.progress.progresscounter++
        this.bar.progress=(this.bar.width*this.progress.progresscounter/this.progress.limit)|0
        if (this.progress.progresscounter == this.progress.limit){
          this.closewin()
        } else {
          this.progress.timer=setTimeout(this.count,1000)
        }        
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.closewin()
        } else {
          if ((e.key == 'Enter')||(e.key==' ')) {
            this.pause()
          }
        }
      },
      pause(){
        if (this.progress.timer){
          clearTimeout(this.progress.timer)
          this.progress.timer=null
        } else {
          this.progress.timer=setTimeout(this.count,1000)
        }
      }
    }
  });

</script>
