import { defineStore } from 'pinia'
import {useMyDataStore,notify,sendsmth,setURL} from '@/store/MyData.js'
import * as POSITION from '@/hooks/position.js'
import * as POSTREE from '@/hooks/PosTree.js'
import  useI18n  from '../i18n.js'
import * as SOCKETUTILS from '@/hooks/socketutils.js'

const { t } = useI18n.global
const initpos=[1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,3,3,3,3,3,3,3,3,3,3,3,3];
const zeropos=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

let audioCtx
let oscillator

export function pisk(){
  audioCtx = new(window.AudioContext || window.webkitAudioContext)();
  oscillator = audioCtx.createOscillator();
  oscillator.type = 'square';
  oscillator.frequency.setValueAtTime(100, audioCtx.currentTime);
  oscillator.connect(audioCtx.destination);
  oscillator.start()
  setTimeout(stoppisk,50)          
}

function stoppisk(){
  oscillator.stop()
  oscillator=null
  audioCtx=null
}

export const useBoardStore = defineStore('BoardStore', {
  state: () => {
    const Mydatastore = useMyDataStore()

    return { 
      testposition:{
        p1:initpos,
        turn:'w',
        pid:'test'
      },
      currentposition:{
        size:640,//496,
        abc:false,
        chcount:8,
        turn:"w",
        p1:zeropos,
        orient:"w",
        selected:[],
        n1:null,
        itin:[],
        killed:[],
        awail:[],
        lastmove:null,
        showitin:0,
        showawail:0,
        showlast:false,
        showkilled:false,
        pid:'current'
      },
      w:{
        size:64,
        abc:false,
        chcount:1,
        turn:"w",
        p1:[1],
        orient:"w",
        selected:[],
        itin:null,
        awail:[],
        lastmove:null,
        showitin:0,
        showawail:0,
        showlast:false,
        showkilled:false,
        pid:'checker1'
      },
      W:{
        size:64,
        abc:false,
        chcount:1,
        turn:"w",
        p1:[2],
        orient:"w",
        selected:[],
        itin:null,
        awail:[],
        lastmove:null,
        showitin:0,
        showawail:0,
        showlast:false,
        showkilled:false,
        pid:'checker2'
      },
      b:{
        size:64,
        abc:false,
        chcount:1,
        turn:"w",
        p1:[3],
        orient:"w",
        selected:[],
        itin:null,
        awail:[],
        lastmove:null,
        showitin:0,
        showawail:0,
        showlast:false,
        showkilled:false,
        pid:'checker3'
      },
      B:{
        size:64,
        abc:false,
        chcount:1,
        turn:"w",
        p1:[4],
        orient:"w",
        selected:[],
        itin:null,
        awail:[],
        lastmove:null,
        showitin:0,
        showawail:0,
        showlast:false,
        showkilled:false,
        pid:'checker4'
      },
      emptychecker:{
        size:64,
        abc:false,
        chcount:1,
        turn:"w",
        p1:[0],
        orient:"w",
        selected:[],
        itin:null,
        awail:[],
        lastmove:null,
        showitin:0,
        showawail:0,
        showlast:false,
        showkilled:false,
        pid:'checker0'
      },
      editturn:'w',
      selectedchecker:null,
      Mydatastore,
      posintree:[],
      playlist:null,
      blackredpostree:[],
      visualtree:[],
      cartoonmode:'CARTOON',
      trainingmode:'IAMWATCHER',
      bookmarks:[],
      currentslaveindex:null,
      snapchain:[],
      modalvisible:'none',
      messagetxt:"",
      run:false,
      playtimer:null,
      itintimer:null,
      movechoice:[],
      selectedchoice:null,
      offsetinsideslave:0,
      moviedelay:0,
      mycomment:0,
      discussion:[],
      lastdiscussid:100000000000,
      temporaryids:[],
      editchain:null,
      editchainmode:null,
      todoafteranimation:[],
      expkeys:[-1]
    }
  },
  actions: {
    hideModal(){
      this.modalvisible='none'
    },
    foundslaveintree(node,slavename,gamename){
      if (node.id==slavename){
        node.title=gamename
        return true
      } else {
        if (node.children){
          for (let i=0; i<node.children.length;i++){
            if (this.foundslaveintree(node.children[i],slavename,gamename)){
              return true
            }
          }
        }
      }
      return false
    },
    edit2playlist(){
      console.log('edit2playlist:'+JSON.stringify(this.editchain))
      let slave={
        gameid:this.editchain.id
      }
      if (this.editchainmode == 'editchain'){
          slave=(this.currentslaveindex == -1)?this.playlist:this.playlist.slaves[this.currentslaveindex]
      }
      let showtypes=[];
      for (let i=0;i<this.editchain.gameingrp.length;i++){
        if (this.editchain.gameingrp[i]){
          showtypes.push(i+1)  
        }
      }
      slave.source="db"
      slave.ownerid=this.Mydatastore.mydata.id
      slave.ownerlogin=this.Mydatastore.mydata.login
      slave.wplayer=null
      slave.bplayer=null
      slave.wlogin=null
      slave.blogin=null
      slave.finish=t('DISCUSSION.NOW')
      slave.gres=this.editchain.gres
      slave.board=this.currentposition.orient
      slave.gamename=this.editchain.gamename
      slave.descr=this.editchain.descr
      slave.public=0
      slave.commenabled=0
      slave.turn=this.editchain.startturn
      slave.moves=JSON.parse(JSON.stringify(this.editchain.moves))
      slave.commcnt=this.editchain.commcnt?this.editchain.commcnt:0
      slave.www=this.editchain.www
      slave.keywords=[this.editchain.keywords]
      slave.showtypes=showtypes
      slave.tournament=0
      slave.virtual=0
      slave.slaves=(slave.slaves && slave.slaves.length)?JSON.parse(JSON.stringify(slave.slaves)):[]
      slave.orating=this.Mydatastore.mydata.rating
      slave.wrating=0
      slave.brating=0
      slave.grating=0
      slave.rnd='library1'
      if (this.editchainmode == 'newchain'){
        setURL('dbgame='+slave.gameid)
        this.Mydatastore.currentplaylist=slave
        this.setplaylist(slave)
        this.buildtree()
      } else {
        if (this.editchainmode == 'addchain'){
          this.playlist.slaves.push(slave)
          this.buildtree()
          this.currentslaveindex=this.playlist.slaves.length-1
        } else {
          if (this.editchainmode == 'editchain'){
            let slavename='slave'+this.currentslaveindex
            for (let i=0;i<this.visualtree.length;i++){
              if (this.foundslaveintree(this.visualtree[i],slavename,slave.gamename)){
                break
              }
            }
          }
        }
      }
      console.log('edit2playlist:'+JSON.stringify(this.playlist))
    },
    backfromedit(){
      this.bookmarks=[]
      this.selectslave(this.currentslaveindex)
      this.setexpkeys()
    },
    msg_savingres(msg){
      if (msg.out_error_code){
        notify(t('COMMON.ERROR'),t('MESSAGES.ERRSAVEGAME')+' '+msg.out_error_code+' : '+msg.out_error_text)
      } else {
        if (this.editchain && (this.Mydatastore.rightpanel == 'protocol')){
          if (!this.editchain.id) {
            this.editchain.id=msg.gameid
          }
          if (this.editchainmode == 'newchain'){
            this.playlist.gameid=msg.gameid
          }
          if ((this.editchainmode == 'addchain')&&(msg.gameid!=this.playlist.gameid)){
            SOCKETUTILS.addslave(this.playlist.gameid,msg.gameid)
          }
        }
      }
    },
    savegame(){
      if (this.editchain.moves.length == 0){
        return;
      }
      if (this.editchain.gres == null){
        this.editchain.gres=3
      }
      let showtypes=[];
      for (let i=0;i<this.editchain.gameingrp.length;i++){
        if (this.editchain.gameingrp[i]){
          showtypes.push(i+1)  
        }
      }
      let savemsg={
        name:"savegame",
        gameid:this.editchain.id,
        initpos:this.editchain.moves[0].pos1,
        board:this.currentposition.orient,
        turn:this.editchain.startturn,
        moves:[],
        gres:this.editchain.gres,
        gamename:this.editchain.gamename,
        keywords:this.editchain.keywords,
        descr:this.editchain.descr,
        www:this.editchain.www,
        showtypes:showtypes
      }
      this.editchain.moves.forEach(a =>{
        savemsg.moves.push({
          n1:a.n1,
          itin:a.itin,
          killed:a.killed,
          newpos:a.pos2,
          comment:a.comment
        })
      })
      sendsmth(savemsg);
      this.editchain.changed=false
    },
    nullid(){
      this.editchain.id=0
      this.editchain.changed=true
      if (this.editchainmode=='editchain') {
        this.editchainmode='newchain'
      }
    },
    changeeditchain(){
      let moveold=(this.editchain.moves.length > this.editchain.currentindex)?this.editchain.moves[this.editchain.currentindex]:null
      let movenew={
        pos1:JSON.parse(JSON.stringify(this.currentposition.p1)),
        n1:this.currentposition.awail[this.selectedchoice].n1,
        itin:JSON.parse(JSON.stringify(this.currentposition.awail[this.selectedchoice].itin)),
        killed:JSON.parse(JSON.stringify(this.currentposition.awail[this.selectedchoice].killed)),
        pos2:JSON.parse(JSON.stringify(this.currentposition.awail[this.selectedchoice].newpos)),
        turn:moveold?moveold.turn:this.currentposition.turn
      }
      if ((moveold==null)||(moveold.n1!=movenew.n1)||(JSON.stringify(moveold.itin) != JSON.stringify(movenew.itin))){
        this.nullid()
        this.editchain.moves.splice(this.editchain.currentindex,this.editchain.moves.length)
        this.editchain.moves.push(JSON.parse(JSON.stringify(movenew)))
      }
      this.editchain.currentindex++
    },
    cutafter(ind){
      this.editchain.moves.splice(ind+1,this.editchain.moves.length)
      this.nullid()
    },
    cutbefore(ind){
      this.editchain.moves.splice(0,ind)
      if (ind%2){
        this.editchain.startturn=this.antiturn(this.editchain.startturn)
      }
      this.nullid()
      this.editchain.currentindex=0
    },
    currentfromedit(ind){
      this.editchain.currentindex=ind
      if (this.editchain.moves.length){
        if (ind >= this.editchain.moves.length){
          this.currentposition.p1=JSON.parse(JSON.stringify(this.editchain.moves[ind-1].pos2))
          this.currentposition.turn=(ind%2==1)?this.antiturn(this.editchain.startturn):this.editchain.startturn
          this.currentposition.showitin=0
          this.currentposition.showkilled=false
        } else {
          this.currentposition.p1=JSON.parse(JSON.stringify(this.editchain.moves[ind].pos1))
          this.currentposition.turn=(ind%2)?this.antiturn(this.editchain.startturn):this.editchain.startturn
          this.currentposition.n1=this.editchain.moves[ind].n1
          this.currentposition.itin=JSON.parse(JSON.stringify(this.editchain.moves[ind].itin))
          this.currentposition.killed=JSON.parse(JSON.stringify(this.editchain.moves[ind].killed))
          this.currentposition.showitin=this.editchain.moves[ind].itin.length
          this.currentposition.showkilled=true
        }
        this.currentposition.awail=POSITION.buildmoves(this.currentposition.p1)
      }
    },
    createeditchain(i){
      this.currentposition.lastmove=null
      if ((i!=null) && this.playlist && (i<this.playlist.slaves.length)){
        let slave=(i == -1)?this.playlist:this.playlist.slaves[i]
        this.editchain={
          moves:JSON.parse(JSON.stringify(slave.moves)),
          startpos:JSON.parse(JSON.stringify(slave.moves[0].pos1)),
          startturn:slave.turn,
          id:(slave.source == 'recent')?0:slave.gameid,
          gamename:slave.gamename,
          descr:slave.descr,
          gres:slave.gres,
          www:slave.www,
          changed:(slave.source == 'recent'),
          currentindex:0
        }
        let ct=slave.turn
        this.editchain.moves.forEach(a => {
          a.turn=ct
          ct=this.antiturn(ct)
        })
        let s=""
        for (let j=0; j<slave.keywords.length; j++){
          s=s+slave.keywords[j]+"\n"    
        }
        this.editchain.keywords=s
        this.editchain.gameingrp=[false,false,false,false]
        if (slave.showtypes){
          for (let j=0;j<slave.showtypes.length;j++){
            this.editchain.gameingrp[slave.showtypes[j]-1]=true
          }
        }
        this.currentposition.n1=slave.moves[0].n1
        this.currentposition.itin=JSON.parse(JSON.stringify(slave.moves[0].itin))
        this.currentposition.killed=JSON.parse(JSON.stringify(slave.moves[0].killed))
        this.currentposition.showitin=slave.moves[0].itin.length
        this.currentposition.showkilled=true
      } else {
        this.editchain={
          moves:[],
          startpos:JSON.parse(JSON.stringify(this.currentposition.p1)),
          startturn:this.currentposition.turn,
          id:0,
          gamename:'',
          descr:'',
          www:'',
          keywords:'',
          gres:3,
          gameingrp:[false,false,false,false],
          changed:false,
          currentindex:0
        }
      }
      this.currentposition.awail=POSITION.buildmoves(this.currentposition.p1)
    },
    discussresult(newid){
      let temp=this.temporaryids.shift()
      for (let i=0;i<this.discussion.length;i++){
        if (this.discussion[i].id==temp){
          this.discussion[i].id=newid
          break
        }
      }
    },
    placereply(id,txt){
      let place=0
      let left=0
      if (id!=0){
        for (let i=0;i<this.discussion.length;i++){
          if (this.discussion[i].id==id){
            place=i+1
            left=this.discussion[i].left+1
          }
        }
      }
      let newid=100000000000000000+(Math.random()*1000000)|0
      this.temporaryids.push(newid)
      this.discussion.splice(place,0,{
        id:newid,
        parent:id,
        ownerid:this.Mydatastore.mydata.id,
        ownrname:this.Mydatastore.mydata.login,
        ownrrating:this.Mydatastore.mydata.rating,
        date:t('DISCUSSION.NOW'),
        left:left,
        txt:txt
      })
    },
    msg_mycomment(msg){
      if (this.playlist.gameid == msg.data.gameid){
        this.playlist.mycomment=msg.data.rating
      }
    },
    msg_discussion(msg){
      let parents=[]
      for (let i=0;i<msg.data.discussion.length;i++){
        let lft=0
        if (this.lastdiscussid>msg.data.discussion[i].id){
          this.lastdiscussid=msg.data.discussion[i].id
        }
        if (msg.data.discussion[i].parent == 0){
          parents=[]
          lft=0
        } else {
          for (let j=parents.length-1; j>=0; j--){
            if (parents[j] == msg.data.discussion[i].parent) {
              parents.splice(j+1,10000)
              lft=j+1
              break
            }
          }
        }
        parents.push(msg.data.discussion[i].id);
        msg.data.discussion[i].left=lft
        this.discussion.push(msg.data.discussion[i]);
      }
      if (msg.data.discussion.length<15){
        this.lastdiscussid=0
      }
    },
    orientrecent(){
      this.currentposition.orient=(this.playlist.wplayer == this.Mydatastore.mydata.id)?"w":"b"
    },    
    setmoviedelay(rat){
      this.moviedelay=2001-(rat*2000+1)|0;
    },
    acceptyourdata(msg){
      this.currentposition.abc=(msg.profprivate.showabc == 1)
      let speed=msg.profprivate.showspeed?msg.profprivate.showspeed*1:0.5
      this.setmoviedelay(speed)
      setTimeout(this.calcleaderssize,20,this.leaderssize)
    },
    startanimation(){
      this.currentposition.showkilled=1
      this.currentposition.showitin=0
      this.currentposition.n1=this.currentposition.awail[this.selectedchoice].n1
      this.currentposition.itin=JSON.parse(JSON.stringify(this.currentposition.awail[this.selectedchoice].itin))
      this.currentposition.killed=JSON.parse(JSON.stringify(this.currentposition.awail[this.selectedchoice].killed))
      this.animation()
    },
    animation(){
      if (this.currentposition.showitin == this.currentposition.itin.length){
        this.stopanimation()
      } else {
        this.currentposition.showitin++
        this.itintimer=setTimeout(this.animation,this.moviedelay)
      }
    },
    chargeplay(){
      if ((!this.nowismychoice()) && this.run){
        this.playtimer=setTimeout(this.play,this.moviedelay)
      }
    },
    stopanimation(){
      if (this.itintimer){
        clearTimeout(this.itintimer)
        this.itintimer=null
        this.setmessage()
        this.makemove()
        this.postanimation()
      }
    },
    postanimation(){
      while (this.todoafteranimation.length){
        let todo=this.todoafteranimation.shift()
        if (todo.action=='mygame'){
          this.acceptmygame(todo.data)
        } else {
          if (todo.action=='oppmove'){
            this.acceptoppmove(todo.data)
            break
          } else {
            if (todo.action=='moveback'){
              this.acceptoppmoveback(todo.data)
              break
            }
          }
        }
      }
    },
    acceptoppmoveback(msg){
      this.currentposition.p1=msg.pos
      this.currentposition.turn=msg.color
      let side=(msg.color=='w')?t('GAME.RESULT.WHITES'):t('GAME.RESULT.BLACKS')
      notify(side+' '+t('GAME.MOVEBACKACCEPTED'))
    },
    msg_moveback(msg){
      if (!this.Mydatastore.game){
        if (this.itintimer){
          this.todoafteranimation.push({
            action:'moveback',
            data:JSON.parse(JSON.stringify(msg))
          })
        } else {
          this.acceptoppmoveback(msg)
        }
      }
    },
    makesinglestep(){
      this.selectedchoice=0
      this.gameonclick(this.currentposition.awail[0].n1)
    },
    acceptmygame(game){
      if ((this.currentposition.orient != game.mycolor)&&(game.mycolor !='wb')){
        this.currentposition.orient = game.mycolor
      }
      this.currentposition.p1=JSON.parse(JSON.stringify(game.pos))
      this.currentposition.awail=JSON.parse(JSON.stringify(game.am))
      this.currentposition.turn=game.turn
      this.currentposition.showitin=0
      this.currentposition.showkilled=0
      this.currentposition.showawail=0
      //221113
      if (this.currentposition.awail && (this.currentposition.awail.length==1)&&
        ((this.Mydatastore.mydata.profprivate.enabledAutoSingleStep=='1')||(this.Mydatastore.mydata.profprivate.enabledAutoSingleStep==1))){
        this.makesinglestep()        
      }
      //221113end
    },
    msg_mygames(msg){
      if (msg.games.length){
        if (this.itintimer){
          this.todoafteranimation.push({
            action:'mygame',
            data:JSON.parse(JSON.stringify(msg.games[0]))
          })
        } else {
          this.acceptmygame(msg.games[0])
        }
      }
    },
    acceptoppmove(msg){
      this.selectedchoice=0
      this.currentposition.turn=msg.data.turn

      this.currentposition.awail=[]
      this.currentposition.awail.push({
        n1:msg.data.n1,
        itin:JSON.parse(JSON.stringify(msg.data.itin)),
        killed:JSON.parse(JSON.stringify(msg.data.killed)),
        newpos:JSON.parse(JSON.stringify(msg.data.newpos))
      })
      this.startanimation()
    },
    msg_oppmove(msg){
      if (this.itintimer){
        this.todoafteranimation.push({
          action:'oppmove',
          data:JSON.parse(JSON.stringify(msg))
        })
      } else {
        this.acceptoppmove(msg)
      }
    },
    msg_initpos(msg){
      this.dropitin()
      this.currentposition.p1=JSON.parse(JSON.stringify(msg.initpos))
      this.currentposition.turn=msg.turn
      this.currentposition.orient=msg.board
      this.currentposition.awail=[]
    },
    addbm(text){
      let snap=this.create_snapshot()
      snap.snapchain=JSON.parse(JSON.stringify(this.snapchain))
      this.bookmarks.push({
        id:this.bookmarks.length,
        txt:(text.length<10)?text:text.slice(0,8)+'...',
        snap:snap
      })
    },
    create_snapshot(){
      let sn = {
        currentposition:JSON.parse(JSON.stringify(this.currentposition)),
        cartoonmode:this.cartoonmode,
        trainingmode:this.trainingmode,
        currentslaveindex:this.currentslaveindex,
        selectedchoice:this.selectedchoice
      }
      sn.currentposition.n1=null
      sn.currentposition.itin=[]
      sn.currentposition.selected=[]
      sn.currentposition.killed=[]
      return sn
    },
    cancelchoice(){
      this.run=false
      this.currentposition.showawail=0
    },
    gotobm(bmid){
      this.restore_snapshot(this.bookmarks[bmid].snap)
    },
    selectchoice(i){
      this.currentposition.awail.forEach(a => a.selected=0)
      this.selectedchoice=i
      this.currentposition.awail[i].selected=1
    },
    moveafterchoice(){
      let found=0
      for (let i=0;i<this.currentposition.awail[this.selectedchoice].slaves.length;i++){
        if (this.currentslaveindex==this.currentposition.awail[this.selectedchoice].slaves[i].slaveind){
          found=1
          break
        } 
      }
      if (!found){
        this.currentslaveindex=this.currentposition.awail[this.selectedchoice].slaves[0].slaveind
      }
        this.startanimation()
        this.setexpkeys()
    },
    play(){
      if (this.itintimer || (this.modalvisible == 'message')){
        this.playtimer=setTimeout(this.play,this.moviedelay)
        return
      }
      let eliglen=this.currentposition.awail.filter(a => a.eligible).length
      if (eliglen){
        if ((eliglen>1) && this.Mydatastore.mydata.profprivate && (this.Mydatastore.mydata.profprivate.stoponfork=='1') &&
          (this.cartoonmode == 'CARTOON')){
          this.movechoice=[] 
          for (let i=0;i<this.currentposition.awail.length;i++){
            if (this.currentposition.awail[i].eligible){
              let possiblepos=JSON.parse(JSON.stringify(this.currentposition))
              possiblepos.n1=this.currentposition.awail[i].n1
              possiblepos.itin=JSON.parse(JSON.stringify(this.currentposition.awail[i].itin))
              possiblepos.killed=JSON.parse(JSON.stringify(this.currentposition.awail[i].killed))
              possiblepos.showitin=possiblepos.itin.length
              possiblepos.size=96
              possiblepos.abc=false
              possiblepos.showkilled=true
              this.movechoice.push({
                id:i,
                position:possiblepos,
                slaves:JSON.parse(JSON.stringify(this.currentposition.awail[i].slaves))
              })
            }
          }
          this.currentposition.showawail=1
          this.modalvisible='movechoice'
        } else {
          let elnum=Math.floor((Math.random()*eliglen))
          this.selectedchoice=this.currentposition.awail.filter(a => a.eligible)[elnum].npp
          this.moveafterchoice()
        }
      } else {
        this.run=false
      }
    },
    restore_snapshot(snap){
      this.currentposition=JSON.parse(JSON.stringify(snap.currentposition))
      this.cartoonmode=snap.cartoonmode
      this.trainingmode=snap.trainingmode
      this.currentslaveindex=snap.currentslaveindex
      this.selectedchoice=snap.selectedchoice
      this.dropitin()
      if (snap.snapchain){
        this.snapchain=JSON.parse(JSON.stringify(snap.snapchain))
      }
    },
    stepback(){
      if (this.snapchain.length){
       this.restore_snapshot(this.snapchain.pop())
       this.run=false
      }
    },
    selectchecker(checker){
      this.selectedchecker=checker
      this.w.selected=[]
      this.W.selected=[]
      this.b.selected=[]
      this.B.selected=[]
      this.emptychecker.selected=[]
      switch (checker){
        case 0:{
          this.emptychecker.selected=[0]
          break
        }
        case 1:{
          this.w.selected=[0]
          break
        }
        case 2:{
          this.W.selected=[0]
          break
        }
        case 3:{
          this.b.selected=[0]
          break
        }
        case 4:{
          this.B.selected=[0]
          break
        }
      }
    },
    getcurrentposition(){
      return this.currentposition
    },
    updatecurrentposition(pl){
      this.currentposition.p1=JSON.parse(JSON.stringify(pl.moves[0].pos1))
      this.currentposition.turn=pl.turn
      this.currentposition.awail=[]
      this.currentposition.selected=[]
      this.currentposition.showitin=0
      this.currentposition.showawail=0
      this.currentposition.lastmove=null
      this.currentposition.showlast=false
      this.currentposition.showkilled=false
      //this.currentposition.orient=pl.board?pl.board:"w"
      this.run=false
      this.offsetinsideslave=0
    },
    fullitinstr(move){
      return move.n1+JSON.stringify(move.itin)
    },
    buildeligible(){
      let normal=this.normalizepos(this.currentposition.p1)
      let actualslaves=POSTREE.search(this.blackredpostree[normal.len],normal.str)
      let found=0
      this.selectedchoice=null
      for (let i=0; i<this.currentposition.awail.length;i++){
        let aw=this.currentposition.awail[i]
        aw.selected=0
        aw.npp=i
        aw.slaves=[]
        let awitin=this.fullitinstr(aw)
        for (let j=0;j<actualslaves.length;j++){
          let slaveind = actualslaves[j]
          let slave=(slaveind==-1)?this.playlist:this.playlist.slaves[slaveind]
          for (let k=0;k<slave.moves.length;k++){
            let move=slave.moves[k]
            if ( (awitin == this.fullitinstr(move))&&(normal.str == this.normalizepos(move.pos1).str) ){
              found=1
              aw.eligible=true
              aw.slaves.push({
                gamename:slave.gamename,
                slaveind:slaveind,
                comment:slave.moves[k].comment
              })
              if ((this.selectedchoice == null) && (slaveind == this.currentslaveindex)){
                this.selectedchoice=i
                aw.selected=1
              }
              aw.pos2str=this.normalizepos(aw.newpos).str
              break
            }
          }
        }
      }
      if ((found == 0)&&(this.Mydatastore.rightpanel != 'protocol')){
        this.run=false
        notify(t('COMMON.ALARM'),t('CARTOON.ENDOFCHAIN'))
      }
    },
    setplaylist(pl){
      //this.stoptimer(this.playtimer)
      clearTimeout(this.playtimer)
      this.playtimer=null
      if (pl.source == 'recent'){
        pl.gameid=0
        pl.ownerid=this.Mydatastore.mydata.id
      }
      this.updatecurrentposition(pl)
      this.bookmarks=[]
      this.playlist=JSON.parse(JSON.stringify(pl))
      this.playlist.warnedcolormismatch=false
      this.currentposition.awail=POSITION.buildmoves(this.currentposition.p1)
      this.currentslaveindex=-1
      this.snapchain=[]
      this.setexpkeys()
    },
    checkmismatch(){
      if (!this.playlist.warnedcolormismatch){
        let slave=(this.currentslaveindex==-1)?this.playlist:this.playlist.slaves[this.currentslaveindex]
        let cp2str=JSON.stringify(this.currentposition.p1)
        for (let i=0;i<slave.moves.length;i++){
          if (cp2str == JSON.stringify(slave.moves[i].pos1)){
            if ((this.currentposition.turn == slave.turn) != (i%2==0)){
              this.playlist.warnedcolormismatch=true
              notify(t('CARTOON.MISMATCHCOLOR'))
            }
            return
          }
        }
      }
    },
    startfrom(p1,p2,turn){
      let normal=this.normalizepos(p1)
      let actualslaves=POSTREE.search(this.blackredpostree[normal.len],normal.str)
      if (actualslaves.length){
        this.snapchain=[]
        this.currentposition.lastmove=null
        this.currentposition.p1=JSON.parse(JSON.stringify(p1))
        this.currentposition.turn=turn
        this.currentposition.awail=POSITION.buildmoves(this.currentposition.p1)
        this.currentslaveindex=actualslaves[0]
        if (p2){
          let pos2str=JSON.stringify(p2)
          for (let i=0;i<actualslaves.length;i++){
            let slave=(actualslaves[i]==-1)?this.playlist:this.playlist.slaves[actualslaves[i]]
            for (let j=0; j<slave.moves.length;j++){
              if (pos2str == JSON.stringify(slave.moves[j].pos2)){
                this.currentslaveindex=actualslaves[i]
                break
              }
            }
          }
        }
        this.setexpkeys()
        this.buildeligible()
        this.dropitin()
        this.checkmismatch()
      }
    },
    selectslave(ind){
      let pl=(ind==-1)?this.playlist:this.playlist.slaves[ind]
      this.updatecurrentposition(pl)
      this.currentposition.awail=POSITION.buildmoves(this.currentposition.p1)
      this.currentslaveindex=ind
      this.buildeligible()
      this.snapchain=[]
      this.checkmismatch()
    },
    normalizepos(pos1){
      return {
        str:JSON.stringify(pos1).replaceAll(',',''),
        len:pos1.filter(p => p>0).length
      }
    },
    treenode(title,key){
      return {
        title:title,
        key:key,
        children:[],
        id:'slave'+key
      }
    },
    getparent(id){
      if (this.playlist.slaves[id]){
        return this.playlist.slaves[id].parent
      }
      return null
    },
    buildtree(){
      //221128
      this.playlist.slaves.forEach(a => {
        a.parent=null
      })
      //221128end
      if (this.playlist.slaves) {
        this.blackredpostree=[]
        for (let i=0; i<33;i++){
          this.blackredpostree.push(null)
        }

        this.playlist.moves.forEach(a => {
          let normal=this.normalizepos(a.pos1)
          this.blackredpostree[normal.len]=POSTREE.inspos(this.blackredpostree[normal.len],normal.str,-1)
        })
        let normal=this.normalizepos(this.playlist.moves[this.playlist.moves.length-1].pos2)
        this.blackredpostree[normal.len]=POSTREE.inspos(this.blackredpostree[normal.len],normal.str,-1)

        for (let i=0;i<this.playlist.slaves.length;i++){
          this.playlist.slaves[i].moves.forEach(a => {
            let normal=this.normalizepos(a.pos1)
            this.blackredpostree[normal.len]=POSTREE.inspos(this.blackredpostree[normal.len],normal.str,i)
          })
          let normal=this.normalizepos(this.playlist.slaves[i].moves[this.playlist.slaves[i].moves.length-1].pos2)
          this.blackredpostree[normal.len]=POSTREE.inspos(this.blackredpostree[normal.len],normal.str,i)
        }
        let matrix=[]
        for (let i=0;i<33;i++){
          POSTREE.obhod(this.blackredpostree[i],matrix)
        }
        this.visualtree=[]
        let visualt=new this.treenode(this.playlist.gamename,-1)
        let queue=[{
          ind:-1,
          treebranch:visualt//this.visualtree
        }]
        let stillwork=1
        while (stillwork){
          while (queue.length){
            let currchain=queue.shift()
            for (let i=matrix.length-1;i>=0;i--){
              let j=matrix[i].findIndex(element => element == currchain.ind)
              if (j>=0){
                matrix[i].filter(a => a!=currchain.ind).forEach(b =>{
                  if (this.playlist.slaves[b].parent == undefined){
                    let newnode = new this.treenode(this.playlist.slaves[b].gamename,b)
                    currchain.treebranch.children.push(newnode)
                    queue.push({
                      ind:b,
                      treebranch:newnode
                    })
                    this.playlist.slaves[b].parent=currchain.ind
                  }
                })
                matrix.splice(i,1)  
              }
            }
          }
          this.visualtree.push(visualt)
          stillwork=matrix.length
          if (stillwork){
            let newminmatrix=1000000000000
            matrix.forEach(a => {
              a.forEach(b => {
                if (b<newminmatrix){
                  newminmatrix=b
                }
              })
            })
            visualt=new this.treenode(this.playlist.slaves[newminmatrix].gamename,newminmatrix)
            queue=[{
              ind:newminmatrix,
              treebranch:visualt
            }]
            //221128
            this.playlist.slaves[newminmatrix].parent=-2
            //221128end
          }
        }
        //221128
        for (let i=0;i<this.playlist.slaves.length;i++){
          if ((this.playlist.slaves[i].parent == null)||(this.playlist.slaves[i].parent == undefined)){
            visualt=new this.treenode(this.playlist.slaves[i].gamename,i)
            this.visualtree.push(visualt)
          }
        }
        //221128end
        this.Mydatastore.spinneroff(false)
      }
      this.buildeligible()
    },
    currentposclick(X,Y){
      this.Mydatastore.setlastactivity()
      if (! (this.Mydatastore && this.Mydatastore.mydata)){
        notify(t('COMMON.ALARM'),t('COMMON.AUTH_NEEDED'),1)
        return
      }
      let n1=this.getN1(X,Y)
      if (this.Mydatastore.rightpanel == 'setposition'){
        this.setposition(n1)
        return
      }
      if (this.Mydatastore.rightpanel == 'cartoon'){
        this.cartoonclick(n1)
        return
      }
      if (this.Mydatastore.rightpanel == 'solution'){
        this.puzzleonclick(n1)
        return
      }
      if (this.Mydatastore.rightpanel == 'protocol'){
        this.protocolonclick(n1)
        return
      }
      if (this.Mydatastore.rightpanel == 'game'){
        this.gameonclick(n1)
        return
      }
    },
    nowismychoice(){
      let a=  ( 
                (this.cartoonmode == 'TRAINING') && (
                  (this.trainingmode == 'IAMBOTH') || 
                  (this.currentposition.turn == 'w') && (this.trainingmode == 'IAMWHITE') ||
                  (this.currentposition.turn == 'b') && (this.trainingmode == 'IAMBLACK')
                ) 
              )
      return a
    },
    hintbuttondisabled(){
      return !(this.nowismychoice() && this.currentposition.awail.filter(a =>a.eligible).length)
    },
    dropitin(){
      this.currentposition.showitin=0
      this.currentposition.n1=null
      this.currentposition.itin=[]
      this.currentposition.selected=[]
      this.currentposition.killed=[]
    },
    selectfield(n1){
      this.currentposition.selected.push(n1)
    },
    add2itin(n1,eligibleonly){
      if (n1 >=0 ) {
        if (this.currentposition.n1 == null) {
          this.currentposition.n1 = n1
        } else {
          this.currentposition.itin.push(n1)
        }

        let j=0; //общее кол-во совп
        let crm=-1;//индекс совпадающего

        for (let i=0; i<this.currentposition.awail.length; i++) {
          if ((! eligibleonly)||(this.currentposition.awail[i].eligible)){
            if ((this.currentposition.awail[i].n1==this.currentposition.n1)&&(this.currentposition.itin.length <= this.currentposition.awail[i].itin.length)) {
              let l = 0
              for (let k=0; k<this.currentposition.itin.length; k++) {
                if (this.currentposition.itin[k] != this.currentposition.awail[i].itin[k]){
                  l=1
                  break
                }
              }
              if (l == 0) {
                j++
                crm = i
              }
            }
          }
        }
        if (j == 0) {
          this.dropitin()
        } else {
          this.selectfield(n1)
        }
        return {
          varcount:j,
          movenumber:crm
        }
      } else {
        this.dropitin()
        return {
          varcount:0
        }
      }
    },
    setmessage(){
      if ( (this.Mydatastore.rightpanel=='cartoon') && this.Mydatastore.mydata && this.Mydatastore.mydata.profprivate && 
            (this.Mydatastore.mydata.profprivate.showcomm == '1')){
        this.messagetxt=null;

        for (let i=0;i<this.currentposition.awail[this.selectedchoice].slaves.length;i++){
          if (this.currentposition.awail[this.selectedchoice].slaves[i].slaveind == this.currentslaveindex){
            this.messagetxt=this.currentposition.awail[this.selectedchoice].slaves[i].comment
            break;
          }
        }

        if (this.messagetxt){ 
          this.modalvisible='message'
        }
      }
    },
    makemove(){
      if ((this.modalvisible == 'message')||(this.itintimer)){
        setTimeout(this.makemove,100)
        return
      }
      let movenumber=this.selectedchoice
      this.snapchain.push(this.create_snapshot())
      this.currentposition.lastmove=JSON.parse(JSON.stringify(this.currentposition.awail[movenumber]))
      this.currentposition.p1=this.currentposition.awail[movenumber].newpos
      this.currentposition.turn=this.antiturn(this.currentposition.turn)
      this.currentposition.awail=POSITION.buildmoves(this.currentposition.p1)
      if (this.Mydatastore.rightpanel == 'cartoon'){
        this.buildeligible()
      }
      this.offsetinsideslave=1
      this.dropitin()
      this.chargeplay()
    },
    mkchoice(){
      this.currentposition.showawail=0
      this.modalvisible='none'
      this.moveafterchoice()
    },
    moveeligible(movenumber){
      if (this.currentposition.awail[movenumber].eligible){
        for (let i=0;i<this.currentposition.awail[movenumber].slaves.length;i++){
          if (this.currentposition.awail[movenumber].slaves[i].slaveind == this.currentslaveindex){
            return this.currentslaveindex
          }
        }
        return this.currentposition.awail[movenumber].slaves[0].slaveind
      } else return null
    },
    cartoonclick(n1){
      if (this.nowismychoice() && this.currentposition.awail.filter(a => a.eligible).length) {
        let add2itinresult=this.add2itin(n1,false)
        if (add2itinresult.varcount == 1) {
          let me=this.moveeligible(add2itinresult.movenumber)
          if ( me != null){
            this.currentslaveindex=me
            this.selectedchoice=add2itinresult.movenumber
            this.setmessage()
            this.makemove()
            this.setexpkeys()
          } else {
            notify(t('COMMON.ERROR'),t('CARTOON.WRONGMOVE'))
            pisk()
            this.dropitin()
          }
        }
      } else {
        if (this.modalvisible == 'movechoice'){
          let add2itinresult=this.add2itin(n1,true)
          if (add2itinresult.varcount == 1){
            this.selectchoice(add2itinresult.movenumber)
            this.mkchoice()

          }    
        }
      }
    },
    puzzleonclick(n1){
      if (this.currentposition.awail.length) {
        let add2itinresult=this.add2itin(n1,false)
        if (add2itinresult.varcount == 1) {
          this.selectedchoice=add2itinresult.movenumber
          this.makemove()
          this.finaltest()
          this.snapchain=[]
        }
      }
    },
    gameonclick(n1){
      if (this.currentposition.awail && this.currentposition.awail.length && this.Mydatastore.game) {
        let add2itinresult=this.add2itin(n1,false)
        if (add2itinresult.varcount == 1) {
          this.selectedchoice=add2itinresult.movenumber
          SOCKETUTILS.mymove(this.Mydatastore.game.gid,this.currentposition.awail[this.selectedchoice])
          this.makemove()
          this.Mydatastore.game.turn=this.currentposition.turn
          this.Mydatastore.game.pos=this.currentposition.p1
          if (this.Mydatastore.game.mycolor != 'wb'){
            this.currentposition.awail=[]
          }
          if (this.Mydatastore.game.drawlater){
            this.Mydatastore.game.drawlater=null
            SOCKETUTILS.offerdraw(this.Mydatastore.game.gid)
          }
          if (this.Mydatastore.game.mymovescnt){
            this.Mydatastore.game.mymovescnt++
          } else {
            this.Mydatastore.game.mymovescnt=1
          }
        }
      }
    },
    protocolonclick(n1){
      if (this.currentposition.awail.length) {
        if (this.currentposition.showitin){
          this.dropitin()
        }
        let add2itinresult=this.add2itin(n1,false)
        if (add2itinresult.varcount == 1) {
          this.selectedchoice=add2itinresult.movenumber
          this.changeeditchain()
          this.makemove()
          this.snapchain=[]
        }
      }
    },
    finaltest(){
      let normal=this.normalizepos(this.currentposition.p1)
      let actualslaves=POSTREE.search(this.blackredpostree[normal.len],normal.str)
      if (actualslaves.length == 0){
        return
      }
      if (this.currentposition.awail.length==0){
        for (let i=0; i<actualslaves.length; i++){
          let slave=(actualslaves[i]==-1)?this.playlist:this.playlist.slaves[actualslaves[i]]
          if (this.normalizepos(slave.moves[slave.moves.length-1].pos2).str == normal.str){
            notify(t('PUZZLE.GOODRESULT'),t('PUZZLE.SOLUTIONOK'))
            return
          }
        }
        notify(t('PUZZLE.BADRESULT'),t('PUZZLE.NOSOLUTION'))
        return
      }
      for (let i=0; i<actualslaves.length; i++){
        let slave=(actualslaves[i]==-1)?this.playlist:this.playlist.slaves[actualslaves[i]]
        for (let j=0;j<slave.moves.length;j++){
          let p1norm=this.normalizepos(slave.moves[j].pos1)
          if (p1norm.str == normal.str){
            return
          }
        }
      }
      notify(t('PUZZLE.GOODRESULT'),t('PUZZLE.SOLUTIONOK'))
    },
    showhint(){
      this.currentposition.showawail=1
    },
    hidehint(){
        this.currentposition.showawail=0
    },
    setposition(n1){
      if (n1 == -1){
        return
      }
      this.currentposition.p1[n1]=(this.currentposition.turn == 'w')? this.selectedchecker : POSITION.convrule[this.selectedchecker]
    },
    getN1(X,Y){
      let chcount89=this.currentposition.abc?9:8
      let dx=this.currentposition.size/chcount89
      let  x1 = ((X/dx)|0); 
      let  y1 = chcount89-1- ((Y/dx)|0);
      if (this.currentposition.turn != this.currentposition.orient) {
        x1=chcount89-1-x1;
        y1=chcount89-1-y1;
      }
      if (this.currentposition.abc && (this.currentposition.turn == this.currentposition.orient)){
        x1--
        y1--
      }
      let sdvig =y1 & 1;
      return ((x1 & 1)==sdvig)? y1*4+(x1 >> 1) : -1
    },
    emptypos(){
      this.currentposition.p1=JSON.parse(JSON.stringify(zeropos))
    },
    startpos(){
      this.currentposition.p1=JSON.parse(JSON.stringify(initpos))
      this.currentposition.turn='w'
      this.editturn='w'
    },
    changeturn(){
      let p=POSITION.convertpos(this.currentposition.p1)
      this.currentposition.turn=this.editturn
      this.currentposition.p1=p
    },
    antiturn(a){
      if (a=="w"){
        return "b"
      } else {
        return "w"
      }
    },
    setexpkeys(){
      if (this.currentslaveindex>=0){
        let parent=this.getparent(this.currentslaveindex)
        if (parent){
          this.expkeys=[]
          while (parent){
            this.expkeys.push(parent)
            parent=this.getparent(parent)
          }
        }
      } else {
        this.expkeys=[-1]
      }
    }

  }
})
