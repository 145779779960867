<template>
  <svg :width="bar.width" :height="bar.height" >
    <rect x=0 y=0 :width="bar.width" :height="bar.height" style="fill:lightblue;stroke:lightblue;"/>
    <rect x=0 y=0 :width="bar.progress" :height="bar.height" style="fill:blue;stroke:blue;" />
  </svg>
</template>

<script type="text/javascript">

  export default {
    props:["bar"],
  }

</script>
