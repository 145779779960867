<template>
  <div class="gameroom" id="divdiscussion">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%;">
      <ExitBack/>
      <span class="formheader">{{BoardStore.playlist.gamename}}
      </span>
      <span v-if="BoardStore.playlist.ownerid">
        {{t('CARTOON.AUTHOR')}} {{BoardStore.playlist.ownerlogin}}
        <span v-if="BoardStore.playlist.www">
          (
          <span v-if="www" class="asreference" @click="MydataStore.openreference(BoardStore.playlist.www)">
            {{BoardStore.playlist.www}}
          </span>
          <span v-if="! www" >
            {{BoardStore.playlist.www}}
          </span>
          )
        </span> 
      </span>
      <span>{{BoardStore.playlist.descr}}
  <!--    <button @click="MydataStore.showCartoon">
        {{t('DISCUSSION.BACK2CARTOON')}}
      </button>-->
      </span>
      <div class="bordered wide96">
        <table width="100%">
          <tr>
            <td>
              {{t('DISCUSSION.RATING')}}<br>
              <img :src="getrating(BoardStore.playlist.grating)" height="20" width="100">
                ({{roundup()}})
            </td>
            <td>
              {{t('DISCUSSION.MARKCOUNT')}}<br>
              <b>{{BoardStore.playlist.commcnt}}</b>
            </td>
            <td v-if="BoardStore.playlist.ownerid != MydataStore.mydata.id">
              {{t('DISCUSSION.MYMARK')}}<br>
              <img id="evalstars" :class="starclass()" :src="getmyrating()" height="20" width="100" @click="clickevaluation">
              <button v-if="(this.BoardStore.playlist.mycomment + this.newrating)>0" @click="evaluate()">
                {{getbutton()}}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="width96left alignleft">
        <table width="100%">
          <tr>
            <td>
              <span v-if="commlevel0open"> {{t('DISCUSSION.YOURCOMMENT')}}:</span>
              <span v-else class="asreference" @click="openlevel0(true)">{{t('DISCUSSION.COMMENTHERE')}}</span>
            </td>
          </tr>
          <tr v-if="commlevel0open">
            <td>
              <textarea v-model="commentlevel0" style="width:80%" maxlength="2000"></textarea>
            </td>
          </tr>
          <tr v-if="commlevel0open">
            <td>
              <button @click="openlevel0(false)">
                {{t('COMMON.CANCEL')}}
              </button>
              <button @click="sendlevel0" :disabled="commentlevel0.length==0">
                {{t('COMMON.SEND')}}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="alignleft bordered autoscroll" style="width:96%" @scroll="scroll">
        <span id="spandiscussion" v-for="d in BoardStore.discussion" :key="d.id">
          <p :style="getspanstyle(d.left)">
            <small>
              <b>{{d.ownrname}}({{d.ownrrating}})</b>
              {{d.date}}:
            </small>
            <br>
            {{d.txt}}
            <br>
            <span class="asreference" @click="showreplyform(d.id)" v-if="openedform!=d.id">
              {{t('DISCUSSION.REPLY')}}
            </span>
            <span v-if="openedform==d.id">
              <table width="100%">
                <tr>
                  <td>
                    <textarea v-model="myreply"  style="width:80%" maxlength="2000"></textarea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button @click="closelevel()">
                      {{t('COMMON.CANCEL')}}
                    </button>
                    <button @click="sendreply" :disabled="myreply.length==0">
                      {{t('COMMON.SEND')}}
                    </button>
                  </td>
                </tr>
              </table>
            </span>
         </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import {useBoardStore} from '@/store/BoardStore.js'
  import {useMyDataStore} from '@/store/MyData.js'
  import * as UTILS from '@/hooks/utils.js'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import ExitBack from '@/components/ExitBack'
  
  export default defineComponent({
    components:{
      ExitBack
    },
    data(){
      return{
        www:this.MydataStore.likereference(this.BoardStore.playlist.www),
        newrating:0,
        commentlevel0:'',
        commlevel0open:false,
        openedform:0,
        myreply:''
      }
    },
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const BoardStore=useBoardStore()
      const MydataStore = useMyDataStore()
      
      BoardStore.lastdiscussid=100000000000
      BoardStore.playlist.mycomment=null
      BoardStore.discussion=[]
      SOCKETUTILS.getcommentgame(BoardStore.playlist.gameid)
      SOCKETUTILS.requestdiscussion(BoardStore.playlist.gameid,BoardStore.lastdiscussid)

      return {
        t,
        BoardStore,
        MydataStore,
      }
    },
    methods:{
      closelevel(){
        this.openedform=0
        this.myreply=''
      },
      getrating(a){
        if (!a) {
          return UTILS.getrating(0)
        } else {
          return UTILS.getrating(a)
        }
      },
      getmyrating(){
        return this.BoardStore.playlist.mycomment?this.getrating(this.BoardStore.playlist.mycomment):this.getrating(this.newrating)
      },
      getbutton(){
        return (!this.BoardStore.playlist.mycomment)?this.t('COMMON.SEND'):this.t('DISCUSSION.CANCEL')
      },
      getspanstyle(l){
        return "margin-left:"+(l*5)+"%"
      },
      clickevaluation(e){
        if (this.BoardStore.playlist.mycomment){
          return
        }
        let evalstartcoord=document.getElementById("evalstars").getBoundingClientRect()
        let X = (e.clientX - evalstartcoord.left)|0
        let evalrat=X/evalstartcoord.width
        if (evalrat<0.08){
          this.newrating=1
        } else {
          this.newrating=((evalrat+0.15)*5)|0
        }
      },
      evaluate(){
        if (!this.BoardStore.playlist.mycomment){
          this.BoardStore.playlist.grating=(this.BoardStore.playlist.grating*this.BoardStore.playlist.commcnt+this.newrating)/(this.BoardStore.playlist.commcnt+1)
          this.BoardStore.playlist.mycomment=this.newrating

          this.BoardStore.playlist.commcnt=this.BoardStore.playlist.commcnt+1
          SOCKETUTILS.commentgame(this.BoardStore.playlist.gameid,this.BoardStore.playlist.mycomment)
        } else {
          if (this.BoardStore.playlist.commcnt == 1){
            this.BoardStore.playlist.grating=0
            this.BoardStore.playlist.commcnt=0
          } else {
            this.BoardStore.playlist.grating=(this.BoardStore.playlist.grating*this.BoardStore.playlist.commcnt-this.BoardStore.playlist.mycomment)/(this.BoardStore.playlist.commcnt-1)
            this.BoardStore.playlist.commcnt=this.BoardStore.playlist.commcnt-1
          }
          this.newrating=0
          this.BoardStore.playlist.mycomment=0
          SOCKETUTILS.commentgame(this.BoardStore.playlist.gameid,null)
        }
        this.MydataStore.updategamerating(this.BoardStore.playlist.gameid,this.BoardStore.playlist.grating)
      },
      openlevel0(a){
        this.commlevel0open=a
      },
      async scroll(){
        if (this.BoardStore.lastdiscussid>0){
          if (document.getElementById("spandiscussion").getBoundingClientRect().bottom-document.getElementById("divdiscussion").getBoundingClientRect().bottom<50){
            if (!this.scroll_locked){
              this.scroll_locked=true
              //this.Mydatastore.library_getnextpage()
              SOCKETUTILS.requestdiscussion(this.BoardStore.playlist.gameid,this.BoardStore.lastdiscussid)
              await new Promise(r => setTimeout(r, 1000));
              this.scroll_locked=false
            }
          }
        }
      },
      sendlevel0(){
        SOCKETUTILS.discussgame(this.BoardStore.playlist.gameid,null,this.commentlevel0)
        this.BoardStore.placereply(0,this.commentlevel0)
        this.commentlevel0=""
        this.commlevel0open=false
      },
      sendreply(){
        SOCKETUTILS.discussgame(this.BoardStore.playlist.gameid,this.openedform,this.myreply)
        this.BoardStore.placereply(this.openedform,this.myreply)
        this.closelevel()
      },
      showreplyform(i){
        this.myreply=''
        this.openedform=i
      },
      starclass(){
        return (this.BoardStore.playlist.mycomment)?null:"asreference"
      },
      roundup(){
        return Math.round(this.BoardStore.playlist.grating*100)/100
      }
    },
  })
</script>

<style type="text/css">
</style>