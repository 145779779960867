<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('LIBRARY.SEARCH_POS')}}
    </template>
    <template v-slot:body>
      <a-form>
        <div class="alignleft">
          <a-checkbox v-model:checked="pos1filter">
            {{t('LIBRARY.CURR_POS_FILTER')}}
          </a-checkbox>
        </div>
 
        <span v-if="pos1filter">
          <a-checkbox v-model:checked="pos2filter">
            {{t('LIBRARY.SEARCH_MOVE')}}
          </a-checkbox>
          <br>
          <div class="bordered alignleft mh100" v-if="pos2filter">
            <a-radio-group v-model:value="pos2" name="radioG" width="100%">
              <table width="100%">
                <tr align="left" v-for="move in movelist" :key="move.npp">
                  <td width="60%">
                    <a-radio :value="move.npp">
                      <b>{{move.str}}</b>
                    </a-radio>
                  </td>
                  <td width="40%">
                    <CheckersBoard :board="move"/>
                  </td>
                </tr>
              </table>
            </a-radio-group>
          </div>
        </span>
        <a-form-item>
          <a-button @click="close" class="margin16">
            {{t('COMMON.CANCEL')}}
          </a-button>
          <a-button type="primary" @click="setfilter">
            {{t('LIBRARY.SET_FILTER')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import { useI18n } from 'vue-i18n'
  import * as POSITION from '@/hooks/position.js'
  import * as PROTOCOL from '@/hooks/protocol.js'
  import CheckersBoard from '@/components/CheckersBoard'

  export default defineComponent({
    components: {
      CheckersBoard,
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      return{
        pos1filter:false,
        pos2filter:false,
        pos2:0,
      }
    },
    computed:{
      movelist(){
        let cp=this.Boardstore.getcurrentposition()
        let am = POSITION.buildmoves(cp.p1)
        PROTOCOL.addtranscription2moves(am,cp.turn)
        am.forEach((a,index) =>{
          a.p1=cp.p1
          a.turn=cp.turn
          a.orient=cp.orient
          a.showkilled=true
          a.showitin=a.itin.length
          a.size=64
          a.npp=index
        })
        return am
      }
    },
    setup() {
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
      const Boardstore = useBoardStore()

      return {
        t,
        Boardstore,
        Mydatastore,
      }
    },
    methods:{
      setfilter(){
        let p1=this.pos1filter?this.Boardstore.getcurrentposition().p1:null
        let p2=(this.pos1filter && this.pos2filter && this.movelist.length)?this.movelist[this.pos2].newpos:null
        this.Mydatastore.setPosfilter(p1,p2,this.Boardstore.getcurrentposition().turn)
        this.close()
      },
      close(){
        this.Mydatastore.modalvisible='none'
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.close()
        } else {
          if (e.key == 'Enter') {
            this.setfilter()
          }
        }
      },
    }
  });

</script>
<style type="text/css">
  .mh100{
    overflow: auto;
    max-height: 200px;
  }
</style>