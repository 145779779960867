<template>
  <table width="100%">
    <tr align="center">
      <td colspan="2">
        <h3><b>{{t('GAME.COMM')}}</b></h3>
      </td>
    </tr>
    <tr bgcolor="#FFFFE0" >
      <td align="left" style="border:1px solid gray;">
        <div id="chatdiv" style="overflow:auto; min-height: 200px; max-height:200px;" :scrollTop="MydataStore.chat.length*100"> 
          <span v-for="txt,index in MydataStore.chat" :key=index>
            <b><small>{{txt.login}}:</small></b>
            {{txt.txt}}
            <br>
          </span>
        </div>
      </td>
      <td style="vertical-align: top; border:1px solid gray;">
        <div style="overflow:auto; max-height:300px; " 
             v-if="(MydataStore.game && MydataStore.game.watchers && MydataStore.game.watchers.length)||(MydataStore.watching && MydataStore.watching.watchers && MydataStore.watching.watchers.length)">
          <img src="eye.png" width="24">
          <br>
          <span v-if="MydataStore.game">
            <span v-for="watcher in MydataStore.game.watchers" :key="watcher" align=left>
              {{watcher.login}}
              <OneUser :profile="watcher" :imgsize="24"/>
              <br>
            </span>
          </span>
          <span v-else>
            <span v-for="watcher in MydataStore.watching.watchers" :key="watcher" align=left>
              {{watcher.login}}
              <OneUser :profile="watcher" :imgsize="24"/>
              <br>
            </span>
          </span>  
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <input v-model="msg2send" type="text" name="msg2opp" size="25" @keydown.enter="sendmsg" >
        <button @click="sendmsg()" :disabled="(msg2send.length==0)|| ((!MydataStore.game)&&(!MydataStore.watching))||(MydataStore.watching && MydataStore.watching.rating)">{{t('COMMON.SEND')}}</button>
      </td>
      <td>
        <button v-if="(MydataStore.game && MydataStore.game.watchers && MydataStore.game.watchers.length)" @click="expell()" :disabled="MydataStore.game.rating">{{t('GAME.EXPELL')}}</button>
      </td>
    </tr>
  </table>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import OneUser from '@/components/OneUser.vue'


  export default defineComponent({
    components: {
      OneUser,
    },
    data(){
      return{
        msg2send:''
      }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const MydataStore = useMyDataStore()

      return {
        t,
        MydataStore,
      }
    },
    methods:{
      expell(){
        SOCKETUTILS.expell()
        this.MydataStore.mydata.nowatch=1
      },
      sendmsg(){
        if (this.msg2send.length && (this.MydataStore.game || this.MydataStore.watching)){
          SOCKETUTILS.chat(this.msg2send)
          this.msg2send=''
        }
      },
    }
  })
</script>