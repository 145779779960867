"use strict"

const wfields=["a1","c1","e1","g1","b2","d2","f2","h2",
                "a3","c3","e3","g3","b4","d4","f4","h4",
                "a5","c5","e5","g5","b6","d6","f6","h6",
                "a7","c7","e7","g7","b8","d8","f8","h8"];

const bfields=["h8","f8","d8","b8","g7","e7","c7","a7",
                "h6","f6","d6","b6","g5","e5","c5","a5",
                "h4","f4","d4","b4","g3","e3","c3","a3",
                "h2","f2","d2","b2","g1","e1","c1","a1"];

export function move2str(move,turn){
  let myfields;
  if (turn == "w") {
    myfields=wfields;
  } else {
    myfields=bfields;
  }
  let result=myfields[move.n1];
  let sign=move.killed.length?':':'-'
  for (let i=0; i<move.itin.length;i++){
    result=result+sign+myfields[move.itin[i]];
  }
  return result;
}

export function addtranscription2moves(am,turn){
  am.forEach(m => m.str=move2str(m,turn))
}
