<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('AUTH_MODAL.TITLE.AUTH')}}
    </template>
    <template v-slot:body>
      <a-form
        v-if="Mydatastore.modalvisible === 'Login' "
        :model="formState"
        name="normal_login"
        class="login-form"
      >
        <a-form-item
          v-bind:label="t('AUTH_MODAL.COMMON.USERNAME.LABEL')"
          name="username"
          :rules="[{ required: true, message: t('AUTH_MODAL.COMMON.USERNAME.REQUIRED') }]"
        >
          <a-input v-model:value="formState.username" :maxlength=20 v-on:keydown.enter="auth">
            <template #prefix>
              <UserOutlined class="site-form-item-icon" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item
          v-bind:label="t('AUTH_MODAL.COMMON.PASSWORD.LABEL')"
          name="password"
          :rules="[{ required: true, message: t('AUTH_MODAL.COMMON.PASSWORD.REQUIRED') }]"
        >
          <a-input-password v-model:value="formState.password" :maxlength=100 v-on:keydown.enter="auth">
            <template #prefix>
              <LockOutlined class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>

        <div class="login-form-wrap">
          <a-form-item name="remember" no-style>
            <a-checkbox v-model:checked="formState.remember">{{t('AUTH_MODAL.AUTH.REMEMBER')}}</a-checkbox>
          </a-form-item>
          <a class="login-form-forgot" @click="showForgot">{{t('AUTH_MODAL.AUTH.FORGOT_PASSWORD')}}</a>
        </div>

        <a-form-item>
          <a-button :disabled="disabled" type="primary" @click="auth" class="login-form-button">
            {{t('AUTH_MODAL.AUTH.ENTER')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent,reactive,computed } from 'vue';
  import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
  import {useMyDataStore, auth} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      UserOutlined,
      LockOutlined,
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    setup() {
      const formState = reactive({
        username: '',
        password: '',
        remember: true,
      });
      
      if (localStorage.getItem('username')){
        formState.username=localStorage.getItem('username')
      }

      const disabled = computed(() => {
        return !(formState.username && formState.password);
      });

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
    
      return {
        formState,
        disabled,
        t,
        Mydatastore
      };
    },
    methods:{
      async auth(){
        if (this.formState.remember){
          localStorage.setItem('username',this.formState.username)
        }
        await auth(this.formState.username,this.formState.password)
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.Mydatastore.hideModal()
        } else {
          if (e.key == 'Enter') {
            this.auth()
          }
        }
      },
      showForgot(){
        this.Mydatastore.modalvisible='Forgotpass'
      }
    }
  });

</script>
<style>
.login-form {
  max-width: 300px;
}
.login-form-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-form-forgot {
  margin-bottom: 0px;
}
.login-form-button {
  width: 100%;
  margin-top: 16;
}
</style>
