<template>
  <div class="gameroom" style="line-height: 1.4">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%;">
      <span class="formheader">{{t("PROTOCOL.TITLE")}}</span>
      <div class="bordered width96left" style="max-height:88%">
        <div style="width:40%; max-height:95%;">
          <div style="width:100%; max-height:90%; overflow:auto;">
            <table width="95%" :style="movesstyle()">
              <tr v-for="rec,index in protocoltable" :key=index align="left" >
                <td width="8%">
                  {{(index+1)}}.
                </td>
                <td width="45%" :bgcolor="bgcolor(0,index)" @click="setcurrent(0,index)">
                  <span :style="styleofcell(0,index)">
                    {{rec[0].txt}}
                    <a-popover v-if="rec[0].color=='usial'">
                      <template #content>
                        <a-input v-model:value="BoardStore.editchain.moves[rec[0].npp].comment" 
                                          @change="BoardStore.editchain.changed=true"
                                          :maxlength=2000
                                          :placeholder="rec[0].comment?null:this.t('PROTOCOL.ADDCOMMENT')"/>
                      </template>
                      <img :src="rec[0].comment?'redcomment.jpg':'blackcomment.png'" :width="rec[0].comment?24:8" :height="rec[0].comment?24:8"/>
                    </a-popover>
                  </span>
                </td>
                <td v-if="rec[1]" :bgcolor="bgcolor(1,index)" @click="setcurrent(1,index)">
                  <span :style="styleofcell(1,index)">
                    {{rec[1].txt}}
                    <a-popover v-if="rec[1].color=='usial'">
                      <template #content>
                        <a-input v-model:value="BoardStore.editchain.moves[rec[1].npp].comment" 
                                          @change="BoardStore.editchain.changed=true"
                                          :maxlength=2000
                                          :placeholder="rec[1].comment?null:this.t('PROTOCOL.ADDCOMMENT')"/>
                      </template>
                      <img :src="rec[1].comment?'redcomment.jpg':'blackcomment.png'" :width="rec[1].comment?24:8" :height="rec[1].comment?24:8"/>
                    </a-popover>
                  </span>
                </td>
                <td v-else>
                </td>
              </tr>
            </table>
          </div>
          <div style="padding:  10px 0;">
            <button :disabled="beforedisabled()" @click="cutbefore()">{{t('PROTOCOL.CUTBEFORE')}}</button>
            <button :disabled="afterdisabled()" @click="cutafter()">{{t('PROTOCOL.CUTAFTER')}}</button>
          </div>
        </div>
        <div style="width:60%; max-height:95%;">
          <table width="90%" style="margin: 0% 0% 0% 10%;">
            <tr>
              <td align="left">
                <label for="gamename">
                  {{t('PROTOCOL.GAMENAME')}}
                </label>
              </td>
              <td align="right">
                <span v-if='BoardStore.editchain.id' class="asreference" @click="nullid()">id={{BoardStore.editchain.id}}</span>
                <span v-else><img src="new.png" width="24" height="24"></span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <a-input id="gamename" v-model:value="BoardStore.editchain.gamename" 
                          :maxlength=60 @change="BoardStore.editchain.changed=true"
                          :style="gamenamestyle()">
                </a-input>
              </td>
            </tr>
            <tr align="left">
              <td>
                <label for="gamedescr">{{t('PROTOCOL.GAMEDESCR')}}</label>
              </td>
            </tr>
            <tr align="left">
              <td colspan="2">
                <a-input id="gamedescr" v-model:value="BoardStore.editchain.descr" :maxlength=2000 @change="BoardStore.editchain.changed=true">
                </a-input>
              </td>
            </tr>
            <tr align="left">
              <td colspan="2">
                {{t('PROTOCOL.RESULT')}}
                <br>
                <a-radio-group v-model:value="BoardStore.editchain.gres" @change="BoardStore.editchain.changed=true">
                  <br><a-radio :value=3>{{t('EDIT_CHAIN.RESULT.UNDEFINED')}}</a-radio>
                  <br><a-radio :value=0>{{t('EDIT_CHAIN.RESULT.DRAW')}}</a-radio>
                  <br><a-radio :value=1>{{t('EDIT_CHAIN.RESULT.WHITE_WIN')}}</a-radio>
                  <br><a-radio :value=2>{{t('EDIT_CHAIN.RESULT.BLACK_WIN')}}</a-radio>
                </a-radio-group>
                <br>
                <label for="gameref">{{t('PROTOCOL.REFERENCE')}}</label>
                <a-input id="gameref" v-model:value="BoardStore.editchain.www" :maxlength=1000 @change="BoardStore.editchain.changed=true">
                </a-input>
                <br>
                <label for="gamekeywords">{{t('PROTOCOL.KEYWORDS')}}</label>
                <textarea id="gamekeywords" style="width:100%; max-height:36px" v-model="BoardStore.editchain.keywords" @input="BoardStore.editchain.changed=true">
                </textarea>
                <br>
                {{t('PROTOCOL.GROUPS')}}
                <br><a-checkbox v-model:checked="BoardStore.editchain.gameingrp[0]" @change="BoardStore.editchain.changed=true">{{t('EDIT_CHAIN.GROUPS.PLAYED_GAME')}}</a-checkbox>
                <br><a-checkbox v-model:checked="BoardStore.editchain.gameingrp[1]" @change="BoardStore.editchain.changed=true">{{t('EDIT_CHAIN.GROUPS.ANALYZE_AND_DEBUT')}}</a-checkbox>
                <br><a-checkbox v-model:checked="BoardStore.editchain.gameingrp[2]" @change="BoardStore.editchain.changed=true">{{t('EDIT_CHAIN.GROUPS.COMBINATION')}}</a-checkbox>
                <br><a-checkbox v-model:checked="BoardStore.editchain.gameingrp[3]" @change="BoardStore.editchain.changed=true">{{t('EDIT_CHAIN.GROUPS.ETUDE')}}</a-checkbox>
              </td>
            </tr>
            <td colspan="2">
              <button :disabled="savingdisabled()" @click="save()">{{t('PROTOCOL.SAVE')}}</button>
              <button v-if="BoardStore.playlist" @click="back()">{{t('PROTOCOL.BACK')}}</button>
            </td>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import {useBoardStore} from '@/store/BoardStore.js'
  import {useMyDataStore} from '@/store/MyData.js'
  import * as POSITION from '@/hooks/position.js'

  export default defineComponent({
    computed:{
      protocoltable(){
        let t=[]
        //let curturn=this.Boardstore.editchain.startturn
        let rec=[]
        const addelem = ((o) =>{
          rec.push(o)
          if (rec.length == 2){
            t.push(JSON.parse(JSON.stringify(rec)))
            rec=[]
          }
        })
        if (this.BoardStore.editchain.startturn == 'b'){
          addelem({
            npp:null,
            txt:'..',
            color:'virtual'
          })
        }
        for (let i=0;i<this.BoardStore.editchain.moves.length;i++){
          addelem({
            npp:i,
            txt:POSITION.move2str(this.BoardStore.editchain.moves[i]),
            color:'usial',
            comment:this.BoardStore.editchain.moves[i].comment
          })
        }
        addelem({
          npp:this.BoardStore.editchain.moves.length,
          txt:'->',
          color:'last'
        })
        if (rec.length==1){
          t.push(rec)
        }
        return t
      },
      selX(){
        let offset=(this.BoardStore.editchain.startturn=='w')?0:1
        return ((this.BoardStore.editchain.currentindex+offset)%2)?1:0
      },
      selY(){
        let offset=(this.BoardStore.editchain.startturn=='w')?0:1
        return (this.BoardStore.editchain.currentindex+offset)>>1
      }
    },
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const BoardStore=useBoardStore()
      const MydataStore = useMyDataStore()

      return { 
        t,
        BoardStore,
        MydataStore
      }

    },
    methods:{
      afterdisabled(){
        return this.getindex(this.selX,this.selY)==(this.BoardStore.editchain.moves.length-1)
      },
      back(){
        this.BoardStore.backfromedit()
        this.MydataStore.changeright('cartoon')
      },
      beforedisabled(){
        return (this.selY==0)&&((this.BoardStore.editchain.startturn=='b')||(this.selX==0))
      },
      bgcolor(x,y){
        if ((x==this.selX)&&(y==this.selY)){
          return "#DDDDDD"
        }
      },
      cutafter(){
        this.BoardStore.cutafter(this.getindex(this.selX,this.selY))        
      },
      cutbefore(){
        let ind=this.getindex(this.selX,this.selY)
        this.BoardStore.cutbefore(ind)
        this.selY=0
      },
      gamenamestyle(){
        return (this.BoardStore.editchain.gamename.replaceAll(' ','')=='')?"border-color: red;":null
      },
      getindex(x,y){
        let ind=2*y+x
        if (this.BoardStore.editchain.startturn=='b'){
          ind--
        }
        return ind
      },
      movesstyle(){
        return (this.BoardStore.editchain.moves.length == 0)?"border: 1px solid red;":null
      },
      nullid(){
        if (confirm(this.t('PROTOCOL.CONFIRMNULLID'))){
          this.BoardStore.nullid()
        }
      },
      save(){
        this.BoardStore.savegame()
        this.BoardStore.edit2playlist()
      },
      savingdisabled(){
        return (this.BoardStore.editchain.changed==false)||(this.BoardStore.editchain.gamename.replaceAll(' ','') == '')
      },
      setcurrent(x,y){
        if ((this.BoardStore.editchain.startturn=='b')&&(x==0)&&(y==0)){
          return
        } else {
          let ind=2*y+x-((this.BoardStore.editchain.startturn=='b')?1:0)
          this.BoardStore.currentfromedit(ind) 
        }
      },
      styleofcell(x,y){
        return (this.protocoltable[x,y].color=='virtual')?null:'cursor: pointer;'
      }
    }
  })
</script>