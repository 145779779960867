<template>
  <div class="gameroom" id="divlibrary">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%;">
      <span class="formheader">{{t("TAB_BAR.LIBRARY")}}</span>
      <div class="width96left" style="margin-left: 10px;">
        <table width="100%">
          <tr>
            <td align="left" width="50%">
              <span>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.OWNER_FILTER')}}
                  </template>
                  <button :class="ownerbutton()" @click="Mydatastore.showOwnerfilter">
                    <img src="profile.png" width="24" height="24">
                  </button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.RATING_FILTER')}}
                  </template>
                  <button :class="ratingbutton()" @click="Mydatastore.showRatingfilter">
                    <img src="rating.png" width="24" height="24">
                  </button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.BOOKMARK_FILTER')}}
                  </template>
                  <button :class="bookmarkbutton()" @click="Mydatastore.setbookmarkfilter">
                    <img src="izbr.png" width="24" height="24">
                  </button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.SEARCH_FILTER')}}
                  </template>
                  <button :class="searchbutton()" @click="Mydatastore.showSearchfilter">
                    <img src="search.png" width="24" height="24">
                  </button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.SEARCH_ID')}}
                  </template>
                  <button :class="idbutton()" @click="Mydatastore.showIdfilter">
                    <img src="id.png" width="24" height="24">
                  </button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.SEARCH_POS')}}
                  </template>
                  <button :class="posbutton()" @click="Mydatastore.showPosfilter">
                    <img src="smallLogo.png" width="24" height="24">
                  </button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.NO_FILTER')}}
                  </template>
                  <button :class="nofilterbutton()">
                    <img src="nofilter.png" width="24" height="24" @click="Mydatastore.dropLibraryfilter">
                  </button>
                </a-popover>
              </span>
            </td>
            <td width="50%" align="right">
              <a-checkbox v-model:checked="showid">
                {{t('LIBRARY.SHOWID')}}
              </a-checkbox>
           </td>
          </tr>
        </table>
      </div>
<!--      <div class="bordered height90"  @scroll="scroll" >-->
      <div class="userlist"  @scroll="scroll" >
        <table id="tablelibrary">
          <tr v-for="chain in Mydatastore.library" :key="chain.gid" align="left">
            <td v-if="showid">
              {{chain.gid}}
            </td>
            <td>
              {{chain.ownrlogin}}
            </td>
            <td>
              <!--<img src="book.jpg" width="64" height="64">-->
              <CheckersBoard :board="chain" v-if="chain.p1 && chain.p1.length"/>
              <span v-else>{{t('LIBRARY.GAME_NOT_PLAYED')}}</span>
            </td>
            <td>
              <span v-if="chain.ownr>0">
                <span class="asreference" @click="loadgame(chain)">{{chain.comment}}</span>
                <a-popover v-if="chain.slaves.length">
                  <template #content>
                    {{t('COMMON.PLAYLIST')+' '+(chain.slaves.length+1)+' '+t('COMMON.CHAINS')}}
                  </template>
                  <img src="tree.png" width="32" height="32">
                </a-popover>
              </span>
              <span v-if="chain.ownr == 0">
                <span :class="classgamename(chain)" @click="loadgame(chain)">
                  {{chain.wlogin}}({{chain.wrating}}) - {{chain.blogin}}({{chain.brating}}) {{gres(chain.gres)}}
                  <a-popover v-if="chain.virtual">
                    <template #content>
                      {{t('LIBRARY.ABSENT_USER')}}
                    </template>
                    <img src="absent.png" width="32" height="32">
                  </a-popover>  
                  <a-popover v-if="chain.tournament">
                    <template #content>
                      {{t('PAGES.TOURNAMENT_INFO.BUTTON.TOURTABLE')}}
                    </template>
                    <img src="/tournament.png" width="32" height="32" class="asreference" @click="Mydatastore.showTourtable(chain.tournament)">
                  </a-popover>  
                </span>
              </span>
              <br>
              <span>{{chain.dfinish}}</span>
            </td>
            <td>
              <a-popover v-if="chain.showtypes.filter(showtype => (showtype == 3)||(showtype == 4)).length">
                <template #content>
                  {{gethint(chain.showtypes)}}
                </template>
                <img class="asreference" src="brain.jpg" width="32" height="32" @click="Mydatastore.loadgame(chain.gid,'puzzle')">
              </a-popover>
            </td>
            <td>
              <a-popover>
                <template #content>
                  {{t('RATING')}}
                </template>
                <img :src="getrating(chain.gamerating)" height="20" width="100">
                ({{chain.gamerating}})
              </a-popover>
            </td>
            <td>
              <a-popover v-if="infavor(chain.gid)">
                <template #content>
                  {{t("LIBRARY.EXCLUDE_FAVOR")}}
                </template>
                <img class="asreference" src="delbm.png" width="32" height="32" @click="tofavor(chain,0)">
              </a-popover>
              <a-popover v-if="! infavor(chain.gid)">
                <template #content>
                  {{t("LIBRARY.INCLUDE_FAVOR")}}
                </template>
                <img class="asreference" src="izbr.png" width="32" height="32" @click="tofavor(chain,1)">
              </a-popover>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore, notify} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import * as UTILS from '@/hooks/utils.js'
  import CheckersBoard from '@/components/CheckersBoard'

  export default defineComponent({
    components:{
      CheckersBoard,
    },
    data(){
      return{
        scroll_locked:false,
        showid:false
      }
    },
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()

      return {
        t,
        Mydatastore
      }
    },
    methods:{
      classgamename(chain){
        return (chain.p1 && chain.p1.length)?"asreference":null
      },
      infavor(gid){
        for (let i=0;i<this.Mydatastore.favors.length;i++){
          if (gid == this.Mydatastore.favors[i].gid){
            return true
          }
        }
        return false
      },
      loadgame(chain){
        if (chain.p1 && chain.p1.length){
          this.Mydatastore.loadgame(chain.gid,'library')
        } else {
          notify(this.t('LIBRARY.EMPTYGAME'))
        }
      },
      gethint(showtypes){
        let first=1
        let hint=''
        if (showtypes.filter(sht => (sht == 3)).length){
          first=0
          hint=this.t('EDIT_CHAIN.GROUPS.COMBINATION')
        }
        if (showtypes.filter(sht => (sht == 4)).length){
          if (first) {
            hint=hint+' + '
          }
          hint=hint+this.t('EDIT_CHAIN.GROUPS.ETUDE')
        }
        return hint
      },
      getrating(grating){
        return UTILS.getrating(grating)
      },
      gres(gameres){
        switch (gameres) {
          case 0: return '½ : ½'
          case 1: return '1 : 0'
          case 2: return '0 : 1'
          default: return '?'
        }
      },
      ownerbutton(){
        return this.Mydatastore.library_filter.aowner?"selectedbutton":"usialbutton"
      },
      ratingbutton(){
        return this.Mydatastore.ratingfilteron?"selectedbutton":"usialbutton"
      },
      searchbutton(){
        return ((this.Mydatastore.library_filter.ashowtypes &&(this.Mydatastore.library_filter.ashowtypes.length))||
                this.Mydatastore.library_filter.akeywords)?"selectedbutton":"usialbutton"
      },
      bookmarkbutton(){
        return this.Mydatastore.library_filter.afavor?"selectedbutton":"usialbutton"
      },
      idbutton(){
        return this.Mydatastore.idfilter?"selectedbutton":"usialbutton"
      },
      posbutton(){
        return this.Mydatastore.library_filter.p1?"selectedbutton":"usialbutton"
      },
      nofilterbutton(){
        return (this.Mydatastore.library_filter.p1||
                this.Mydatastore.library_filter.ashowtypes||
                this.Mydatastore.library_filter.akeywords||
                this.Mydatastore.library_filter.afavor||
                this.Mydatastore.library_filter.arating||
                this.Mydatastore.library_filter.aowner||
                this.Mydatastore.idfilter)?"usialbutton":"selectedbutton"
      },
      async scroll(){
        if (document.getElementById("tablelibrary").getBoundingClientRect().bottom-document.getElementById("divlibrary").getBoundingClientRect().bottom<50){
          if (!this.scroll_locked){
            this.scroll_locked=true
            this.Mydatastore.library_getnextpage()
            await new Promise(r => setTimeout(r, 1000));
            this.scroll_locked=false
          }
        }
      },
      tofavor(chain,flag){
        if ((flag == 0)&&confirm(this.t('LIBRARY.EXCLUDE_FAVOR')+'?')||(flag == 1)&&confirm(this.t('LIBRARY.INCLUDE_FAVOR')+'?')){
          SOCKETUTILS.tofavor(chain.gid,flag)
          if (flag){
            this.Mydatastore.favors.push(chain)
          } else {
            for (let i=0;i<this.Mydatastore.favors.length;i++){
              if (this.Mydatastore.favors[i].gid == chain.gid){
                this.Mydatastore.favors.splice(i,1)
                break
              }
            }
            if (this.Mydatastore.library_filter.afavor){
              for (let i=0;i<this.Mydatastore.library.length;i++){
                if (this.Mydatastore.library[i].gid == chain.gid){
                  this.Mydatastore.library.splice(i,1)
                  break
                }
              }
            }
          }
        }
      }
    }
  })
</script>

<style type="text/css">
.height90{
  height: 90%;
  overflow: auto;
  width: 96%;
}
</style>