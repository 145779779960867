<template>
  <a-dropdown :trigger="['click']" v-model:visible="this.up" :onVisibleChange= vch ()>
    <a class="ant-dropdown-link" @click.prevent>
      <ProfileButt/>
      <down-outlined v-if="down" id ="ArrowIcon1" style="fontSize: 20px"/>
      <up-outlined v-if="! down" id ="ArrowIcon2" style="fontSize: 20px"/>
    </a>
    <template #overlay>
      <a-menu>
        <a-menu-item>
          <a href="javascript:;" v-if="! (Mydatastore && Mydatastore.mydata && Mydatastore.mydata.login)" @click="this.register()">
            <user-add-outlined /> {{ t('AUTH_MODAL.REGISTRATION.REGISTER') }}</a>
        </a-menu-item>
        <a-menu-item>
          <a href="javascript:;" v-if="(Mydatastore && Mydatastore.mydata && Mydatastore.mydata.login)" @click="this.logout()">
            <logout-outlined /> {{ t('AUTH_MODAL.PROFILE.EXIT') }}
          </a>
        </a-menu-item>
        <a-menu-item>
          <a v-if="! (Mydatastore && Mydatastore.mydata && Mydatastore.mydata.login)" @click="this.showLogin()">
            <login-outlined /> {{ t('AUTH_MODAL.AUTH.ENTER') }}
          </a>
        </a-menu-item>
        <a-menu-item>
          <a href="javascript:;" v-if="(Mydatastore && Mydatastore.mydata && Mydatastore.mydata.login)" @click="this.settings()">
            <setting-outlined /> {{ t('AUTH_MODAL.TITLE.PROFILE') }}</a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script type="text/javascript">
  import { defineComponent } from 'vue'
  import ProfileButt from '@/components/ProfileButt.vue';
  import { useI18n } from 'vue-i18n'
  import {useMyDataStore, logout} from '@/store/MyData.js'
  import {DownOutlined, UpOutlined, LogoutOutlined, LoginOutlined, UserAddOutlined, SettingOutlined}  from '@ant-design/icons-vue';

  export default defineComponent({
    name:'AvaTar',
    data(){
      return {
        down:true,
        up:false
      }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      const Mydatastore = useMyDataStore()
      //setTimeout(Mydatastore.setmyparams,10)
      return { t, Mydatastore }
    },
    methods:{
      vch(visible) {
        this.down=!visible;
        this.up=visible;
      },
      showLogin() {
        this.Mydatastore.showLogin();
        this.down=true;
        this.up=false;
      },
      logout(){
        this.down=true;
        this.up=false;
        logout();
      },
      register(){
        this.Mydatastore.showRegister();
        this.down=true;
        this.up=true;
      },
      settings(){
        this.Mydatastore.showSettings();
        this.down=true;
        this.up=true;
      }
    },

    components:{
      ProfileButt,
      DownOutlined,
      LogoutOutlined,
      LoginOutlined,
      UserAddOutlined,
      SettingOutlined,
      UpOutlined,
    }
  })
  
</script>
