<template>
  <div v-if="Mydatastore.mydata" class="ProfileS bluelevel2 roundborder" style="margin-top: 0; padding: 0">
    <h2>
      <AvaTar/>
      <span class="yellowfont">{{ Mydatastore.mydata.login }}(</span> 
      <img v-if="Mydatastore.mydata.arrow" :src="Mydatastore.mydata.arrow"  width="36" height="36"/>
      <span class="yellowfont">{{Mydatastore.mydata.rating}})</span> 
      <span id="MyProfileSpan">
        <OneUser v-if="Mydatastore.mydata" :profile="Mydatastore.mydata" :imgsize="36"/>
      </span>
    </h2>
  </div>
</template>

<script type="text/javascript">
  import { defineComponent } from 'vue'
  import { useI18n } from 'vue-i18n'
  import {useMyDataStore} from '@/store/MyData.js'
  import OneUser from '@/components/OneUser'
  import AvaTar from '@/components/AvaTar'

  export default defineComponent({
    name:'ProFile',
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      const Mydatastore = useMyDataStore()
      return { t, Mydatastore }
    },
    components:{
      OneUser,
      AvaTar
    },
  })
</script>

<style type="text/css">
  .ProFileS{
    height: 70%;
    width: 25%;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  
  .IconProfile{
    width: 8%;
  }

  @media all and (max-width: 800px) {
    .ProFileS{
      width: 35%;
    }
  }

  @media all and (max-width: 1100px) {
    #MyProfileSpan{
      display: none;
    }
  }
</style>