<template>
  <div class="gameroom">
    <div class="bordered lightblue userlist" style="max-height: 100%;">
      <span class="formheader">{{t("TAB_BAR.RECENT")}}</span>
        <br>
        <table width="100%">
          <tr align="left">
            <td width="30%">
              {{t('RECENT.DATE')}}
            </td>
            <td width="25%">
              {{t('RECENT.WHITE')}}
            </td>
            <td width="25%">
              {{t('RECENT.BLACK')}}
            </td>
            <td width="20%">
              {{t('RECENT.RESULT')}}
            </td>
          </tr>
          <tr v-for="recent in Mydatastore.recent" :key="recent.gameid"  @click="load(recent.gameid)" align="left">
            <td>
              {{recent.time}}
            </td>
            <td>
              {{recent.wlogin}}
            </td>
            <td>
              {{recent.blogin}}
            </td>
            <td class="asreference">
              <span v-if="recent.gres == 0">{{t('RECENT.DRAW')}}</span>
              <span v-if="recent.gres == 1">{{t('RECENT.WHITE_WON')}}</span>
              <span v-if="recent.gres == 2">{{t('RECENT.BLACK_WON')}}</span>
            </td>
          </tr>
        </table>

    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()

      return {
        t,
        Mydatastore
      }
    },
    methods:{
      load(gameid){
        SOCKETUTILS.getrecentgame(gameid)
      },
    }
  })
</script>