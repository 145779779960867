<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('LIBRARY.SEARCH_ID')}}
    </template>
    <template v-slot:body>
      <a-form>
        <div class="bordered alignleft">
          <input type="text" maxlength=12 max-size=12 v-model="idfilter">
        </div>

        <a-form-item>
          <a-button @click="close" class="margin16">
            {{t('COMMON.CANCEL')}}
          </a-button>
          <a-button :disabled="!idfilter" @click="filteroff" class="margin16">
            {{t('LIBRARY.FILTER_OFF')}}
          </a-button>
          <a-button type="primary" @click="setfilter">
            {{t('LIBRARY.SET_FILTER')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      return{
        idfilter:0
      }
    },
    setup() {
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
     
      return {
        t,
        Mydatastore
      };
    },
    methods:{
      filteroff(){
        this.Mydatastore.dropIdfilter()
        this.Mydatastore.hideModal()
        this.idfilter=0
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.close()
        } else {
          if (e.key == 'Enter') {
            this.setfilter()
          }
        }
      },

      setfilter(){
        if ((this.idfilter=='')||(this.idfilter=='0')){
          this.Mydatastore.dropIdfilter()
        } else {
          this.Mydatastore.setIdfilter(this.idfilter)
        }
      },
      close(){
        this.Mydatastore.modalvisible='none'
      }
    }
  });

</script>
