<template>
  <div class="gameroom">
    <div class="bordered lightblue" style="max-height: 100%;">
      <span class="formheader">{{t("POSITION.SETUP")}}</span>
      <div class="userlist">
        <table>
          <tr>
            <td>
              <CheckersBoard :board="Boardstore.w" @click="Boardstore.selectchecker(1)"/>
            </td>
            <td>
              <CheckersBoard :board="Boardstore.W" @click="Boardstore.selectchecker(2)"/>
            </td>
            <td>
              <CheckersBoard :board="Boardstore.b" @click="Boardstore.selectchecker(3)"/>
            </td>
            <td>
              <CheckersBoard :board="Boardstore.B" @click="Boardstore.selectchecker(4)"/>
            </td>
            <td>
              <CheckersBoard :board="Boardstore.emptychecker" @click="Boardstore.selectchecker(0)"/>
            </td>
          </tr>
        </table>
        <br>
        <a-radio-group v-model:value="Boardstore.editturn" @change="changeturn">
          <a-radio value="w">{{t('POSITION.COLOR_SELECTOR.WHITE')}}</a-radio>
          <a-radio value="b">{{t('POSITION.COLOR_SELECTOR.BLACK')}}</a-radio>
        </a-radio-group>
        <br>
        <br>
        <table>
          <tr>
            <td>
              <button @click="emptypos">
                {{t('POSITION.EMPTY')}}
              </button>
            </td>
            <td>
              <button @click="startpos">
                {{t('POSITION.START')}}
              </button>
            </td>
            <td>
              <button @click="startediting">
                {{t('POSITION.FINISH')}}
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import {useBoardStore} from '@/store/BoardStore.js'
  import CheckersBoard from '@/components/CheckersBoard'
  import {useMyDataStore} from '@/store/MyData.js'

  export default defineComponent({
    components:{
      CheckersBoard
    },
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Boardstore=useBoardStore()
      const Mydatastore = useMyDataStore()

      Boardstore.editturn=Boardstore.getcurrentposition().turn
      Boardstore.selectchecker(0)

      return { 
        t,
        Boardstore,
        Mydatastore
      }

    },
    methods:{
      selectchecker(checker){
        this.Boardstore.selectchecker(checker)
      },
      emptypos(){
        this.Boardstore.emptypos()
      },
      startpos(){
        this.Boardstore.startpos()
      },
      changeturn(){
        this.Boardstore.changeturn()
      },
      startediting(){
        this.Boardstore.playlist=null
        this.Boardstore.createeditchain(null)
        this.Boardstore.editchainmode='newchain'
        this.Mydatastore.showProtocol()
      }
    }
  })
</script>