<template>
<!--  <div id="app" style="background: #E0C08F">-->
  <div id="app" class="deepblue">
    <LoginForm  v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Login'))"/>
    <RegisterForm v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Register'))"/>
    <ForgotpassForm v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Forgotpass'))"/>
    <TwopasswordForm v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Twopassword'))"/>
    <SettingsForm v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Settings'))"/>
    <AlarmForm  v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Alarm'))" />
    <MyInvitation  v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Invitation'))"/>
    <InvitationList v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Invitations'))"/>
    <PlayList v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'PlayList'))"/>
    <OwnerFilter v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Ownerfilter'))"/>
    <RatingFilter v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Ratingfilter'))"/>
    <SearchFilter v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Searchfilter'))"/>
    <IdFilter v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Idfilter'))"/>
    <PosFilter v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'Posfilter'))"/>
    <BoardSettings v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'boardsettings'))"/>
    <HelpWin v-if="(Mydatastore && Mydatastore.modalvisible && (Mydatastore.modalvisible === 'help'))"/>
    <IamWaiting v-if="(Mydatastore && Mydatastore.myinv)"/>
    <AboutModal v-if="(Mydatastore && (Mydatastore.modalvisible === 'aboutmodal'))"/>
    <a-spin size="large" :spinning="Mydatastore.spinning">
      <AppHeader/>
      <div id="AppContent">
        <LeftPanel/>
        <DivBoard/>
        <RightPanel/>
      </div>
      <div id="AppFooter">Created by Vladimir Fedosov @2022</div>
    </a-spin>
  </div>
</template>

<script>
  import AppHeader from '@/layouts/AppHeader';
  import LoginForm from '@/modal/LoginForm'
  import ForgotpassForm from '@/modal/ForgotpassForm'
  import RegisterForm from '@/modal/RegisterForm'
  import TwopasswordForm from '@/modal/TwoPasswordForm'
  import LeftPanel from '@/leftside/LeftPanel'
  import DivBoard from '@/Board/DivBoard'
  import RightPanel from '@/rightside/RightPanel'
  import SettingsForm from '@/modal/SettingsForm'
  import AlarmForm from "@/modal/AlarmForm"
  import MyInvitation from "@/modal/MyInvitation"
  import InvitationList from "@/modal/InvitationList"
  import PlayList from "@/modal/PlayList"
  import OwnerFilter from "@/modal/OwnerFilter"
  import RatingFilter from "@/modal/RatingFilter"
  import SearchFilter from "@/modal/SearchFilter"
  import IdFilter from "@/modal/IdFilter"
  import PosFilter from "@/modal/PosFilter"
  import BoardSettings from "@/modal/BoardSettings"
  import IamWaiting from '@/components/IamWaiting'
  import HelpWin from '@/modal/HelpWin'
  import AboutModal from '@/modal/AboutModal'
  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import {useAboutStore} from '@/store/AboutStore.js'

  export default {
    name: 'App',
    setup() {
      const Mydatastore = useMyDataStore()
      const BoardStore = useBoardStore()
      const AboutStore = useAboutStore()
      return {Mydatastore,BoardStore,AboutStore}
    },
    components: {
      AppHeader,
      LoginForm,
      RegisterForm,
      ForgotpassForm,
      TwopasswordForm,
      LeftPanel,
      DivBoard,
      RightPanel,
      SettingsForm,
      AlarmForm,
      MyInvitation,
      InvitationList,
      PlayList,
      OwnerFilter,
      RatingFilter,
      SearchFilter,
      IdFilter,
      PosFilter,
      IamWaiting,
      BoardSettings,
      HelpWin,
      AboutModal
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
</style>
