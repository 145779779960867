<template>
  <h2><b>
  <span @click='changelocale()' class="yellowfont" style="margin:6px; cursor: pointer">{{MydataStore.locale.toUpperCase()}}</span>
  </b></h2>
</template>

<script>
  import i18n from '../i18n';
  import {setlang,useMyDataStore} from '@/store/MyData'

  export default {
    name:"LanguageSelector",
    setup(){
      i18n.global.locale.value = "ru"
      const MydataStore = useMyDataStore()
      return {
        MydataStore
      }
    },
    methods: { // <---------------------------
      switchLocale(locale) {
        let oldlocale=i18n.global.locale.value;
        i18n.global.locale.value = locale;
        if ((oldlocale != locale)&& this.MydataStore.mydata && this.MydataStore.mydata.id) {
          setlang(locale);
        }
        this.MydataStore.locale=locale
      },
      changelocale(){
        if (this.MydataStore.locale=='ru'){
          this.switchLocale('en')
        } else {
          this.switchLocale('ru')
        }
      }
    },
    data() {
      return {
        locales: process.env.VUE_APP_I18N_LOCALE.split(',')
      }
    }
  }
</script>

<style type="text/css">
  #LanguageSelector {
    height: 100%;
  }
</style>