<template>
  <a-popover>
    <template #content>
      {{hint()}}
    </template>
    <div class="waiting" :style="bgcolor">
      <table width="100%">
        <tr>
          <td rowspan="2" align="center">
             <img src="wait1.png" width="64" height="32">
          </td>
          <td>
            <img style="cursor: pointer;" src="close.png" width="24" @click="close">
          </td>   
        </tr>
        <tr>
          <td>
          </td>
        </tr>
      </table>
    </div>
  </a-popover>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import {computed} from 'vue'

  export default {
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const MydataStore = useMyDataStore()

      const bgcolor = computed (() => {
        return "background-color: rgba("+MydataStore.myinv.r+','+MydataStore.myinv.g+','+MydataStore.myinv.b+');'
      })

      return {
        t,
        MydataStore,
        bgcolor
      }
    },
    methods:{
      close(){
        SOCKETUTILS.cancelinvitation()
        this.MydataStore.myinv=null           
      },
      hint(){
        if (!this.MydataStore.myinv){
          return ''
        }
        let a=this.t("INVITE_MODAL.WAIT")
        let b=this.MydataStore.myinv.login?this.MydataStore.myinv.login:this.t('INVITE_MODAL.ANYBODY')
        return a+b 
      }
    }
  }
</script>


<style>
 .waiting{
  width: 10%;
  min-width: 92px;
  left: 89%;
  height: 7%;
  min-height: 52px;
  z-index: 10;
  border-radius: 30px/20px ;
  position: absolute;
  top: 2%;
  display: flex;
  justify-content: center;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
} 
</style>