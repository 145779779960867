import { defineStore } from 'pinia'
import * as POSITION from '@/hooks/position.js'

export const useAboutStore = defineStore('AboutStore', {
  state: () => {
    let d1={
      size:208,
      abc:false,
      chcount:8,
      turn:"w",
      p1:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,2,0,0,0,0,0,0,0,0,0,0,0,0,3,0],
      orient:"w",
      selected:[],
      n1:null,
      itin:[],
      killed:[],
      awail:[],
      lastmove:null,
      showitin:0,
      showawail:2,
      showlast:false,
      showkilled:false,
    }
    let d2={
      size:208,
      abc:false,
      chcount:8,
      turn:"w",
      p1:[0,0,0,0,0,3,0,0,0,0,3,3,0,3,1,0,0,0,0,3,1,0,0,0,0,3,0,0,0,0,0,0],
      orient:"w",
      selected:[],
      n1:null,
      itin:[],
      killed:[],
      awail:[],
      lastmove:null,
      showitin:0,
      showawail:2,
      showlast:false,
      showkilled:false,
    }
    d1.awail=POSITION.buildmoves(d1.p1)
    d2.awail=POSITION.buildmoves(d2.p1)
    return {
      chaptervisible:[true,false,false,false,false,false,false,false,],
      diagr1:d1,
      diagr2:d2
    }
  }
})

//let About=useAboutStore()
//About.diagr1.awail=POSITION.buildmoves(About.diagr1.p1)
