<template>
  <div class="gameroom">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%; height: 100%;">
      <ExitBack/>
      <span class="formheader">{{t('WATCHING.TITLE')}}</span>
      <div class="bordered height90">
        <table width="100%">
          <tr align="left">
            <td width="18%" v-if="MydataStore.watching.game.timelimit && MydataStore.watching.wtime">
              <GameClock :time="MydataStore.watching.wtime"/> 
            </td>
            <td>
              {{t('WATCHING.WHITES')}} : <b>{{MydataStore.watching.game.wlogin}}({{MydataStore.watching.game.wuser.rating}})</b> 
              <OneUser v-if="!MydataStore.watching.game.wuser.absent" :profile="MydataStore.watching.game.wuser" :imgsize="24"/>
              <a-popover v-if="MydataStore.watching.game.wuser.absent">
                <template #content>
                  {{t("GAME.ABSENT")}}
                </template>
                <img src="absent.png" width="24" height="24">
              </a-popover>
            </td>
            <td v-if="MydataStore.watching.game.rating">
              {{t('WATCHING.STAKE')}}:{{MydataStore.watching.deltaratw}}
            </td>
          </tr>
          <tr align="left">
            <td width="18%" v-if="MydataStore.watching.game.timelimit && MydataStore.watching.btime" align="left">
              <GameClock :time="MydataStore.watching.btime"/> 
            </td>
            <td>
              {{t('WATCHING.BLACKS')}} : <b>{{MydataStore.watching.game.blogin}}({{MydataStore.watching.game.buser.rating}})</b> 
              <OneUser :profile="MydataStore.watching.game.buser" :imgsize="24"/>
            </td>
            <td v-if="MydataStore.watching.game.rating">
              {{t('WATCHING.STAKE')}}:{{MydataStore.watching.deltaratb}}
            </td>
          </tr>
        </table>
        <br>
        <span v-if="MydataStore.watching.game.tournament" class="asreference" @click="watchtournament()">{{t('WATCHING.TOURNAMENT')}}</span>
        <br>
        <span v-if="MydataStore.watching.game.timelimit">{{t('WATCHING.TIME')}} : {{MydataStore.watching.game.timecondition}}</span>
        <span v-if="MydataStore.watching.grestxt">{{t('WATCHING.FINISHED')}}. {{MydataStore.watching.grestxt}}</span>
        <CommTable/>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import OneUser from '@/components/OneUser.vue'
  import GameClock from '@/components/GameClock.vue'
  import ExitBack from '@/components/ExitBack'
  import CommTable from '@/components/CommTable'

  export default defineComponent({
    components: {
      OneUser,
      GameClock,
      ExitBack,
      CommTable
    },
    data(){
      return{
        msg2send:''
      }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const MydataStore = useMyDataStore()

      return {
        t,
        MydataStore,
      }
    },
    methods:{
      dialogstyle(){
        return "top:10%; position: absolute; height: 71%; width: "+this.dialogwidth()+"%;  overflow:auto; border: solid #808080 2px; border-radius: 0;"
      },

      dialogwidth(){
        return ((this.MydataStore.game && this.MydataStore.MydataStore.watching.watchers && this.MydataStore.MydataStore.watching.watchers.length)?'57':'96')
      },
      inputstyle(){
        return "top:81%; position: absolute; height: 15%; width: "+this.dialogwidth()+"%; display:flex; justify-content: flex-start;   padding: 5px;"+
                "margin-left:10px;margin-right: 10px;margin-top: 10px;max-width: 100%;"
      },
      sendmsg(){
        if (this.msg2send.length && this.MydataStore.watching){
          SOCKETUTILS.chat(this.msg2send)
          this.msg2send=''
        }
      },
      watchtournament(){
        this.MydataStore.showCurrentTour(this.MydataStore.watching.game.tournament)
      }
    }
  })
</script>