<template>
  <div class="gameroom">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%;">
      <ExitBack/>
      <span class="formheader">{{t("TAB_BAR.ANALYZE")}}</span>
      <div class="width96left" style="margin-left:10px">
        <table width="100%">
          <tr>
            <td align="left" width="20%">
              <CheckersBoard :board="MydataStore.analyzedata.pos1"/>
              <br><button @click="refresh">{{t('COMMON.REFRESH')}}</button>
            </td>
            <td width=30%>
              <a-checkbox v-model:checked="MydataStore.analyzedata.roboton" @change="change">
                {{t('ANALYZE.ROBOTON')}}
              </a-checkbox>
              <br>
              <a-select v-if="MydataStore.analyzedata.roboton" v-model:value="MydataStore.analyzedata.robotid" style="width: 160px" @change="change">
                <a-select-option v-for="robot in MydataStore.users.filter(a=>a.robot)" :key="robot.userid" :value="robot.userid" 
                                 :disabled="noprivl(robot.game2privl)">
                  {{robot.login}}<img v-if="robot.game2privl" src="gerb.jpg" width="24" height="24">
                </a-select-option>
              </a-select>
            </td>
            <td width="30%">
              <a-checkbox v-model:checked="MydataStore.analyzedata.dbon" @change="change">
                {{t('ANALYZE.DATABASE')}}
              </a-checkbox>
              <span v-if="MydataStore.analyzedata.dbon">
                {{t('ANALYZE.MINRATING')}}
                <a-input-number v-model:value="MydataStore.analyzedata.minrating" @change="change"></a-input-number>
              </span>
            </td>
            <td width="20%">
              <button :disabled="buttondisabled()" @click='search'>
                <img src="search.png" width="24">
                {{t('COMMON.READY')}}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="userlist" style="overflow: auto; max-height: 70%;">
        <table width="100%" v-if="MydataStore.analyzedata.description">
          <tr align="left" v-for="move in MydataStore.analyzedata.description.am" :key=move.npp>
            <td width=20%>
              <CheckersBoard :board="move"/>
            </td>
            <td>
              <b>{{move.str}}</b>
            </td>
            <td>
              <span v-if="MydataStore.analyzedata.robotid && MydataStore.analyzedata.roboton">
                <img src="robot.jpg" width="20">
                :{{getestim(move.roboteval,MydataStore.analyzedata.pos1.turn)}}
                <a-popover>
                  <template #content>
                    {{t('ANALYZE.BEST')}}
                  </template>
                <img src="thumbsup.png" width="20" v-if="move.best">
              </a-popover>
              </span>
              <span v-if="move.dbstat">
                <span v-for="stat in move.dbstat.filter(a => a.cnt>0)" :key="stat.group" class="asreference" @click="setfilter(move,stat)">
                  <br>
                  <img src="db.png" width="20">:{{getgroupname(stat.group)}}({{stat.cnt}})
                </span>
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import CheckersBoard from '@/components/CheckersBoard'
  import * as TIME from '@/sys/time.js'
  import ExitBack from '@/components/ExitBack'

  export default defineComponent({
    components:{
      CheckersBoard,
      ExitBack
    },
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const MydataStore = useMyDataStore()
      const BoardStore = useBoardStore()
      
      const change=(()=>{
        MydataStore.analyzedata.description=null
      })

      const refresh=(()=>{
        MydataStore.analyzedata.pos1=JSON.parse(JSON.stringify(BoardStore.getcurrentposition()))
        MydataStore.analyzedata.pos1.showitin=0
        MydataStore.analyzedata.pos1.showkilled=false
        MydataStore.analyzedata.pos1.showawail=0
        MydataStore.analyzedata.pos1.size=80
        MydataStore.analyzedata.pos1.abc=false
        MydataStore.library_filter.turn=BoardStore.getcurrentposition().turn
        change()
      })

      if (MydataStore.analyzedata.pos1 == null){
        refresh()
      }
      return {
        t,
        BoardStore,
        MydataStore,
        refresh,
        ExitBack,
        change
      }
    },
    methods:{
      buttondisabled(){
        return (!(this.MydataStore.analyzedata.roboton && this.MydataStore.analyzedata.robotid))&&(!this.MydataStore.analyzedata.dbon)
      },
      getgroupname(gr){
        return this.t('ANALYZE.'+gr)
      },
      noprivl(robotprivl){
        return ((robotprivl>0) && (!TIME.later(this.MydataStore.mydata.privl)))
      },
      search(){
        SOCKETUTILS.evaluate(this.MydataStore.analyzedata.pos1.p1,this.MydataStore.analyzedata.robotid,
                             this.MydataStore.analyzedata.dbon,this.MydataStore.analyzedata.minrating)
        this.MydataStore.spinneron()
      },
      getestim(eval1, turn){
        if (eval1 ==0) {
          return this.t('ANALYZE.DRAW')
        }
        if ((turn == "w")&&(eval1>4000)||(turn == "b")&&(eval1<-4000)) {
          return this.t('ANALYZE.BWIN')
        }
        if ((turn == "w")&&(eval1>40)||(turn == "b")&&(eval1<-40)) {
          return this.t('ANALYZE.BADV')+' ('+eval1+')'
        }
        if ((turn == "w")&&(eval1>15)||(turn == "b")&&(eval1<-15)) {
          return this.t('ANALYZE.BBETTER')+' ('+eval1+')'
        }
        if ((eval1>=-15)&&(eval1<=15)) {
          return this.t('ANALYZE.EQUAL')+' ('+eval1+')'
        }
        if ((turn == "w")&&(eval1<-4000)||(turn == "b")&&(eval1>4000)) {
          return this.t('ANALYZE.WWIN')
        }
        if ((turn == "w")&&(eval1<-40)||(turn == "b")&&(eval1>40)) {
          return this.t('ANALYZE.WADV')+' ('+eval1+')'
        }
        if ((turn == "w")&&(eval1<-15)||(turn == "b")&&(eval1>15)) {
          return this.t('ANALYZE.WBETTER')+' ('+eval1+')'
        }
        return this.t('ANALYZE.IDONTKNOW')
      },
      setfilter(move,stat){
        let savedturn=this.MydataStore.library_filter.turn
        this.MydataStore.nulllibraryfilter()
        this.MydataStore.library_filter.turn=savedturn
        this.MydataStore.library_filter.aminimalid=10000000000
        this.MydataStore.library_filter.p1=JSON.parse(JSON.stringify(move.p1))
        this.MydataStore.library_filter.p2=JSON.parse(JSON.stringify(move.newpos))
        if (stat.group == 'saved'){
          this.MydataStore.library_filter.amysaved=1
        } else {
          if (stat.group == 'rating'){
            this.MydataStore.library_filter.arating=1
            this.MydataStore.library_filter.aminimalrating=this.MydataStore.analyzedata.minrating
            this.MydataStore.ratingfilteron=true
          }
        }
        this.MydataStore.library=[]
        this.MydataStore.showLibrary()

      },
    }
  })
</script>
