<template>
  
  <div id="buttonsboard" align="center" style="padding-bottom: 12px;">
    <table :width="Boardstore.getcurrentposition().size">
      <tr>
        <td width=33% align="center">
          <button @click="rotateboard()" style="margin-top: 5px;">
            <table>
              <tr>
                <td>
                  <svg :width="imgsize()-5" :height="imgsize()-5">
                    <polyline :points="pointsstrelka()" :style="stylestrelka()"/>
                  </svg>
                </td>
                <td>
                  {{t('BOARD.ROTATE')}}
                </td>
              </tr>
            </table>
          </button>
        </td>
        <td v-if="Mydatastore && Mydatastore.mydata" width=34% align="center">
          <button @click="showboardsettings()" >
            <img src="settings.png" :width="imgsize()">
            {{t('BOARD.SETTINGS')}}
          </button>
        </td>
        <td v-if="Boardstore.currentposition.lastmove && (Boardstore.currentposition.lastmove.n1>=0)" width=33% align="center">
          <button @mousedown="showlast()" @mouseup="hidelast()" @mouseout="hidelast()">
            <img src="footprint.png" :width="imgsize()">
            {{t('BOARD.LAST_MOVE')}}
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script type="text/javascript">

  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import { useI18n } from 'vue-i18n'

  export default {
    setup() {
      
      const Mydatastore = useMyDataStore()
      const Boardstore=useBoardStore()

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })

      return {
        Mydatastore,
        Boardstore,
        t
      }
    },
    methods : {
      imgsize(){
        let a= Math.round(this.Boardstore.getcurrentposition().size/16)|0
        return a
      },
      rotateboard(){
        this.Boardstore.getcurrentposition().orient=(this.Boardstore.getcurrentposition().orient == 'w')?'b':'w'
      },
      showboardsettings(){
        this.Mydatastore.showboardsettings()
      },
      stylestrelka(){
        return (this.Boardstore.getcurrentposition().turn == 'w')?'fill:white;stroke:black':'fill:black;stroke:white'
      },
      pointsstrelka(){
        let px=[-10,0,10,0,-10]
        let py=[-5,0,-5,10,-5]
        let position=this.Boardstore.getcurrentposition()
        if (position.orient == position.turn){
          for (let i=0;i<py.length;i++){
            py[i]=-py[i]
          }
        }
        for (let i=0;i<px.length;i++){
          px[i]=Math.round(px[i]*this.imgsize()/36)|0
          py[i]=Math.round(py[i]*this.imgsize()/36)|0
        }
        let center=(this.imgsize()/2)|0
        let rez=((center+px[0])+','+(center+py[0]))
        for (let i=1;i<px.length;i++){
          rez=rez+' '+(center+px[i])+','+(center+py[i])
        }
        return rez
      }, 
      showlast(){
        this.Boardstore.getcurrentposition().showlast=true
      },
      hidelast(){
        this.Boardstore.getcurrentposition().showlast=false
      },
    }
  }

</script>
