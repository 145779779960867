<template>
  <div class="gameroom">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%;">
      <span class="formheader">{{t("PAGES.TOURNAMENTS.TABS.TIMETABLE")}}</span>
      <br>
      <div style="overflow:auto; max-height: 88%;">
      <div v-for="tour in Mydatastore.tournaments" :key="tour.tourid" class="onetournament">
        <h2>{{tourname(tour.tourcycle)}}</h2>
        {{t('PAGES.TOURNAMENT_INFO.ROWS.STATUS')}} : {{tourstatus(tour)}}
        <span v-if="(tour.status == 'o')&&(later(tour.checkin))">
          <br>
          {{t('PAGES.TOURNAMENT_INFO.ROWS.REGISTRATION_END')}} : {{tour.checkin}} <span :class="soon(tour.checkin)">({{remain(tour.checkin)}})</span>
          <button v-if="! iamsubscribed(tour.participants)" @click="participate(tour.tourid,1)">
            {{t('PAGES.TOURNAMENT_INFO.BUTTON.PARTICIPATE')}}
          </button>
          <button v-if="iamsubscribed(tour.participants)" @click="participate(tour.tourid,0)">
            {{t('PAGES.TOURNAMENT_INFO.BUTTON.UNSUBSCRIBE')}}
          </button>
        </span>
        <span v-if="(tour.status == 'p')||(tour.status == 'a')">
          <button @click="tourtable(tour.tourid)">
            {{t('PAGES.TOURNAMENT_INFO.BUTTON.TOURTABLE')}}
          </button>
          <br>
          {{t('TOUR_SYSTEM.'+tour.toursystem)}}
        </span>
        <br>
        {{t('PAGES.TOURNAMENT_INFO.ROWS.START')}} : {{tour.start}} <span :class="soon(tour.start)">({{remain(tour.start)}})</span>
        <br>
        {{t('PAGES.TOURNAMENT_INFO.ROWS.TIME.TIMING')}} : {{timing(tour.timing)}} 
        <br>
        <span v-if="Mydatastore.pseudoswitch"></span>
        {{t('PAGES.TOURNAMENT_INFO.ROWS.MIN_PLAYERS')}} : {{tour.min2start}} 
        <br>
        {{t('PAGES.TOURNAMENT_INFO.ROWS.MAX_PLAYERS_FOR_ROUND')}} : {{tour.maxround}} 
        <br>
        {{t('PAGES.TOURNAMENT_INFO.ROWS.REGISTERED_USERS')}} : {{tour.participants.length}} 
        <button v-if="! tour.show_participants" @click="show_participants(tour)" :disabled="tour.participants.length==0">
          {{t('PAGES.TOURNAMENT_INFO.SHOW_PARTICIPANTS')}}
        </button>
        <span v-else>
          <button @click="hide_participants(tour)">{{t('PAGES.TOURNAMENT_INFO.HIDE_PARTICIPANTS')}}</button>
          <span v-for="participant in tour.participants" :key="participant.id"><br> {{participant.login}}({{participant.rating}})  </span> 
        </span>
      </div>
    </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as TIME from '@/sys/time.js'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()

      return {
        t,
        Mydatastore
      }
    },
    methods:{
      participate(tourid,flag){
        SOCKETUTILS.enroll(tourid,flag)
      },
      iamsubscribed(participants){
        for (let j=0; j<participants.length;j++) {
          if (participants[j].id == this.Mydatastore.mydata.id){
            return true
          }   
        }
        return false
      },
      timing(time){
        return this.Mydatastore.timing(time)
      },
      soon(checkin){
        let beforecheckin=TIME.timeremainms(checkin)
        return (beforecheckin>=60000)?"blackfont":"redfont"
      },
      remain(checkin){
        return TIME.timeremain(checkin)
      },
      later(tlimit){
        return TIME.later(tlimit)
      },
      tourname(tourcycle){
        return this.t('TOUR_CYCLE.'+tourcycle)
      },
      tourstatus(tour){
        if ( (! TIME.later(tour.checkin) && (tour.status == 'o')) ){
          return this.t('TOURNAMENT_STATUS.REG_CLOSED')
        }
        return this.t('TOURNAMENT_STATUS.'+tour.status)
      },
      show_participants(tour){
        tour.show_participants=true;
      },
      hide_participants(tour){
        tour.show_participants=false;
      },
      tourtable(tourid){
        this.Mydatastore.showCurrentTour(tourid)
      },
    }
  })
</script>