<template>
  
  <a-menu v-model:selectedKeys="current" mode="horizontal" id="MyMnu" theme="dark">
    <a-menu-item key="play" :disabled="!(store && store.mydata)" @click="playroom">
      <img src="playicon.png" width="20" height="20"><span style="padding:4px"><b>{{t('TAB_BAR.PLAY')}}</b></span>
    </a-menu-item>
    <a-sub-menu key="tour" :disabled="!(store && store.mydata)">
      <template #title>
        <img src="tournament.png" width="20" height="20"><span style="padding:4px"><b>{{t("TAB_BAR.TOURNAMENTS")}}</b></span>
      </template>
      <a-menu-item key="timetable" @click="tourtimetable"><b>{{t('TAB_BAR.TIMETABLE')}}</b></a-menu-item>
      <a-menu-item key="archieve" @click="tourarchive"><b>{{t('TAB_BAR.ARCHIEVE')}}</b></a-menu-item>
    </a-sub-menu>
    <a-sub-menu key="learn" :disabled="!(store && store.mydata)">
      <template #title>
        <img src="edu.png" width="20" height="20"><span style="padding:4px"><b>{{t("TAB_BAR.LEARN")}}</b></span>
      </template>
      <a-menu-item key="setup" @click="setuppos"><b>{{t('TAB_BAR.SETUP')}}</b></a-menu-item>
      <a-menu-item key="puzzle" @click="puzzleofday"><b>{{t('TAB_BAR.PUZZLEOFDAY')}}</b></a-menu-item>
      <a-menu-item key="analyze" @click="analyze"><b>{{t('TAB_BAR.ANALYSE')}}</b></a-menu-item>
      <a-menu-item key="library" @click="library"><b>{{t('TAB_BAR.LIBRARY')}}</b></a-menu-item>
      <a-menu-item key="mygames" @click="mygames"><b>{{t('TAB_BAR.MYGAMES')}}</b></a-menu-item>
      <a-menu-item key="recent" @click="recent"><b>{{t('TAB_BAR.RECENT')}}</b></a-menu-item>
    </a-sub-menu>
    <a-menu-item key="about" @click="about">
      <img src="about.png" width="20" height="20"><span style="padding:4px"><b>{{t('TAB_BAR.ABOUT_PROJECT')}}</b></span>
    </a-menu-item>
    <a-menu-item v-if="store && store.mydata" key="help" @click="help">
      <img src="help.jpg" width="20" height="20"><span style="padding:4px"><b>{{t('TAB_BAR.HELP')}}</b></span>
    </a-menu-item>
  </a-menu>

</template>

<script>
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    setup() {
      const current = [];
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      const store = useMyDataStore()
      const BoardStore=useBoardStore()

      return { current, t, BoardStore, store }
    },
    methods:{
      analyze(){
        this.BoardStore.hideModal()
        this.store.showAnalyze()
      },
      playroom(){
        this.BoardStore.hideModal()
        this.store.showGameroom()
      },
      puzzleofday(){
        //this.store.showPuzzle()
        this.BoardStore.hideModal()
        if (this.store.changeright('solution')){
          //this.store.TOD2playlist()
          SOCKETUTILS.getpuzzle()
        }
      },
      recent(){
        this.BoardStore.hideModal()
        this.store.showRecent()
      },
      tourtimetable(){
        this.BoardStore.hideModal()
        this.store.showTourtimetable()
      },
      tourarchive(){
        this.BoardStore.hideModal()
        this.store.showTourarchive()
      },
      mygames(){
        this.BoardStore.hideModal()
        this.store.showMygames()
      },
      library(){
        this.BoardStore.hideModal()
        this.store.showLibrary()
      },
      setuppos(){
        this.BoardStore.hideModal()
        this.store.showSetuppos()
      },
      about(){
        this.BoardStore.hideModal()
        this.store.showAbout()
      },
      help(){
        this.BoardStore.hideModal()
        this.store.showHelp()
      },
    }
  });
</script>

<style type="text/css">
  #MyMnu{
    border-bottom: 0px;
    margin-left: 10px;
  }
  @media all and (max-width: 800px) {
    #MyMnu {
      height: 40px;
    }
  }

</style>