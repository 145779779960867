"use strict"

function rotateleft(h) {
  let x = h.right
  h.right = x.left
  x.left = h
  let xcolor = x.color
  x.color = h.color
  h.color = xcolor
  return x;
}

function rotateright(h){
  let x = h.left
  h.left = x.right
  x.right = h
  let xcolor = x.color
  x.color = h.color
  h.color = xcolor
  return x
}

function flipcolors(h){
  h.color = 1
  h.left.color = 0
  h.right.color = 0
}

function compareto(i,j){
  if (i>j){
    return 1
  }
  if (i<j){
    return -1
  }
  return 0
}


function node(i,val){
  this.key=i
  this.left = null
  this.right = null
  this.color = 1
  this.chains = [val]
}

function isred(h){
  return (h==null)?false:(h.color == 1)
}

function put(h,i,val){
  if (h == null){
    return new node(i,val)
  }
  let cmp = compareto(i,h.key);
  if (cmp<0){ 
    h.left = put(h.left,i,val)
  } else {
    if (cmp>0) { 
      h.right = put(h.right, i, val)
    } else {
      //if (val < h.chains[0]){
      //  h.chains.unshift(val)
      //} else {
        h.chains.push(val)

      //}
    }
  }
  if ( isred(h.right) && (! isred(h.left) ) ) {
    h =rotateleft(h)
  }
  if ( isred(h.left) && isred(h.left.left) ) {
    h = rotateright(h)
  }
  if ((! h.left==null) && (! h.right==null)) {
    if ( (h.left.color == 1) && (h.right.color == 1) ){
      flipcolors(h)
    }
    if (h.left.color+h.right.color == -1) {
      flipcolors(h)
    }
  }
  return h
}

export function inspos(store,p1,val){
  return put(store,p1,val)
}

export function search(h, p1){
  if (h == null) {
    return []
  }
  let cmp =compareto(p1,h.key)
  if (cmp<0) {
    return search(h.left,p1)
  } else {
    if (cmp>0) {
      return search(h.right,p1)
    } else {
      return h.chains
    }
  }
}

export function obhod(h,arr){
  if (!h){
    return
  }
  obhod(h.left,arr)
//  if (h.chains.length>1){
//  }
  if (h.chains.length>1){
    arr.push(h.chains)
  }
  obhod(h.right,arr)
}

//---------------------//
//EXAMPLE!
//let store=null

//for (let i=0; i<300; i++){
//  store=inspos(store,(Math.random()*100)|0,-i)
//}
//obhod(store)
