<template>
  <div class="aboutsection bluelevel1">
    {{t(store.pagesection)}}
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import {useMyDataStore} from '@/store/MyData.js'

  export default defineComponent({
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      const store = useMyDataStore()
      return { t, store }
    },
    components:{

    }
  });
</script>

<style type="text/css">
  .aboutsection1 {
    box-sizing: border-box;
    height: min-content;
    border: solid #F0F0F0 2px;
    padding: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    width: 90%;
  }
  .aboutsection {
    height: min-content;
    padding: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    width: 90%;
    border-radius: 10px/10px;
  }
</style>