<script>
  import {useMyDataStore} from '@/store/MyData.js'

  export default {
    name: 'UniversalModal',
    setup() {
      const Mydatastore = useMyDataStore()
      return {
        Mydatastore
      }
    },
    methods: {
      close() {
        this.Mydatastore.modalvisible='none';
      },
    },
  };
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          This is the default title!
        </slot>
        <div style="left:97%; width=3%; top:0">
          <img src='close.png' width=16 height=16 @click="close" style="cursor: pointer;">
        </div>
      </header>
      <section class="modal-body">
        <slot name="body">
          I'm the default body!
        </slot>
       </section>
    </div>
  </div>
</template>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    padding: 15px;
    display: flex;
    border-bottom: 1px solid #eeeeee;
    justify-content: space-between;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

</style>