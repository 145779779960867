<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('COMMON.SETTINGS')}}
    </template>
    <template v-slot:body>
      <a-form
        :model="Mydatastore.settingsformState"
        name="settings"
        class="settings-form"
      >
        <a-form-item v-bind:label="t('SETTINGS.COUNTRY')" name="country">
          <a-select v-model:value="Mydatastore.settingsformState.country" width=300px align="left">
            
              <a-select-option v-for="country in this.flags" v-bind:key="country">
                <img :src="this.path(country)" width="24"/>
                {{t("COUNTRY_FLAG."+country.substring(0,country.length - 4))}}
              </a-select-option>
            
          </a-select>
        </a-form-item>

        <a-form-item name="dontinvite" no-style>
          <a-popover>
            <template #content>
              {{t('SETTINGS.DONTINVITE')}}
            </template>
            <a-checkbox v-model:checked="Mydatastore.settingsformState.dontinvite">
              {{t('TEMPLATES.PLAY.BOARD.TOP_PANEL.DENY_INVITATIONS')}}
            </a-checkbox>
          </a-popover>
        </a-form-item>
        <br>

        <a-form-item name="dontwatch" no-style>
          <a-popover>
            <template #content>
              {{t('SETTINGS.DONTWATCH')}}
            </template>
            <a-checkbox v-model:checked="Mydatastore.settingsformState.dontwatch">
              {{t('TEMPLATES.PLAY.BOARD.TOP_PANEL.DENY_WATCH')}}
            </a-checkbox>
          </a-popover>
        </a-form-item>
        <br>

        <a-form-item name="autostep" no-style>
          <a-popover>
            <template #content>
              {{t('SETTINGS.AUTOSTEP')}}
            </template>
            <a-checkbox v-model:checked="Mydatastore.settingsformState.autostep">
              {{t('TEMPLATES.PLAY.BOARD.TOP_PANEL.ENABLE_AUTO_STEP')}}
            </a-checkbox>
          </a-popover>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="save" class="settings-form-button">
            {{t('COMMON.SAVE')}}
          </a-button>
        </a-form-item>

      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>

</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import {flags} from '@/store/countries.js'
  
  export default defineComponent({
    components: {
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    setup() {
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
    
      return {
        t,
        Mydatastore,
        flags
      };
    },
    methods:{
      path(a){
        return("flags/"+a)
      },
      save(){
        this.Mydatastore.savesettings();
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.Mydatastore.hideModal()
        } else {
          if (e.key == 'Enter') {
            this.save()
          }
        }
      },
    }
  });

</script>

<style>
  .settings-form {
    max-width: 500px;
    min-width: 300px;
  }
</style>
