"use strict"

let deltatime;

export function time2str(t){
  if (t <= 0 ){
    return('00:00:00')
  }
  let h=(t/3600)|0
  if (h<10) {
    h="0"+h
  }
  let m=((t % 3600)/60)|0
  if (m<10) {
    m="0"+m
  }
  let s=(t % 60)|0
  if (s<10){
    s="0"+s
  }
  return (h+":"+m+":"+s)
}

function str2ms(timestr){
  let year=timestr.slice(0,4);
  let month=timestr.slice(5,7);
  let day=timestr.slice(8,10);
  let hour=timestr.slice(11,13);
  let minute=timestr.slice(14,16);
  let second=0;
  if (timestr.length>16){
    second=timestr.slice(17,19);
  }
  return new Date(year,month-1,day,hour,minute,second,0);
}
  
function zerolead(n){
  if (n<10){
    return ("0"+n);
  } else {
    return n;
  }
}

export function ms2deltatime(d){
  let hour=(d/3600000)|0;
  hour=zerolead(hour);
  let minute=((d-hour*3600000)/60000)|0;
  minute=zerolead(minute);
  let second=((d-hour*3600000-minute*60000)/1000)|0;
  second=zerolead(second);
  return hour+"."+minute+":"+second;
}

export function setservertime(str){
  let servertime=str2ms(str);
  let localtime=new Date();
  deltatime=servertime-localtime;
}

export function later(timestr){
  let ms=str2ms(timestr);
  let localtime=new Date();
  let delta=ms-localtime;
  if (delta>deltatime) {
    return 1;
  } else {
    return 0;
  }
}

export function timeremain(tlimit){
  let ms=str2ms(tlimit);
  let localtime=new Date();
  localtime.setTime(localtime.getTime() + deltatime);
  let delta=ms-localtime;
  if (delta>0) {
    return ms2deltatime(delta);
  } else {
    return "00.00:00";
  }
}

export function timeremainms(tlimit){
  let ms=str2ms(tlimit);
  let localtime=new Date();
  localtime.setTime(localtime.getTime() + deltatime);
  let delta=ms-localtime;
  return delta;
}

export function timing2str(tlimit){
  let tlimitclear;
  let result={
    hours:0,
    min:0,
    whatfor:null
  }
  if (tlimit){
    if (tlimit > 2000000){
      result.whatfor="PAGES.TOURNAMENT_INFO.ROWS.TIME.FOR_GAME";
      tlimitclear=tlimit-2000000;
    } else {
      result.whatfor="PAGES.TOURNAMENT_INFO.ROWS.TIME.FOR_MOVE";
      tlimitclear=tlimit-1000000;
    }
    result.min=(tlimitclear / 60)|0;
    if (tlimitclear >= 3600) {
      result.hours=(tlimitclear / 3600)|0;
      result.min-=result.hours*60
    }
  }
  return result;
} 

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}