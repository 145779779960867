<template>
    <a-tree
      ref="tree"
      :show-line="true"
      :tree-data="treeData"
      :virtual="true"
      v-bind:selectedKeys="selkeys"
      v-model:expandedKeys="BoardStore.expkeys"
      @select="onSelect"
    >
    </a-tree>
</template>
<script>
import { defineComponent } from 'vue';
import {useBoardStore} from '@/store/BoardStore.js'
import {computed} from 'vue'

export default defineComponent({
  setup() {
    const BoardStore=useBoardStore()
    const treeData = BoardStore.visualtree;
    const selkeys = computed (() => {
      return [BoardStore.currentslaveindex]
    })
    return {
      treeData,
      BoardStore,
      selkeys
    }
  },
  methods:{
    onSelect(selectedKeys) {
      if (selectedKeys.length){
        this.BoardStore.selectslave(selectedKeys[0])
      }
    }
  }

});
</script>