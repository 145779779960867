"use strict"

export const flags=[
  "globe.png",
  "tn_ar-flag.gif",
  "tn_am-flag.gif",
  "tn_as-flag.gif",
  "tn_au-flag.gif",
  "tn_aj-flag.gif",
  "tn_bo-flag.gif",
  "tn_br-flag.gif",
  "tn_bu-flag.gif",
 // "tn_ca-flag.gif",
  "tn_ci-flag.gif",
  "tn_ch-flag.gif",
  "tn_hr-flag.gif",
  "tn_cu-flag.gif",
  "tn_cy-flag.gif",
  "tn_ez-flag.gif",
  "tn_da-flag.gif",
  "tn_en-flag.gif",
  "tn_fi-flag.gif",
  "tn_fr-flag.gif",
  "tn_gg-flag.gif",
  "tn_gm-flag.gif",
  "tn_gr-flag.gif",
  "tn_hu-flag.gif",
  "tn_in-flag.gif",
  "tn_id-flag.gif",
  "tn_ir-flag.gif",
  "tn_iz-flag.gif",
  "tn_ei-flag.gif",
  "tn_is-flag.gif",
  "tn_it-flag.gif",
  "tn_ja-flag.gif",
  "tn_jo-flag.gif",
  "tn_kz-flag.gif",
  "tn_kg-flag.gif",
  "tn_lg-flag.gif",
  "tn_lh-flag.gif",
  "tn_mx-flag.gif",
  "tn_md-flag.gif",
  "tn_mj-flag.gif",
  "tn_mo-flag.gif",
  "tn_nl-flag.gif",
  "tn_nz-flag.gif",
  "tn_ni-flag.gif",
  "tn_no-flag.gif",
  "tn_pk-flag.gif",
  "tn_pl-flag.gif",
  "tn_po-flag.gif",
  "tn_ro-flag.gif",
  "tn_rs-flag.gif",
  "tn_sa-flag.gif",
  "tn_ri-flag.gif",
  "tn_sn-flag.gif",
  "tn_lo-flag.gif",
  "tn_si-flag.gif",
  "tn_sf-flag.gif",
  "tn_ks-flag.gif",
  "tn_sp-flag.gif",
  "tn_sw-flag.gif",
  "tn_sz-flag.gif",
  "tn_sy-flag.gif",
  "tn_ti-flag.gif",
  "tn_tz-flag.gif",
  "tn_th-flag.gif",
  "tn_ts-flag.gif",
  "tn_tu-flag.gif",
  "tn_tx-flag.gif",
  "tn_ae-flag.gif",
  "tn_uk-flag.gif",
  "tn_us-flag.gif",
  "tn_up-flag.gif",
  "tn_uz-flag.gif",
  "tn_vm-flag.gif"
];
