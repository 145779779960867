<template>
  <div id="LeadersDiv" class="bluelevel1 roundborder" style="display: flex; flex-direction: column; width: 100%; margin-left:12px; overflow:auto;">
    {{t('TEMPLATES.PLAY.LEADERS.COLUMNS.RATING')}}
    <table id="leaderslist" width="100%">
      <tr v-for="(leader,index) in store.leaders" :key="leader.id" :bgcolor="leader.bgcolor">
        <td>
          <div class="bluelevel2 roundborder" style="padding:6px; margin:5px">
            <table width="100%">
              <tr align="left">
                <td width="5%">
                  {{index+1}}
                </td>
                <td width="17%">
                  <img v-if="leader.arrow" :src="leader.arrow"  width="16" height="16"/>{{leader.rating}}
                </td>
                <td width="30%">
                  <span class="yellowfont">{{leader.login }}</span>
                </td> 
                <td>
                  <OneUser :profile=leader :imgsize="24"/>
                </td>
              </tr>
            </table>
          </div>
        </td> 
      </tr>
    </table>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import {useMyDataStore} from '@/store/MyData.js'
  import OneUser from '@/components/OneUser'

  export default defineComponent({
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      const store = useMyDataStore()
      return { t, store }
    },
    components:{
      OneUser
    }
  });
</script>

<style type="text/css">

</style>