<template>
  <div id="AppHeader">
    <MainLogo/>
    <MnuMain/>
    <div class="LangAvatar">
    <!--  <LanguageSelector/>-->
      <AvaTar v-if="!Mydatastore.mydata"/>
    </div>
    <ProFile/>
  </div>
</template>

<script type="text/javascript">
  import { defineComponent } from 'vue'
  import MainLogo from '@/components/MainLogo.vue';
//  import LanguageSelector from '@/components/LanguageSelector.vue';
  import { useI18n } from 'vue-i18n'
  import {useMyDataStore, logout} from '@/store/MyData.js'
  import MnuMain from '@/components/MnuMain.vue'
  import ProFile from '@/components/ProFile.vue'
  import AvaTar from '@/components/AvaTar.vue'

  export default defineComponent({
    name:'AppHeader',
    data(){
      return {
        down:true,
        up:false
      }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      const Mydatastore = useMyDataStore()
      setTimeout(Mydatastore.setmyparams,10)
      return { t, Mydatastore }
    },
    methods:{
      vch(visible) {
        this.down=!visible;
        this.up=visible;
      },
      showLogin() {
        this.Mydatastore.showLogin();
        this.down=true;
        this.up=false;
      },
      logout(){
        this.down=true;
        this.up=false;
        logout();
      },
      register(){
        this.Mydatastore.showRegister();
        this.down=true;
        this.up=true;
      },
      settings(){
        this.Mydatastore.showSettings();
        this.down=true;
        this.up=true;
      }
    },

    components:{
      MainLogo,
//      LanguageSelector,
      MnuMain,
      ProFile,
      AvaTar
    }
  })
  
</script>

<style type="text/css">
  #AppHeader{
    height: 60px;
    display: flex;
    align-items: center;
  }
  .LangAvatar{
    height:60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 1%;
    padding-top: 6px;
    margin-left: 16px;
  }

  @media all and (max-width: 1020px) {
    #ArrowIcon1 {
      display: none;
    }
    #ArrowIcon2 {
      display: none;
    }
    .LangAvatar{
      width: 5%;
    }
  }
  @media all and (max-width: 800px) {
    #AppHeader {
      height: 40px;
    }
    .LangAvatar{
      max-width: 5%;
    }
  }
</style>