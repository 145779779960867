<template>
  <div class="gameroom">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%;">
      <table width="95%">
        <tr align="right">
          <td width="55%">
            <span class="formheader">{{t("PAGES.PLAY.TITLE")}}</span>
          </td>
          <td width="30%">
            <div :class="getdivclass('u')" style="display: inline-block;">
              <span @click="list='u'" style="cursor:pointer;">{{t('PAGES.PLAY.USERS.LIST')}}</span>
            </div>
          </td>
          <td width="15%">
            <div :class="getdivclass('g')" style="display: inline-block;">
              <span @click="list='g'" style="cursor:pointer;">{{t('PAGES.PLAY.GAMES.TITLE')}}</span>
            </div>
          </td>
        </tr>
      </table>
      <div v-if="list=='u'" class="userlist">
        <span class="asreference" @click="commoninvite">{{t('TEMPLATES.PLAY.BOARD.TOP_PANEL.COMMON_INVITE')}}</span>
        <table>
          <tr>
            <td width="5%">
              №
            </td>
            <td width="10%">
              {{t('PAGES.PLAY.USERS.RATING')}}
            </td>
            <td width="20%">
              {{t('PAGES.PLAY.USERS.TITLE')}}
            </td>
            <td></td>
            <td></td>
            <td>{{t('INVITE_MODAL.TITLE')}}</td>
          </tr>
          <tr v-for="(user,index) in Mydatastore.users" :key="user.userid">
            <td>{{index+1}}</td>
            <td><img v-if="user.arrow" :src="user.arrow"  width="16" height="16"/>{{user.rating}}</td>
            <td>{{user.login}}</td>
            <td align="left"><OneUser :profile=user :imgsize="24"/></td> 
            <td align="left">
              <span v-if="invitestatus(user) == 'invite'" @click="invite(user)" class="asreference">{{t('COMMON.INVITE')}}</span>
              <span v-if="invitestatus(user) == 'playself'" class="asreference" @click="playself">{{t('PAGES.PLAY.USERS.PLAY_WITH_ME')}}</span>
              <span v-if="invitestatus(user) == 'dontinvite'">{{t('PAGES.PLAY.USERS.DONTINVITE')}}</span>
              <span v-if="invitestatus(user) == 'busy'">{{t('PAGES.PLAY.USERS.BUSY')}}</span>
            </td>
            <td align="left">
              <span v-for="(invitation) in Mydatastore.commoninvitations" :key="invitation.sender">
                <span v-if="invitation.sender == user.userid">
                  <a-popover v-if="invitation.rating">
                    <template #content>
                      {{t("PAGES.TOURNAMENT_INFO.TABLE.RATING")}}
                    </template>
                    <img src="pd.png" :height="24" :width="24" >
                  </a-popover>
                  <a-popover v-if="invitation.tlimit">
                    <template #content>
                      {{t("PAGES.TOURNAMENT_INFO.TABLE.TIMING")}}
                    </template>
                    <img src="clock.png" :height="24" :width="24" >
                  </a-popover>
                  <a-popover v-if="invitation.pos1">
                    <template #content>
                      {{t("INVITE_MODAL.START_WITH")}}
                    </template>
                    <img src="/smallLogo.png" :height="24" :width="24" >
                  </a-popover>
                  <span v-if="user.userid == Mydatastore.mydata.id" class="asreference" @click="cancelcommon">{{t('COMMON.CANCEL')}}
                    <!--<button @click="cancelcommon">{{t('COMMON.CANCEL')}}</button>-->
                  </span>
                  <span v-if="user.userid != Mydatastore.mydata.id" class="asreference" @click="acceptcommon(invitation)">{{t('COMMON.DETAILED')}}
                    <!--<button @click="acceptcommon(invitation)">{{t('COMMON.ACCEPT')}}</button>-->
                  </span>
                </span>
              </span>
            </td> 
          </tr>
        </table>
      </div>
      <div v-else class="userlist">
        <table width="100%">
          <tr>
            <td width="15%"></td>
            <td width="40%" align="left">
              {{t('PAGES.PLAY.GAMES.COLUMNS.WHITE')}}
            </td>
            <td width="40%" align="left">
              {{t('PAGES.PLAY.GAMES.COLUMNS.BLACK')}}
            </td>
            <td>
            </td>
          </tr>
          <tr v-for="(game) in Mydatastore.allgames" :key="game.gameid" >
            <td :width="imgsumwidth(game)" align="left">
              <a-popover v-if="game.tournament">
                <template #content>
                  {{t("PAGES.TOURNAMENT_INFO.TABLE.TOURNAMENT")}}
                </template>
                <img src="tournament.png" :height="24" :width="24" >
              </a-popover>
              <a-popover v-if="game.timelimit">
                <template #content>
                  {{t("PAGES.TOURNAMENT_INFO.TABLE.TIMING")}}
                </template>
                <img src="clock.png" :height="24" :width="24" >
              </a-popover>
              <a-popover v-if="game.rating">
                <template #content>
                  {{t("PAGES.TOURNAMENT_INFO.TABLE.RATING")}}
                </template>
                <img src="pd.png" :height="24" :width="24" >
              </a-popover>
              <a-popover v-if="game.virtual">
                <template #content>
                  {{t("PAGES.TOURNAMENT_INFO.TABLE.ABSENT_USER")}}
                </template>
                <img src="absent.png" :height="24" :width="24" >
              </a-popover>
            </td>
            <td align="left">{{game.wlogin}} ( {{game.wuser.rating}} ) 
              <OneUser :profile=game.wuser :imgsize="24"/>
            </td>
            <td align="left">{{game.blogin}} ( {{game.buser.rating}} ) 
              <OneUser :profile=game.buser :imgsize="24"/>
            </td>
            <td>
              <span class="asreference" @click="watch(game.gameid)">
                <img src="eye.png" width="32" height="24" style="cursor:pointer;">
              </span>
            </td> 
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import OneUser from '@/components/OneUser'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import {useBoardStore} from '@/store/BoardStore.js'

  export default defineComponent({
    setup() {
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
      const BoardStore = useBoardStore()
  
      return {
        t,
        Mydatastore,
        BoardStore
      }
    },
    data(){
      return{
        list:'u'
      }
    },
    components:{
      OneUser
    },
    methods:{
      acceptcommon(invitation){
        this.Mydatastore.currentinvitations=[invitation]
        this.Mydatastore.showInvList()
      },
      cancelcommon(){
        SOCKETUTILS.cancelinvitation()
        this.Mydatastore.myinv=null
      },
      imgsumwidth(game){
        let s=5
        if (game.tournament){
          s+=5
        }
        if (game.timelimit){
          s+=5
        }
        if (game.rating){
          s+=5
        }
        if (game.virtual){
          s+=5
        }
        return s+'%'
      },
      invitestatus(user){
        if ( ((user.dontinvite == 0)||(user.id == this.Mydatastore.mydata.id)) && ((user.gamecount==0)||(user.gamecount<user.robot)) ) {
          if (user.userid == this.Mydatastore.mydata.id){
            return "playself"
          } else {
            return "invite";
          }
        } else {
          if (user.dontinvite) {
            return "dontinvite"
          } else {
            return "busy";
          }
        }
      },
      invite(user){
        this.Mydatastore.user2invite=user
        this.Mydatastore.modalvisible="Invitation"
      },
      commoninvite(){
        this.Mydatastore.user2invite=null
        this.Mydatastore.modalvisible="Invitation"
      },
      playself(){
        let cp=this.BoardStore.getcurrentposition()
        SOCKETUTILS.selfplay(cp.p1,cp.turn)
      },
      watch(gameid){
        SOCKETUTILS.watchgame(gameid)
      },
      getdivclass(a){
        return (a==this.list)?"roundborder bluelevel1":null
      }
    }
  })
</script>