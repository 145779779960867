<template>
  <!--<div style="width:100%; height:100%; display:flex; justify-content:center;">-->
    <span style="font-family: 'Share-TechMono'; font-size: 24px">{{str}}</span>
  <!--</div>-->
</template>

<script type="text/javascript">
  import * as TIME from '@/sys/time.js'
  import {computed} from 'vue'

  export default {
    props:["time"],
    setup(props){

      const str = computed (() => {
        return TIME.time2str(props.time)
      })

      return{
        str
      }
    },
  }
</script>


<style src='@/assets/main.css'>
</style>