<template>
  <div id="logodiv">
    <table width="100%">
      <tr>
        <td align="left">
          <img id="MainLogo" src='@/assets/logo.png'>
        </td>
        <td align="right">
          <LanguageSelector/>
        </td>
      </tr>
    </table>
  </div> 
</template>

<script type="text/javascript">
  import { defineComponent } from 'vue'
  import LanguageSelector from '@/components/LanguageSelector.vue';
  export default defineComponent({
    components:{
      LanguageSelector
    }
  })
</script>

<style type="text/css">
  #MainLogo {
    image-rendering: moz-crisp-edges; /* Firefox */
    interpolation-mode: nearest-neighbor;  /* IE */
    height: 100px;
    margin-top: -8%;
  }
  #MainLogoorig {
    display: none;
    image-rendering: moz-crisp-edges; /* Firefox */
    interpolation-mode: nearest-neighbor;  /* IE */
    height: 150%;
    margin-left: 1%;
    margin-top: -4%;
  }
  #logodiv{
    display: none;
    height: 100%;
    min-width: 19%;
    width: 20%;
  }


  @media screen and (min-width: 892px) {
    #logodiv {
      display: block;
    }
  }

</style>