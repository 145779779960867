<template>
    <a-popover>
      <template #content>
        {{t('WATCHING.BACK')}}
      </template>
      <div class="corner">
        <img class="asreference" src="close.png" width="32" height="24" @click="MydataStore.exitback()">
      </div>
    </a-popover>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'

  export default {
    setup(){
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });

      const MydataStore=useMyDataStore()

      return{
        t,
        MydataStore
      }
    },
  }
</script>
