<template>
  <div id="leftpanel" style="margin-top:10px">
<!--    <LeftMnu/>-->
    <AboutSection v-if="!(store && store.mydata)"/>
    <LeadersList v-else/>
  </div>	
</template>

<script type="text/javascript">
//  import LeftMnu from '@/leftside/MnuLeft'
  import { defineComponent } from 'vue'
  import AboutSection from '@/leftside/AboutSection'
  import LeadersList from '@/leftside/LeadersList'
  import {useMyDataStore} from '@/store/MyData.js'

  export default defineComponent({
    name:'LeftPanel',
    setup(){
      const store=useMyDataStore()
      return {
        store
      }
    },
    components:{
//      LeftMnu,
      LeadersList,
      AboutSection
    },
  })
</script>

<style src='@/layouts/styled.css'>
</style>