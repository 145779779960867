<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('MYGAMES.CREATE_PLAYLIST')}}
    </template>
    <template v-slot:body>
      <span>{{t('MYGAMES.SELECT')}}</span>
      <a-form>
        <div class="bordered playlisttable">
          <a-radio-group v-model:value="this.Mydatastore.playlisttitleid">
            <table id="tableplaylist">
              <tr v-for="mygame in Mydatastore.mygames.filter(mygame => mygame.chb)" :key="mygame.gid" align="left">
                <td>
                  <a-radio :value="mygame.gid"></a-radio>
                </td>
                <td>
                  <img src="book.jpg" width="64" height="64">
                </td>
                <td>
                  {{mygame.comment}}
                  <br>
                  {{mygame.dfinish}}
                </td>
              </tr>
            </table>
          </a-radio-group>
        </div>
        <a-form-item>
          <a-button type="primary" @click="create">
            {{t('COMMON.CREATE')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    components: {
      UniversalModal
    },

    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    setup() {
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
     
      return {
        t,
        Mydatastore
      };
    },
    methods:{
      keyhandler(e){
        if (e.key == 'Enter') {
          this.create()
        }
      },
      async create(){
        let slaves=[]
        this.Mydatastore.mygames.filter(mygame => (mygame.chb && (mygame.gid != this.Mydatastore.playlisttitleid))).forEach(a => slaves.push(a.gid))
        SOCKETUTILS.playlist(this.Mydatastore.playlisttitleid,JSON.stringify(slaves))
        this.Mydatastore.setslaves(slaves)
        this.Mydatastore.modalvisible = 'None'
      }
    }
  });

</script>
<style>
.playlisttable{
  max-height: 300px;
  overflow: auto;
}  
#tableplaylist{
  font-size: 14px;
}
</style>
