<template>
  <UniversalModal v-on:keydown.enter="ok" v-on:keydown.esc="close" >
    <template v-slot:header>
      {{t('BOARD.SETTINGS')}}
    </template>
    <template v-slot:body>
      <a-checkbox v-model:checked="Boardstore.currentposition.abc">{{t('BOARD.ABC')}}</a-checkbox>
      <br>
      <span>
        <br>
        {{t("SETTINGS.SPEED")}}
        <br>
        <table >
          <tr>
            <td>
              <img src="turtle.png" width="24" height="24">
            </td>
            <td>
              <SpeedMeter  id="SpeedSVG" :bar="bar" @click="SpeedHandler"/>
            </td>
            <td>
              <img src="rabbit.png" width="24" height="24">
            </td>
          </tr>
        </table>
      </span>
      <br>
      <a-button @click="close()" class="margin16">
        {{t('COMMON.CANCEL')}}
      </a-button>
      <a-button type="primary" @click="ok">
        {{t('COMMON.SAVE')}}
      </a-button>
      <br>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import {useBoardStore} from '@/store/BoardStore.js'
  import SpeedMeter from '@/components/ProgressBar'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    components: {
      UniversalModal,
      SpeedMeter
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      let speed=(this.Mydatastore && this.Mydatastore.mydata && this.Mydatastore.mydata.profprivate.showspeed)?
            this.Mydatastore.mydata.profprivate.showspeed:0.5
      let initialwidth=200
      return {
        showspeed:(this.Mydatastore && this.Mydatastore.mydata && this.Mydatastore.mydata.profprivate.showspeed)?
            this.Mydatastore.mydata.profprivate.showspeed:0.5,
        bar:{
          width:initialwidth,
          height:8,
          progress:Math.round(initialwidth*speed)
        }
      }
    },

    setup(){
      const Mydatastore = useMyDataStore()
      const Boardstore=useBoardStore()
     
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      return {
        t,
        Mydatastore,
        Boardstore,
      };
    },
    methods:{
      close(){
        this.Boardstore.getcurrentposition().abc=(this.Mydatastore.mydata.profprivate.showabc == 1)
        this.Mydatastore.hideModal()
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.close()
        } else {
          if (e.key == 'Enter') {
            this.ok()
          }
        }
      },
      ok(){
        this.Mydatastore.mydata.profprivate.showabc=this.Boardstore.getcurrentposition().abc?1:0
        this.Boardstore.getcurrentposition().abc=this.Boardstore.getcurrentposition().abc
        SOCKETUTILS.saveprivateprofile('showabc',this.Mydatastore.mydata.profprivate.showabc)
        this.Mydatastore.mydata.profprivate.showspeed=this.showspeed
        SOCKETUTILS.saveprivateprofile('showspeed',this.Mydatastore.mydata.profprivate.showspeed)
        this.Boardstore.setmoviedelay(this.showspeed)
        this.Mydatastore.hideModal()
      },
      SpeedHandler(e) {
        let canvascoord=document.getElementById("SpeedSVG").getBoundingClientRect();
        let X = e.clientX - canvascoord.left;
        this.bar.progress=X
        this.showspeed=X/this.bar.width
      }
    }
  });

</script>
