<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('TAB_BAR.HELP')}}
    </template>
    <template v-slot:body>
      <p>{{t(MydataStore.pagesection)}}</p>
    </template>
    <template v-slot:footer></template>
  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      return{
        idfilter:0
      }
    },
    setup() {
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const MydataStore = useMyDataStore()
     
      return {
        t,
        MydataStore
      };
    },
    methods:{
      keyhandler(e){
        if (e.key == 'Escape') {
          this.close()
        } else {
          if (e.key == 'Enter') {
            this.close()
          }
        }
      },
      close(){
        this.Mydatastore.modalvisible='none'
      }
    }
  });

</script>
