<template>
  <div class="gameroom" id="divmygames">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%;">
      <table width=100%>
        <tr>
          <td>
            <span class="formheader">{{t("TAB_BAR.MYGAMES")}}</span>
          </td>
          <td align="right">
            <a-checkbox v-model:checked="showid">
              {{t('LIBRARY.SHOWID')}}
            </a-checkbox>
          </td>
        </tr>
      </table>
      <div class="userlist"  @scroll="scroll" style="max-height:75%">
        <table id="tablemygames" width="100%">
          <tr v-for="mygame in Mydatastore.mygames" :key="mygame.gid" align="left">
            <td v-if="showid">
              {{mygame.gid}}
            </td>
            <td>
              <a-checkbox v-model:checked="mygame.chb" @change="chbpressed(mygame)"></a-checkbox>
            </td>
            <td>
              <!--<img src="book.jpg" width="64" height="64">-->
              <CheckersBoard :board="mygame"/>
            </td>
            <td>
              <span class="asreference" @click="loadgame(mygame.gid)">{{mygame.comment}}</span>
              <br>
              <span>{{mygame.dfinish}}</span>
            </td>
            <td>
              <a-popover>
                <template #content>
                  {{t('ACCESS_CONTROL')}}
                </template>
                <img src="closed.png" width="32" height="32" v-if="mygame.published == 0" class="asreference" @click="chpublic(mygame,1)">
                <img src="opened.png" width="32" height="32" v-if="mygame.published" class="asreference" @click="chpublic(mygame,0)">
              </a-popover>
            </td>
            <td>
              <a-popover>
                <template #content>
                  {{t('DISCUSSION_CONTROL')}}
                </template>
                <img src="chat24.png" width="32" height="32" v-if="mygame.commenabled" class="asreference" @click="chchat(mygame,0)">
                <img src="nochat.png" width="32" height="32" v-if="mygame.commenabled == 0" class="asreference" @click="chchat(mygame,1)">
              </a-popover>
            </td>
            <td>
              <a-popover>
                <template #content>
                  {{t('MYGAMES.ANNULATE')}}
                </template>
                <img src="tree.png" width="32" height="32" v-if="mygame.slaves.length" class="asreference" @click="annulate(mygame)">
              </a-popover>
            </td>
            <td>
              <a-popover>
                <template #content>
                  {{t('RATING')}}
                </template>
                <img :src="getrating(mygame)" height="20" width="100">
                ({{mygame.gamerating}})
              </a-popover>
            </td>
          </tr>
        </table>
      </div>
      <div class="width96" >
        <span>
          <button @click="refresh">
            {{t('MYGAMES.REFRESH')}}
          </button>
          <button @click="deletegame" :disabled="! aselected">
            {{t('MYGAMES.DELETE')}}
          </button>
          <button @click="combine" :disabled="! aselected">
            {{t('MYGAMES.COMBINE')}}
          </button>
        </span>
        <br>
        <span>
          <a-select 
          v-model:value="moreoption"
          style="width: 200px"
          :disabled="! aselected"
          >
            <a-select-option value="more_options">{{t("MYGAMES.MORE")}}</a-select-option>
            <a-select-option value="allow_access">{{t("MYGAMES.ALLOW_ACCESS")}}</a-select-option>
            <a-select-option value="deny_access">{{t("MYGAMES.DENY_ACCESS")}}</a-select-option>
            <a-select-option value="allow_discussion">{{t("MYGAMES.ALLOW_DISCUSSION")}}</a-select-option>
            <a-select-option value="deny_discussion">{{t("MYGAMES.DENY_DISCUSSION")}}</a-select-option>
          </a-select>
          <button :disabled="dodisabled() || (! aselected)" @click="domore">
            {{t('MYGAMES.DO')}}
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  import CheckersBoard from '@/components/CheckersBoard'
  import * as UTILS from '@/hooks/utils.js'
  
  export default defineComponent({
    components:{
      CheckersBoard,
    },
    data(){
      return{
        scroll_locked:false,
        moreoption:"more_options",
        aselected:false,
        showid:false
      }
    },
    setup() {

      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()

      return {
        t,
        Mydatastore
      }
    },
    methods:{
      annulate(mygame){
        if (confirm(this.t('MYGAMES.ANNULATE')+'?')){
          SOCKETUTILS.clearplaylist(mygame.gid)
          mygame.slaves=[]
        }
      },
      loadgame(gameid){
        this.Mydatastore.loadgame(gameid,'library')
      },
      getrating(mygame){
//        let ratindex=((mygame.gamerating+0.249)*2)|0;
//        if (ratindex>10) {
//          ratindex=10;
//        }
//        return "star"+ratindex+".jpg"
        return UTILS.getrating(mygame.gamerating)    
      },
      async scroll(){
        if (document.getElementById("tablemygames").getBoundingClientRect().bottom-document.getElementById("divmygames").getBoundingClientRect().bottom<50){
          if (!this.scroll_locked){
            this.scroll_locked=true
            this.Mydatastore.mygames_getnextpage()
            await new Promise(r => setTimeout(r, 1000));
            this.scroll_locked=false
          }
        }
      },
      calc_aselected(){
        for (let i=0;i<this.Mydatastore.mygames.length;i++){
          if (this.Mydatastore.mygames[i].chb){
            return true
          }
        }
        return false
      },
      chbpressed(mygame){
        this.aselected=mygame.chb?true:this.calc_aselected()
      },
      chchat(mygame,flag){
        let confirmtxt=this.t(flag?'MYGAMES.ALLOW_DISCUSSION':'MYGAMES.DENY_DISCUSSION')
        if (confirm(confirmtxt+'?')){
          SOCKETUTILS.sendchatorder(mygame.gid,flag)
          mygame.commenabled=flag
        }
      },
      chpublic(mygame,flag){
        let confirmtxt=this.t(flag?'MYGAMES.ALLOW_ACCESS':'MYGAMES.DENY_ACCESS')
        if (confirm(confirmtxt+'?')){
          SOCKETUTILS.sendpublicorder(mygame.gid,flag)
          mygame.published=flag
        }
      },
      combine(){
        this.Mydatastore.setplaylisttitleid()
        this.Mydatastore.modalvisible="PlayList"
      },
      deletegame(){
        let delarray=[]
        this.Mydatastore.mygames.forEach(a => {
          if (a.chb){
            delarray.push(a.gid)
          }
        })
        if (confirm(this.t("MYGAMES.DELETE_LIST")+delarray.length)){
          delarray.forEach(a => SOCKETUTILS.deletegame(a))
          for (let i=this.Mydatastore.mygames.length-1;i>=0;i--){
            if (this.Mydatastore.mygames[i].chb) {
              this.Mydatastore.mygames.splice(i,1)
            }  
          }
          this.aselected=false  
        }
//        this.aselected=false
      },
      dodisabled(){
        return (this.moreoption == "more_options") 
      },
      domore(){
        this.Mydatastore.mygames.forEach(a => {
          if (a.chb){
            switch (this.moreoption) {
              case 'allow_access': {
                SOCKETUTILS.sendpublicorder(a.gid,1)
                a.published=1
                break
              }
              case 'deny_access': {
                SOCKETUTILS.sendpublicorder(a.gid,0)
                a.published=0
                break
              }
              case 'allow_discussion': {
                SOCKETUTILS.sendchatorder(a.gid,1)
                a.commenabled=1
                break
              }
              case 'deny_discussion': {
                SOCKETUTILS.sendchatorder(a.gid,0)
                a.commenabled=0
              }
            }
          }
        })
      },
      refresh(){
        this.Mydatastore.refreshMygames()
        this.aselected=false
      }
    }
  })
</script>

<style type="text/css">
.height70{
  height: 75%;
  overflow: auto;
  width: 96%;
}
.width96{
  width: 96%;
  margin-top: 10px;
}
</style>