<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('LIBRARY.SEARCH_FILTER')}}
    </template>
    <template v-slot:body>
      <a-form>
        <div class="bordered alignleft">
          <a-checkbox v-model:checked="showtype[1]">
            {{t('LIBRARY.SHOWID1')}}
          </a-checkbox>
          <br>
          <a-checkbox v-model:checked="showtype[2]">
            {{t('LIBRARY.SHOWID2')}}
          </a-checkbox>
          <br>
          <a-checkbox v-model:checked="showtype[3]">
            {{t('LIBRARY.SHOWID3')}}
          </a-checkbox>
          <br>
          <a-checkbox v-model:checked="showtype[4]">
            {{t('LIBRARY.SHOWID4')}}
          </a-checkbox>
          <br>
          {{t('LIBRARY.KEYWORD')}}
          <br>
          <input v-model="keyword" type="text" size="10">
        </div>
        <a-form-item>
          <a-button @click="close" class="margin16">
            {{t('COMMON.CANCEL')}}
          </a-button>
          <a-button type="primary" @click="setfilter">
            {{t('LIBRARY.SET_FILTER')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
//  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    components: {
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      let sh=[false,false,false,false,false]
      for (let i=0;i<this.Mydatastore.library_filter.ashowtypes.length;i++){
        sh[this.Mydatastore.library_filter.ashowtypes[i]]=true
      }
      return{
        keyword:this.Mydatastore.library_filter.akeywords,
        showtype:sh
      }
    },
    setup() {
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()

      return {
        t,
        Mydatastore
      };
    },
    methods:{
      setfilter(){
        let s=''
        for (let i=1;i<this.showtype.length;i++){
          if (this.showtype[i]) {
            s=s+' '+i
          }
        }
        this.Mydatastore.library_filter.ashowtypes=s
        this.Mydatastore.library_filter.akeywords=this.keyword
        this.Mydatastore.setAnyfilter()
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.close()
        } else {
          if (e.key == 'Enter') {
            this.setfilter()
          }
        }
      },
      close(){
        this.Mydatastore.modalvisible='none'
      }
    }
  });

</script>
