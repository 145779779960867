<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('LIBRARY.OWNER_FILTER')}}
    </template>
    <template v-slot:body>
      <a-form>
        <div class="bordered alignleft">
          <a-radio-group v-model:value="filtertype">
            <a-radio :value=0>
              {{t('LIBRARY.FILTER_OFF')}}
            </a-radio>
            <br>
            <a-radio :value=1>
              {{t('LIBRARY.MYGAMES')}}
            </a-radio>
            <br>
            <a-radio :value=2>
              {{t('LIBRARY.OTHERGUY')}}
              <span v-if="filtertype==2">
                <br>
                <select v-model="MydataStore.ownerfilter" v-if="MydataStore.ownerfilterlist.length">
                  <option v-for="owner in MydataStore.ownerfilterlist" :key="owner.id" :value="owner.id">
                    {{owner.login}} ({{owner.rating}})
                  </option>  
                </select>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.SELECT_OWNER_HINT')}}
                  </template>
                  <input type=text size=5 v-model="MydataStore.owner_usertxt">
                </a-popover>
                <button @click="searchowner" :disabled="MydataStore.owner_usertxt.length<3">
                  <img src="search.png" width="24" height="24">
                </button> 
              </span>
            </a-radio>
            <a-form-item>
              <a-button @click="close" class="margin16">
                {{t('COMMON.CANCEL')}}
              </a-button>
              <a-button type="primary" @click="setfilter" :disabled="(filtertype==2)&&(!MydataStore.ownerfilter)">
                {{t('LIBRARY.SET_FILTER')}}
              </a-button>
            </a-form-item>
          </a-radio-group>
        </div>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    components: {
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      return {
        filtertype:this.MydataStore.ownerfilteron
      }
    },
    setup() {
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const MydataStore = useMyDataStore()

      return {
        t,
        MydataStore
      };
    },
    methods:{
      setfilter(){
        if (this.filtertype==0){
          this.MydataStore.ownerfilter=0
        } else {
          if (this.filtertype==1){
            this.MydataStore.ownerfilter=this.MydataStore.mydata.id
          }
        }
        this.MydataStore.ownerfilteron=this.filtertype
        this.MydataStore.setOwnerfilter()
        this.MydataStore.hideModal()
      },
      close(){
        this.MydataStore.modalvisible='none'
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.close()
        } else {
          if (e.key == 'Enter') {
            this.setfilter()
          }
        }
      },
      searchowner(){
        SOCKETUTILS.finduser('ownerfilter',this.MydataStore.owner_usertxt)
      },
    }
  });

</script>
