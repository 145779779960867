<template>
  <UniversalModal>
    <template v-slot:header>
      {{t('LIBRARY.RATING_FILTER')}}
    </template>
    <template v-slot:body>
      <a-form>
        <div class="bordered alignleft">
          <a-radio-group v-model:value="filtertype">
            <a-radio :value=1>
              {{t('LIBRARY.FILTER_OFF')}}
            </a-radio>
            <br>
            <a-radio :value=2>
              {{t('LIBRARY.ALL_RATING_FILTER')}}
            </a-radio>
            <br>
            <a-radio :value=3>
              {{t('LIBRARY.MINIMAL_RATING_FILTER')}}
              <a-input-number size=5 v-model:value="minrat" v-if="filtertype==3"></a-input-number>
            </a-radio>
            <br>
            <a-radio :value=4>
              {{t('LIBRARY.ONE_PLAYER_FILTER')}}
              <br>
              <span v-if="filtertype == 4">
                <select v-model="Mydatastore.ratingfilter" v-if="Mydatastore.ratingfilter>0">
                  <option v-for="player in Mydatastore.ratingfilterlist" :key="player.id" :value="player.id">
                    {{player.login}} ({{player.rating}})
                  </option>  
                </select>
                <a-popover>
                  <template #content>
                    {{t('LIBRARY.SELECT_OWNER_HINT')}}
                  </template>
                  <input type=text size=5 v-model="usertxt">
                </a-popover>
                <button @click="searchowner" :disabled="usertxt.length<3">
                  <img src="search.png" width="24" height="24">
                </button> 
              </span>
            </a-radio>
          </a-radio-group>
        </div>  
        <a-form-item>
          <a-button @click="close" class="margin16">
            {{t('COMMON.CANCEL')}}
          </a-button>
          <a-button type="primary" @click="setfilter">
            {{t('LIBRARY.SET_FILTER')}}
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    
    <template v-slot:footer></template>

  </UniversalModal>
</template>

<script>
  import UniversalModal from './UniversalModal.vue'
  import { defineComponent } from 'vue';
  import {useMyDataStore} from '@/store/MyData.js'
  import { useI18n } from 'vue-i18n'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'

  export default defineComponent({
    components: {
      UniversalModal
    },
    mounted() {
      document.addEventListener('keydown', this.keyhandler)
    },
    beforeUnmount() {
      document.removeEventListener('keydown',this.keyhandler); // здесь я его удаляю.
    },
    data(){
      return{
        usertxt:'',
        filtertype:1,
        minrat:1000
      }
    },
    setup() {
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
     
      return {
        t,
        Mydatastore
      };
    },
    methods:{
      setfilter(){
        this.Mydatastore.setRatingfilter(this.filtertype,this.minrat)
      },
      close(){
        this.Mydatastore.modalvisible='none'
      },
      keyhandler(e){
        if (e.key == 'Escape') {
          this.close()
        } else {
          if (e.key == 'Enter') {
            this.setfilter()
          }
        }
      },
      searchowner(){
        SOCKETUTILS.finduser('ratingfilter',this.usertxt)
      },
    }
  });

</script>

