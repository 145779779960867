<template>
  <div id="DivBoard" >
    <div id="Wood" >
      <CheckersBoard :board="Boardstore.currentposition" @click="click"/>
    </div>
      <ButtonsBoard/>
  </div>  
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import {useBoardStore} from '@/store/BoardStore.js'
  import CheckersBoard from '@/components/CheckersBoard'
  import ButtonsBoard from '@/components/ButtonsBoard'

  export default {
    setup (){
      const Mydatastore=useMyDataStore()
      const Boardstore=useBoardStore()

      return { 
        Mydatastore,
        Boardstore ,
        ButtonsBoard
      }
    },
    mounted(){
      this.setsizes()
      window.addEventListener('resize', this.setsizes);
    },
    components:{
      CheckersBoard,
      ButtonsBoard
    },
    methods: {
      setsizes(){
        let w=document.documentElement.clientWidth
        let bottom

        let l=document.getElementById('leftpanel')
        let r=document.getElementById('rightpanel')
        let board=document.getElementById('DivBoard')
        let a=document.getElementById('AppContent')
        
        if (w>=1800){
          //maximum
          this.Boardstore.currentposition.size=640
          bottom='770px'

          l.style.top=0
          l.style.width='20%'
          l.style.height='100vw'

          r.style.top=0
          r.style.width=null

          board.style.left='20%'

        } else {
          //small board
          if (w>1060){
            bottom='660px'
            this.Boardstore.currentposition.size=496
            
            l.style.top=0
            l.style.width='20%'
            l.style.height='100vw' 

            r.style.top=0
            r.style.width=null

            board.style.left='20%'

          } else {
            //left down
            if (w>860) {
              this.Boardstore.currentposition.size=496
              bottom='1200px'

              l.style.top='600px'
              l.style.width='600px'
              l.style.height=null

              r.style.top=0
              r.style.width=null

              board.style.left=0

            } else {
              //all down
              this.Boardstore.currentposition.size=496
              bottom='1800px'

              l.style.top='1200px'
              l.style.width='600px'
              l.style.height=null

              r.style.top='600px'
              r.style.left=0
              r.style.width='100%'

              board.style.left=0
            }
          }
        }
        let bw=(this.Boardstore.currentposition.size+50)+'px'
        board.style.width=bw
        document.getElementById('MyMnu').style.width=bw
        document.getElementById('Wood').style.width=bw

        document.getElementById('AppFooter').style.top=bottom
        a.style.height=bottom
//        console.log('w=',w,' bottom=',bottom)
      },
      click(e){
        let curposgvg=document.getElementById('current')
        let picturecoord=curposgvg.getBoundingClientRect();
        let X = e.clientX - picturecoord.left;
        let Y = e.clientY - picturecoord.top;
        this.Boardstore.currentposclick(X,Y)
      },
      woodstyle(){
        let d=this.Boardstore.currentposition.size+50
        let s="width:"+d+"px; height:"+d+'px'
        return s
      }
    }
  }  
</script>

<style type="text/css">
  #DivBoard{
    box-sizing: border-box;
    border-radius: 20px;
    padding-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    max-width: 100%;
    position: absolute; 
  }
  #DivBoardorig{
    box-sizing: border-box;
    border-radius: 20px;
    padding-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    max-width: 100%;
    position: absolute; 
    left:20%;
    right:0;
    top:0;
    bottom:0;
    width: 548px;
  }

  @media all and (max-width: 960px) {
    #DivBoardorig {
      left: 0;
    }
  }
  @media all and (max-width: 800px) {
    #DivBoardorig {
      left: 0;
    }
  }

  #Wood{
    background: #94774C;
    box-sizing: border-box;
    border-radius: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    max-width: 100%;
  }


</style>